import styled from 'styled-components';
import { COLORS } from '@/styles/colors';
import { bodySmall, poppinsMediumFont, poppinsRegularFont, subTitle } from '@/styles/typography';
import { flex } from '@/styles/layout';

export const PaymentDetailsFormWrapper = styled.div`
  width: 100%;
  padding: 28px;
  background: ${COLORS.white};
  border: 1px solid ${COLORS.grey[850]};
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
`;

export const PaymentDetailsFormTitle = styled.div`
  width: 100%;
  ${poppinsMediumFont};
  ${subTitle};
  text-align: start;
  color: ${COLORS.black};
`;

export const PaymentDetailsFormMessage = styled.div`
  width: 100%;
  margin-top: 14px;
  margin-bottom: 28px;
  ${poppinsRegularFont};
  ${bodySmall};
  text-align: start;
  color: ${COLORS.grey[600]};
`;

export const ButtonBlock = styled.div`
  ${flex({ justifyContent: 'end' })};
  column-gap: 12px;
`;
