import styled from 'styled-components';
import { COLORS } from '@/styles/colors';
import { body, bodySmall, poppinsRegularFont } from '@/styles/typography';
import { customVerticalScroll } from '@/styles/common';

export const FormikTextAreaWrapper = styled.div`
  width: 100%;
`;

export const FormikTextAreaLabelWrapper = styled.div`
  margin-bottom: 4px;
`;

export const FormikTextAreaLabel = styled.div<{ hasError: boolean }>`
  ${bodySmall};
  color: ${COLORS.black};
`;

export const FormikCustomTextArea = styled.textarea<{ hasError: boolean }>`
  display: block;
  width: 100%;
  max-height: 200px;
  resize: none;
  padding: 13px 16px;
  border: ${({ hasError }) => (hasError ? `1px solid ${COLORS.red[600]}` : `1px solid ${COLORS.grey[400]}`)};
  border-radius: 10px;
  color: ${COLORS.black};
  background: ${COLORS.grey[250]};
  ${body};
  ${poppinsRegularFont};

  ::placeholder {
    ${body}
    ${poppinsRegularFont}
    color: ${COLORS.grey[600]}
  }

  &:hover {
    border: 1px solid ${COLORS.grey[700]};
    background: ${COLORS.grey[250]};
  }

  &:focus {
    border: 1px solid ${COLORS.green[500]};
    box-shadow: 0 0 2px rgba(0, 134, 111, 0.6);
    background: ${COLORS.white};
    outline: none;
  }

  ${customVerticalScroll};

  &::-webkit-scrollbar-track {
    margin: 10px 0;
  }
`;
