import styled, { CSSProperties } from 'styled-components';
import { boxStyles } from './boxHelpers';

export type SpacingPropsType = {
  mt?: string;
  mb?: string;
  mr?: string;
  ml?: string;
  m?: string | string[];
};

export type BoxWrapperPropsType = SpacingPropsType & {
  display?: CSSProperties['display'];
  justifyContent?: CSSProperties['justifyContent'];
  alignItems?: CSSProperties['alignItems'];
  flexDirection?: CSSProperties['flexDirection'];
  flexWrap?: CSSProperties['flexWrap'];
  rowGap?: CSSProperties['rowGap'];
  columnGap?: CSSProperties['columnGap'];
  width?: CSSProperties['width'];
  position?: CSSProperties['position'];
};

export const BoxWrapper = styled('div')<BoxWrapperPropsType>(boxStyles);
