import { AssignedDepositor } from '@/api/v1/depositor/getDepositorWireApi';
import { DetailsRow } from '@/components/DetailsWidget/DetailsRow/DetailsRow';
import { DetailsWidget } from '@/components/DetailsWidget/DetailsWidget';
import { phoneFormatter } from '@/helpers/formatHelpers';

type DepositorDetailsType = {
  depositor: AssignedDepositor;
};

export const DepositorDetails = ({ depositor }: DepositorDetailsType) => {
  const { first_name, middle_name, last_name, email, mobile_phone } = depositor;
  const formattedPhoneNumber = phoneFormatter(mobile_phone);

  return (
    <DetailsWidget header='Depositor'>
      <DetailsRow name='First Name'>{first_name || '-'}</DetailsRow>
      <DetailsRow name='Middle Name'>{middle_name || '-'}</DetailsRow>
      <DetailsRow name='Last Name'>{last_name || '-'}</DetailsRow>
      <DetailsRow name='Email Address'>{email || '-'}</DetailsRow>
      <DetailsRow name='Mobile Phone Number'>{formattedPhoneNumber}</DetailsRow>
    </DetailsWidget>
  );
};
