import { useCallback } from 'react';
import { PAYMENT_TYPE_DB } from '@/const/shared';

export const useDepositAccountRadioGroupConfig = () => {
  const changePaymentType = useCallback((selectedPaymentType, setPaymentType) => {
    return {
      domestic_radio: {
        radio_title: 'Domestic Payment',
        isChecked: selectedPaymentType,
        value: PAYMENT_TYPE_DB.DOMESTIC,
        handler: () => setPaymentType(PAYMENT_TYPE_DB.DOMESTIC),
      },
      international_radio: {
        radio_title: 'International Payment',
        isChecked: selectedPaymentType,
        value: PAYMENT_TYPE_DB.INTERNATIONAL,
        handler: () => setPaymentType(PAYMENT_TYPE_DB.INTERNATIONAL),
      },
    };
  }, []);

  return { changePaymentType };
};
