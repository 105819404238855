import { useEffect } from 'react';
import { USER_ROLE_MAP, UserRoleType } from '@/const/user';
import { Widget } from '@/components/Widget/Widget';
import { FormMessage } from '@/bundle/_OrgAdmin/shared/ui/styles';
import { Button } from '@/components/Button/Button';
import { Box } from '@/components/Box/Box';
import { WireUserType } from '@/types/wireTypes';
import { useMutation } from '@tanstack/react-query';
import { getChangedFormValues, getMergedValues, getValidationSchema } from '@/helpers/formHelpers/formHelpers';
import { updateRecipient } from '@/bundle/_OrgAdmin/pages/OutboundWire/_Details/api/recipientApi';
import { updateExecutor } from '@/bundle/_OrgAdmin/pages/OutboundWire/_Details/api/executorApi';
import { updateDepositor } from '@/bundle/_OrgAdmin/pages/InboundWire/api';
import { useFormik } from 'formik';
import { EDIT_USER_FORM_CONFIG } from '@/bundle/shared/components/EditUserForm/const';
import { FormGeneratorNew } from '@/components/form/FormGeneratorNew/FormGeneratorNew';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { EDIT_USER_AMPLITUDE_EVENTS_MAP } from '@/bundle/_OrgAdmin/pages/OutboundWire/_Details/const/const';
import { showSnackbar } from '@/components/Snackbar/Snackbar';
import { UPDATE_USER_MOBILE_PHONE_NOTIFICATION } from '@/const/shared';

interface EditUserFormType {
  header: string;
  role: Exclude<UserRoleType, 'opco_user' | 'organization_user'>;
  user: WireUserType;
  wireId: string;
  onClose: () => void;
  onSuccess: () => void;
}

type FormValues = {
  mobile_phone: string;
  first_name?: string;
  middle_name?: string;
  last_name?: string;
};

const EDIT_USER_REQUEST_MAP = {
  [USER_ROLE_MAP.RECIPIENT]: updateRecipient,
  [USER_ROLE_MAP.EXECUTOR]: updateExecutor,
  [USER_ROLE_MAP.DEPOSITOR]: updateDepositor,
};

const EDIT_USER_ROLE_MAP = {
  [USER_ROLE_MAP.RECIPIENT]: 'recipient',
  [USER_ROLE_MAP.EXECUTOR]: 'executor',
  [USER_ROLE_MAP.DEPOSITOR]: 'depositor',
};

type UpdateFunctionType = typeof updateExecutor | typeof updateDepositor;

export const EditUserForm = ({ header, role, user, wireId, onClose, onSuccess }: EditUserFormType) => {
  const formattedRole = EDIT_USER_ROLE_MAP[role];

  const { data, mutate, isPending } = useMutation({
    mutationKey: [`update_${role}`],
    mutationFn: (payload: FormValues) => {
      const editUserFunction = EDIT_USER_REQUEST_MAP[role];

      // Recipient edit details required user id to pass, meanwhile depositor/executor does not!
      if (role === USER_ROLE_MAP.RECIPIENT) {
        return editUserFunction?.(wireId, user?.id, payload);
      }

      return (editUserFunction as UpdateFunctionType)?.(wireId, payload);
    },
    onSuccess: (updateUserResponse) => {
      if (updateUserResponse?.error) return;

      amplitudeService.logEvent(EDIT_USER_AMPLITUDE_EVENTS_MAP[role]);
      showSnackbar(UPDATE_USER_MOBILE_PHONE_NOTIFICATION, { maxWidth: '375px' });

      onClose();
      onSuccess();
    },
  });

  const formik = useFormik({
    initialValues: getMergedValues(EDIT_USER_FORM_CONFIG, user),
    validationSchema: getValidationSchema(EDIT_USER_FORM_CONFIG),
    onSubmit: async (values) => {
      const changedPayload = getChangedFormValues<FormValues>(values, formik.initialValues);

      await mutate(changedPayload);
    },
  });

  useEffect(() => {
    formik.setFieldValue('mobile_phone', '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Widget title={header} headerOffset={14} wrapperVariant='dark'>
      <FormMessage>
        {`Any change to the ${formattedRole} info below will be applied to the other wires this pending account is assigned to.`}
      </FormMessage>
      <FormikForm value={formik}>
        <FormGeneratorNew config={EDIT_USER_FORM_CONFIG} apiError={data?.error} />
        <Box flexDirection='row' width='100%' justifyContent='end' columnGap='12px' mt='12px'>
          <Button color='secondary' onClick={onClose} width={172} size='medium'>
            Cancel
          </Button>
          <Button type='submit' isLoading={isPending} width={172} size='medium'>
            Save
          </Button>
        </Box>
      </FormikForm>
    </Widget>
  );
};
