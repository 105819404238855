import styled from 'styled-components';
import { COLORS } from '@/styles/colors';
import { customVerticalScrollBase, resetButton, resetList } from '@/styles/common';
import { body, poppinsRegularFont } from '@/styles/typography';
import { THEME } from '@/styles/theme';

export const DropdownWrapper = styled.div<{ zIndex: number }>`
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.grey[850]};
  box-shadow: 0 4px 8px 0 rgba(0, 6, 38, 0.2);
  border-radius: 10px;
  margin-top: 4px;
  z-index: ${THEME.autocomplete.zIndex};
  padding: 4px 0;
  overflow: hidden;

  ${({ zIndex }) =>
    zIndex &&
    `
      z-index: ${zIndex};  
    `};
`;

export const DropdownList = styled.ul`
  ${resetList};
  max-height: 230px;
  ${customVerticalScrollBase};
  overflow: auto;
`;

export const DropdownListItem = styled.li`
  display: block;
  width: 100%;
`;

export const DropdownLabel = styled.span``;

export const DropdownLabelHighlight = styled.span`
  color: ${COLORS.green[500]};
  background-color: ${COLORS.green[200]};
`;

export const DropdownOption = styled.button<{ active: boolean }>`
  display: block;
  text-align: left;
  width: 100%;
  ${resetButton};
  padding: 9px 16px;
  cursor: pointer;
  ${poppinsRegularFont};
  ${body};
  overflow-wrap: anywhere;

  &:hover {
    background-color: ${COLORS.grey[300]};
  }

  ${({ active }) =>
    active &&
    `
      background-color: ${COLORS.grey[300]};  
    `};
`;
