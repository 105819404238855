import { logoutApi } from '@/api/v1/users/logoutApi';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';

export const logout = async () => {
  try {
    const response = await logoutApi();

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
