import { AssignedWireType } from '@/types/wireTypes';

export const getShowPaymentDetails = (
  hasPaymentDetails: boolean,
  wire: AssignedWireType,
  hasBillingEvent?: boolean
): boolean => {
  if (!wire) return;

  const { is_payment_details_exist, view_payment_details_event_register } = wire;

  if (hasBillingEvent && wire && hasPaymentDetails) return true;

  if (is_payment_details_exist && view_payment_details_event_register) return true;

  return false;
};
