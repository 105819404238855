import { useField } from 'formik';
import { MultiSelectWrapper } from './styles';
import { useMemo } from 'react';
import { CustomSelect } from '../../../../../../../components/Select/CustomSelect';

export const MultiSelectCmp = (props: any) => {
  const { config, placeholder, optionsHeight } = props;
  const { name } = config;
  const [field, meta, helpers] = useField(name);

  const { setValue } = helpers;

  const onChange = (value: string) => {
    let newValues = Array.isArray(field.value) ? [...field.value] : [];

    if (newValues.includes(value)) {
      newValues = newValues.filter((item: any) => item !== value);

      return setValue(newValues);
    }

    newValues.push(value);

    return setValue(newValues);
  };

  const placeholderText = useMemo(() => {
    if (Array.isArray(field.value) && field.value.length) {
      return `${placeholder} (${field.value.length})`;
    }

    return placeholder;
  }, [field.value, placeholder]);

  return (
    <MultiSelectWrapper key={name}>
      <CustomSelect
        {...field}
        placeholder={placeholderText}
        options={config?.options}
        variant={'multi'}
        meta={meta}
        onChange={onChange}
        isShowValueInInput
        optionsHeight={optionsHeight}
      />
    </MultiSelectWrapper>
  );
};
