import { useEffect } from 'react';
import { addDays, isBefore } from 'date-fns';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  FilterListCustomButtonClearAll,
  FilterListFiltersBlock,
  FilterListFiltersBlockButtonBlock,
  FilterListCmpBlock,
  FilterListWrapper,
  FilterListFiltersBlockWrapper,
  BlockOfChips,
} from './styles';
import { FormikForm } from '../../../../../components/form/FormikForm/FormikForm';
import { LineOfChips } from './LineOfChips/LineOfChips';
import { useReplaceUrl } from '../../../../../hooks/useReplaceUrl';
import { MultiSelectCmp } from './_filters/MultiSelect/MultiSelectCmp';
import { SearchInput } from './_filters/SearchInput/SearchInput';
import { prepareUrl } from '../../../../../api/helpers/queryHelpers';
import { Button } from '../../../../../components/Button/Button';
import { RangeDatePicker } from './_filters/RangeDatePicker/RangeDatePicker';
import { formatDate } from '@/helpers/dateHelpers/dateHelpers';

const FILTER_TYPE_CMP_MAP: any = {
  MULTI_SELECT: MultiSelectCmp,
  MULTI_SELECT_BY_ID: MultiSelectCmp,
  SEARCH: SearchInput,
  DATE_PICKER: RangeDatePicker,
};

type FilterListPropsType = {
  filterConfig: any;
  initialValues?: any;
  label?: string;
};

export const FilterList = ({ filterConfig, initialValues }: FilterListPropsType) => {
  const navigate = useNavigate();
  const { replaceUrl } = useReplaceUrl();

  const formik = useFormik({
    initialValues: initialValues || {},
    enableReinitialize: true,
    onSubmit: (values: any) => {
      const formattedValues = {
        ...values,
        date_created_after: formatDate(values.date_created_after),
        date_created_before: formatDate(values.date_created_before),
      };

      const newQueryParams = {
        filters: JSON.stringify(formattedValues),
        page: null,
      };

      replaceUrl(newQueryParams);
    },
  });

  useEffect(() => {
    const isStartDateBeforeEndDate = isBefore(formik.values.date_created_after, formik.values.date_created_before);

    if (isStartDateBeforeEndDate) return;

    // Add one day to start date when start date was selected after end date
    const endDate = addDays(formik.values.date_created_after, 1);

    formik.setFieldValue('date_created_before', endDate);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.date_created_after]);

  const applyFilters = () => {
    formik.handleSubmit();
  };

  const clearAllFilters = () => {
    const newQueryParams: any = {
      filters: null,
      page: null,
    };
    const newUrl = prepareUrl(window.location, newQueryParams);

    navigate(newUrl);
  };

  return (
    <FilterListWrapper>
      <FormikForm value={formik}>
        <FilterListFiltersBlock>
          <FilterListFiltersBlockWrapper>
            {filterConfig.map((config: any) => {
              const { type, placeholder, width, optionsHeight } = config;
              const key = `${config?.name}_${width}`;

              const FilterComponent = FILTER_TYPE_CMP_MAP[type];

              return (
                <FilterListCmpBlock key={key} width={width}>
                  <FilterComponent
                    config={config}
                    placeholder={placeholder}
                    values={formik.values}
                    optionsHeight={optionsHeight}
                  />
                </FilterListCmpBlock>
              );
            })}
          </FilterListFiltersBlockWrapper>

          <FilterListFiltersBlockButtonBlock>
            {initialValues && (
              <FilterListCustomButtonClearAll type='button' onClick={clearAllFilters}>
                Clear all
              </FilterListCustomButtonClearAll>
            )}
            <Button width={140} size='medium' type='submit' color='secondary' onClick={applyFilters}>
              Apply
            </Button>
          </FilterListFiltersBlockButtonBlock>
        </FilterListFiltersBlock>
      </FormikForm>
      <BlockOfChips>
        {initialValues && <LineOfChips initialValues={initialValues} filterConfig={filterConfig} />}
      </BlockOfChips>
    </FilterListWrapper>
  );
};
