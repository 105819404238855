import { SwitchRole } from '@/bundle/pages/SwitchRolePage/ui/SwitchRole';
import { AuthLayout } from '../../ui/AuthLayout/AuthLayout';
import { useNavigate } from 'react-router-dom';
import { LayoutSpinner, LayoutWrapper } from '@/bundle/Layouts/styles';
import { authTokenService } from '../LoginPage/service/authTokenService';
import { getLoginUrl } from '../LoginPage/urls/getLoginUrl';
import { useLogin } from '../../hooks/useLogin';
import { UserRoleType } from '@/const/user';
import { UpsellBanner } from '@/bundle/shared/components/UpsellBanner/UpsellBanner';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { useHasOrgAdminRole } from '@/bundle/shared/hooks/useHasOrgAdminRole';
import { useEffect, useState } from 'react';
import { decodeToken } from '@/helpers/tokenHelpers';
import { AccessTokenType } from '@/types/sharedTypes';

export const SelectRolePage = () => {
  const navigate = useNavigate();
  const { login } = useLogin();
  const { hasOrgAdminRole } = useHasOrgAdminRole();

  const [loading, setLoading] = useState(true);
  const [activeRoles, setActiveRoles] = useState([]);

  useEffect(() => {
    const tokens = authTokenService.getTokens();

    if (!tokens || !tokens?.access) {
      return navigate(getLoginUrl());
    }

    const { active_roles } = decodeToken<AccessTokenType>(tokens?.access);

    if (active_roles?.length === 1) {
      return navigate(getLoginUrl());
    }

    setActiveRoles(active_roles);
    setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectRole = (selectedRole: UserRoleType) => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.SwitchRoleSelectDashboardDuringLoginSuccess);

    const tokens = authTokenService.getTokens();

    login({ access: tokens.access, refresh: tokens.refresh }, selectedRole);
  };

  const sendUpsellRequest = () => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.UpsellSwitchRoleDuringLogin);
  };

  const navigateToLogin = () => {
    navigate(getLoginUrl());
  };

  return loading ? (
    <LayoutWrapper>
      <LayoutSpinner />
    </LayoutWrapper>
  ) : (
    <AuthLayout banner={hasOrgAdminRole ? null : <UpsellBanner isClosable={false} onSent={sendUpsellRequest} />}>
      <SwitchRole
        activeRoles={activeRoles}
        onSelect={selectRole}
        onBack={navigateToLogin}
        backButtonText={'Return to Login'}
      />
    </AuthLayout>
  );
};
