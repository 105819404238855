import styled from 'styled-components';
import { COLORS } from '../../styles/colors';
import { body, bodySmall, poppinsRegularFont } from '../../styles/typography';

export const SelectDefaultWrapper = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 40px;
`;

export const SelectSmallWrapper = styled(SelectDefaultWrapper)`
  height: 28px;
`;

export const SelectBigWrapper = styled(SelectDefaultWrapper)`
  height: 50px;
`;

export const CustomSelectInput = styled.input<{
  isDisabled: boolean;
  hasError: boolean;
  hasValue?: boolean;
}>`
  margin-top: 4px;
  position: absolute;
  width: 100%;
  cursor: ${({ isDisabled }) => (isDisabled ? 'auto' : 'pointer')};
  height: inherit;
  padding: 13px 16px;
  background: ${COLORS.grey[250]};
  border: ${({ hasError }) => (hasError ? `1px solid ${COLORS.red[600]}` : `1px solid ${COLORS.grey[400]}`)};
  border-radius: 10px;
  color: ${({ hasValue }) => (hasValue ? `${COLORS.black}` : `${COLORS.grey[600]}`)};
  ${poppinsRegularFont};
  ${bodySmall};
  &:hover {
    border: 1px solid ${COLORS.grey[700]};
  }

  &:focus {
    outline-style: none;
    box-shadow: 0 0 2px rgba(0, 134, 111, 0.6);
    background: ${COLORS.white};
    border: 1px solid ${COLORS.green[500]};
  }

  &:disabled {
    border: 1px solid ${COLORS.grey[400]};
    background-color: ${COLORS.grey[200]};
  }
`;

export const CustomSelectInputBig = styled(CustomSelectInput)`
  ${body}
`;

export const CustomSelectInputSmall = styled(CustomSelectInput)`
  height: 30px;
  margin: 0;
  padding: 0 16px;
`;

export const SelectInnerSpan = styled.span`
  font-size: 14px;
  display: block;
  cursor: pointer;
  height: 50px;
`;

export const SmallSelectInnerSpan = styled(SelectInnerSpan)`
  height: 28px;
`;

export const BigSelectInnerSpan = styled(SelectInnerSpan)`
  height: 50px;
`;

export const CustomSelectArrow = styled.div`
  display: inline-flex;
  position: absolute;
  right: 16px;
  top: 40%;
`;

export const SmallCustomSelectArrow = styled(CustomSelectArrow)`
  top: 20%;
`;

export const BigCustomSelectArrow = styled(CustomSelectArrow)``;

export const OptionsWrapper = styled.div<{ height?: number }>`
  width: 100%;
  //overflow-y: scroll;
  z-index: 2;
  position: absolute;
  top: 100%;
  border-radius: 8px;
  margin-top: 8px;
`;

export const CustomSelectInputDataPicker = styled(CustomSelectInput)`
  padding: 13px 16px;
  height: 42px;
  margin: 0 !important;
  border-radius: 8px !important;
  font-size: 16px;
`;
