import { useNavigate } from 'react-router-dom';
import { AuthLayout } from '../../ui/AuthLayout/AuthLayout';
import { AuthStep } from '../../ui/AuthStep/AuthStep';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { useFormik } from 'formik';
import { PERSONAL_DETAILS_FORM_CONFIG } from './const';
import { getMergedValues, getValidationSchema } from '@/helpers/formHelpers/formHelpers';
import { FormGeneratorNew } from '@/components/form/FormGeneratorNew/FormGeneratorNew';
import { getLoginUrl } from '../LoginPage/urls/getLoginUrl';
import { Button } from '@/components/Button/Button';
import { Box } from '@/components/Box/Box';
import { AuthTitle } from '../../ui/AuthTitle/AuthTitle';
import { AuthMessage } from '../../ui/styles';
import { useMutation } from '@tanstack/react-query';
import { personalDetailsVerification } from '@/bundle/Auth/LoginFlow/PersonalDetailsVerificationPage/api';
import { useAuthNavigateToStep } from '@/bundle/Auth/hooks/useAuthNavigateToStep';
import { PersonalDetailsVerificationPayload } from '@/api/v1/users/sendPersonalDetailsVerificationApi';
import { authTokenService } from '@/bundle/Auth/LoginFlow/LoginPage/service/authTokenService';
import { AuthTokenType } from '@/types/sharedTypes';
import { decodeToken } from '@/helpers/tokenHelpers';
import { Notification } from '@/components/Notification/Notification';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';

export const PersonalDetailsVerificationPage = () => {
  const navigate = useNavigate();
  const navigateToStep = useAuthNavigateToStep();

  const token = authTokenService?.getTokens();

  const { first_name, middle_name, last_name } = decodeToken<AuthTokenType>(token?.access);

  const personalDetails = {
    first_name,
    middle_name,
    last_name,
  };

  const { data, mutate, isPending } = useMutation({
    mutationKey: ['personal_data_verification'],
    mutationFn: (payload: PersonalDetailsVerificationPayload) => {
      return personalDetailsVerification(payload as PersonalDetailsVerificationPayload);
    },
    onSuccess: (personalDataResponse) => {
      if (personalDataResponse?.error) return;

      navigateToStep(personalDataResponse?.body);
    },
  });

  const authMessage =
    'Please confirm or update your personal information as it appears on your ID. This information will be used during ID verification if required during the collection of your wire info.';

  const initialValues = getMergedValues<PersonalDetailsVerificationPayload>(
    PERSONAL_DETAILS_FORM_CONFIG,
    personalDetails
  );

  const formik = useFormik({
    initialValues,
    onSubmit: async (values: PersonalDetailsVerificationPayload) => {
      mutate(values);
    },
    enableReinitialize: true,
    validationSchema: getValidationSchema(PERSONAL_DETAILS_FORM_CONFIG),
  });

  const formError = getResponseError(data?.error);

  const navigateToLogin = () => {
    navigate(getLoginUrl());
  };

  return (
    <AuthLayout>
      <AuthStep>
        <AuthTitle>WireVault Account Personal Info</AuthTitle>
        <Box mb='36px'>
          <AuthMessage>{authMessage}</AuthMessage>
        </Box>
        {formError && (
          <Notification variant='error' mb='24px'>
            {formError}
          </Notification>
        )}

        <FormikForm value={formik}>
          <FormGeneratorNew config={PERSONAL_DETAILS_FORM_CONFIG} apiError={data?.error} />
          <Box display='flex' flexDirection='column' rowGap='12px' mt='14px'>
            <Button type='submit'>Submit</Button>
            <Button color='secondary' onClick={navigateToLogin} isLoading={isPending}>
              Return to Login
            </Button>
          </Box>
        </FormikForm>
      </AuthStep>
    </AuthLayout>
  );
};
