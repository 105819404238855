import { OrganizationCreateUserType, OrganizationUserType } from '@/bundle/_Opco/types/types';
import { ApiParams } from '@/api/helpers/urlApiHelpers';
import { ApiResponseType, RequestOptions } from '@/types/sharedTypes';
import { authApiService } from '@/api/authApiService';

export const createOrganizationUserApi = async (
  organizationId: string,
  data: OrganizationCreateUserType,
  params?: ApiParams,
  options?: RequestOptions
): Promise<ApiResponseType<OrganizationUserType>> => {
  const uri = `/opco/organizations/${organizationId}/users/`;

  return authApiService.POST(uri, data, params, options);
};
