import styled from 'styled-components';
import { ActionIconFill } from '@/bundle/_OrgAdmin/pages/Transactions/_Details/ui/TransactionDetails/styles';
import { ellipsis } from '@/styles/common';
import { flex } from '@/styles/layout';

export const InviteNotification = styled.div`
  max-width: 600px;
  margin-top: 94px;
`;

export const EditIconBlock = styled(ActionIconFill)`
  margin-left: 10px;
`;

export const UserDetailsEditableRow = styled.div`
  ${ellipsis};
`;

export const Wrapper = styled.div`
  width: 100%;
  ${flex()};
  column-gap: 28px;
`;

export const BlockWrapper = styled.div`
  width: 50%;
`;
