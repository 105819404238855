import { DetailsRow } from '@/components/DetailsWidget/DetailsRow/DetailsRow';
import { DetailsWidget } from '@/components/DetailsWidget/DetailsWidget';
import { AssignedWireType, WireUserType } from '@/types/wireTypes';
import { phoneFormatter } from '@/helpers/formatHelpers';
import { isRecipientChainProxy, isRecipientInvitedWireStatus, isRecipientProxy } from '@/helpers/wireHelpers';
import { UserInvitedNotification } from '@/bundle/shared/Notifications/UserInvitedNotification';
import { DetailsEditButton } from '@/bundle/_OrgAdmin/shared/ui/DetailsEditButton/DetailsEditButton';

type RecipientGeneralDetailsType = {
  userDetails: WireUserType;
};

const RecipientGeneralDetails = ({ userDetails }: RecipientGeneralDetailsType) => {
  const { first_name, middle_name, last_name, email, mobile_phone } = userDetails || {};

  return (
    <>
      <DetailsRow name='First Name'>{first_name || '-'}</DetailsRow>
      <DetailsRow name='Middle Name'>{middle_name || '-'}</DetailsRow>
      <DetailsRow name='Last Name'>{last_name || '-'}</DetailsRow>
      <DetailsRow name='Email Address'>{email || '-'}</DetailsRow>
      <DetailsRow name='Mobile Phone Number'>{phoneFormatter(mobile_phone)}</DetailsRow>
    </>
  );
};

type RecipientChainProxyDetailsType = {
  wire: AssignedWireType;
  isRecipientEdit?: boolean;
  isProxyEditRecipient?: boolean;
  onEditRecipient?: () => void;
  onProxyEditRecipient?: () => void;
};

export const RecipientChainProxyDetails = ({
  wire,
  isProxyEditRecipient,
  isRecipientEdit,
  onProxyEditRecipient,
  onEditRecipient,
}: RecipientChainProxyDetailsType) => {
  const isChainProxy = isRecipientChainProxy(wire);
  const proxyRecipientDetails = wire?.assigned_proxy_user;
  const recipientDetails = wire?.assigned_user;
  const isDocumentVerificationRequired = !!wire?.is_pd_provider_kyc_required;

  if (isChainProxy) {
    const isRecipientInvited = isRecipientInvitedWireStatus(wire?.status);

    return (
      <>
        <DetailsWidget header='Recipient Proxy'>
          <RecipientGeneralDetails userDetails={proxyRecipientDetails} />
        </DetailsWidget>

        {!isProxyEditRecipient && (
          <DetailsWidget
            header='Recipient'
            action={isRecipientInvited && <DetailsEditButton onEdit={onProxyEditRecipient} />}
            marker={isDocumentVerificationRequired && 'ID VERIFICATION'}
          >
            {isRecipientInvited && <UserInvitedNotification />}
            <RecipientGeneralDetails userDetails={recipientDetails} />
          </DetailsWidget>
        )}
      </>
    );
  }

  const isProxy = isRecipientProxy(wire);
  const header = isProxy ? 'Recipient Proxy' : 'Recipient';
  const userDetails = isProxy ? proxyRecipientDetails : recipientDetails;

  return (
    <DetailsWidget
      header={header}
      action={isRecipientEdit && <DetailsEditButton onEdit={onEditRecipient} />}
      marker={isDocumentVerificationRequired && 'ID VERIFICATION'}
    >
      {isRecipientEdit && <UserInvitedNotification />}
      <RecipientGeneralDetails userDetails={userDetails} />
    </DetailsWidget>
  );
};
