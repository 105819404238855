import { authApiService } from '@/api/authApiService';
import { ApiParams } from '@/api/helpers/urlApiHelpers';

import { ApiResponseType, ListResponseType } from '@/types/sharedTypes';

export type BilledEventType = {
  date_created: string;
  id: string;
  organization: {
    id: string;
    name: string;
  };
  user: {
    email: string;
    id: string;
  };
  wire: {
    contract: {
      id: string;
      name: string;
    };
    id: string;
    name: string;
    ref_id: string;
    payment_details_collection_type: 'unique' | 'confirmed';
  };
};

export type BilledEventResponseType = ApiResponseType<ListResponseType<BilledEventType>>;

export const getBilledEventsApi = async (params?: ApiParams): Promise<BilledEventResponseType> => {
  const uri = `/opco/events/view-payment-details/`;

  return authApiService.GET(uri, params);
};
