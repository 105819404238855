import { media } from '@/styles/responsive';
import styled from 'styled-components';

export const FormGeneratorWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  column-gap: 20px;
`;

export const FieldWrapper = styled.div<{ column?: number; hasOffset?: boolean }>`
  grid-column: ${({ column = 12 }) => `span ${column}`};

  ${({ hasOffset }) =>
    hasOffset &&
    `
    margin-bottom: 22px;
  `};
`;

export const RadioFieldWrapper = styled(FieldWrapper)`
  margin-bottom: 22px;
`;

export const DividerWrapper = styled(FieldWrapper)`
  margin: 0 -28px;
  flex: 1 0 100%;

  ${media.mobile`
    margin: 0 -16px;
  `}
`;
