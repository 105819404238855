import { overlayBackground } from '@/styles/common';
import { flexCenter } from '@/styles/layout';
import { THEME } from '@/styles/theme';
import styled from 'styled-components';

export const CheckPinCodeOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  ${overlayBackground};
  z-index: ${THEME.modal.zIndex};
`;

export const CheckPinCodeWrapper = styled.div`
  ${flexCenter};
  height: 100%;
`;
