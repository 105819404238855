import { useFormik } from 'formik';
import { FormikForm } from '../../../../../../../components/form/FormikForm/FormikForm';
import { Button } from '../../../../../../../components/Button/Button';
import { WireCreateFormWrapper } from './styles';
import { WireTypesType } from '../../../../Transactions/_Details/const/const';
import { FormButtonBlockCentered } from '../../../../../shared/ui/styles';
import { ResponseErrorType } from '@/types/sharedTypes';
import { FormGeneratorNew } from '@/components/form/FormGeneratorNew/FormGeneratorNew';
import { getCreateWireFormConfig } from '../../const/const';
import { getInitialValues, getValidationSchema } from '@/helpers/formHelpers/formHelpers';
import { formatDate } from '@/helpers/dateHelpers/dateHelpers';
import { formatNotes } from '@/helpers/stringHelpers';

type CreateWireFormType = {
  onCreate: (payload) => void;
  currencyOptions: any;
  onClose: (reloadWires?: boolean) => void;
  selectedWireType: WireTypesType;
  isLoading: boolean;
  error: ResponseErrorType;
};

export const CreateWireForm = ({
  onCreate,
  onClose,
  currencyOptions,
  selectedWireType,
  isLoading,
  error,
}: CreateWireFormType) => {
  const config = getCreateWireFormConfig(currencyOptions, selectedWireType);

  const formik = useFormik<any>({
    initialValues: getInitialValues(config),
    validationSchema: getValidationSchema(config),
    enableReinitialize: true,
    onSubmit: (values) => {
      const { name, amount, currency, expected_payment_date, type, notes } = values;
      const payload = {
        name,
        currency: currency.value,
        amount,
        expected_payment_date: expected_payment_date ? formatDate(expected_payment_date) : null,
        type: type.value,
        notes: formatNotes(notes),
      };

      onCreate(payload);
    },
  });

  const cancelHandler = () => {
    onClose();
  };

  return (
    <WireCreateFormWrapper>
      <FormikForm value={formik}>
        <FormGeneratorNew config={config} apiError={error} />
        <FormButtonBlockCentered>
          <Button color={'secondary'} onClick={cancelHandler} width={200}>
            Cancel
          </Button>
          <Button type='submit' isLoading={isLoading} width={200}>
            Save
          </Button>
        </FormButtonBlockCentered>
      </FormikForm>
    </WireCreateFormWrapper>
  );
};
