import { dateFormatter } from '@/helpers/formatHelpers';
import { OpcoUserStatus } from '../_OpcoUsersPage/ui/OpcoUserStatus/OpcoUserStatus';
import { EditLink } from '../ui/EditLink/EditLink';
import { ReactComponent as EnabledVerificationIcon } from '@/images/CheckSmallIcon.svg';
import { ReactComponent as DisabledVerificationIcon } from '@/images/MinusSmallIcon.svg';

export const OPCO_ORGANIZATIONS_TABLE_CONFIG = [
  {
    key: 1,
    name: 'name,-date_created',
    header: 'NAME',
    width: '20%',
    hasSort: true,
    getValue({ name }) {
      return name;
    },
  },
  {
    key: 2,
    name: 'date_created',
    header: 'CREATION DATE',
    width: '14%',
    hasSort: true,
    getValue({ date_created }) {
      return dateFormatter(date_created);
    },
  },
  {
    key: 3,
    name: 'date_modified,-date_created',
    header: 'LAST UPDATE',
    width: '14%',
    hasSort: true,
    getValue({ date_modified }) {
      return dateFormatter(date_modified);
    },
  },
  {
    key: 4,
    name: 'status',
    header: 'STATUS',
    width: '14%',
    getValue({ status }) {
      return <OpcoUserStatus status={status} />;
    },
  },
  {
    key: 5,
    name: 'pricing_tier__name,-date_created',
    header: 'PRICING TIER',
    width: '14%',
    hasSort: true,
    getValue({ pricing_tier }) {
      return pricing_tier?.name;
    },
  },
  {
    key: 6,
    name: 'is_kyc_allowed,-date_created',
    header: 'ID VERIFICATION',
    width: '12%',
    hasSort: true,
    getValue({ is_kyc_allowed }) {
      return is_kyc_allowed ? <EnabledVerificationIcon /> : <DisabledVerificationIcon />;
    },
  },
  {
    key: 7,
    name: '',
    header: 'ACTIONS',
    width: '12%',
    hasAction: true,
    getValue(item, onCellAction) {
      return <EditLink label='Edit organization' onClick={() => onCellAction(item)} preventDefault />;
    },
  },
];
