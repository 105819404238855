import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ContentLayout } from '@/bundle/Layouts/ContentLayout/ContentLayout';
import { PageBody } from '@/components/Page/PageBody/PageBody';
import { DepositAccountDetails } from './ui/DepositAccountDetails/DepositAccountDetails';
import { DepositAccountPaymentDetails } from './ui/DepositAccountPaymentDetails/DepositAccountPaymentDetails';
import { ConfirmModal } from '@/components/ConfirmModal/ConfirmModal';
import { deactivateDepositAccount, loadDepositAccount, loadDepositAcountPaymentDetails } from './api';
import { RedirectButton } from '../../../../../components/RedirectButton/RedirectButton';
import { getInboundWireDetailsUrl } from '../../InboundWire/urls/getInboundWireDetailsUrl';
import { useInterval } from '@/hooks/useInterval';
import { DEPOSIT_ACCOUNT_STATUS_DB } from '@/const/wire';
import { getDepositAccountsUrl } from '../urls/getDepositAccountsUrl';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { Button } from '@/components/Button/Button';

export const DepositAccountDetailsPage = () => {
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpenDeactivateAccountModal, setIsOpenDeactivateAccountModal] = useState(false);

  const { data: depositAccountData } = useQuery({
    queryKey: ['load_deposit_account', id],
    queryFn: () => loadDepositAccount(id),
  });

  const depositAccountStatus = depositAccountData?.body?.status;

  const { data: paymentDetailsData, isFetching: isFetchingPaymentDetails } = useQuery({
    queryKey: ['load_deposit_account_payment_details', id],
    queryFn: () => loadDepositAcountPaymentDetails(id),
    enabled: !!(
      depositAccountStatus === DEPOSIT_ACCOUNT_STATUS_DB.ACTIVE ||
      depositAccountStatus === DEPOSIT_ACCOUNT_STATUS_DB.INACTIVE
    ),
  });

  const { mutate, isPending: isPendingDeactivateDepositAccount } = useMutation({
    mutationKey: ['deactivate_deposit_account'],
    mutationFn: () => {
      return deactivateDepositAccount(id);
    },
    onSuccess(updatedDepositAccount) {
      if (updatedDepositAccount.error) return;

      amplitudeService.logEvent(AMPLITUDE_EVENTS.DeactivateDepositAccountSuccess);

      queryClient.setQueryData(['load_deposit_account', id], updatedDepositAccount);
    },
    onSettled() {
      setIsOpenDeactivateAccountModal(false);
    },
  });

  const onClose = () => {
    setIsOpenDeactivateAccountModal(false);
  };

  const openDeactivateDepositAccountDiaog = () => {
    setIsOpenDeactivateAccountModal(true);

    amplitudeService.logEvent(AMPLITUDE_EVENTS.DeactivateDepositAccountStartRedirect);
  };

  const depositAccount = depositAccountData?.body;
  const paymentDetails = paymentDetailsData?.body;
  const hasReturnToWireButton = location?.state?.transactionId;
  const isPendingStatus = depositAccountStatus === DEPOSIT_ACCOUNT_STATUS_DB.PENDING;
  const isAllowedToDeactivate = depositAccountStatus === DEPOSIT_ACCOUNT_STATUS_DB.ACTIVE;

  const redirectToWire = () => {
    navigate(getInboundWireDetailsUrl(location.state?.transactionId, location.state?.wireId));
  };

  const redirectToDepositAccounts = () => {
    navigate(getDepositAccountsUrl());
  };

  const { cancelInterval, isIntervalRunning } = useInterval(() => {
    if (isPendingStatus) {
      queryClient.invalidateQueries({ queryKey: ['load_deposit_account', id] });
      queryClient.invalidateQueries({ queryKey: ['load_deposit_account_payment_details', id] });
    }
  });

  useEffect(() => {
    const shouldCancelInterval = depositAccount && !isPendingStatus && isIntervalRunning;

    if (shouldCancelInterval) {
      cancelInterval();
    }
  }, [cancelInterval, isIntervalRunning, isPendingStatus, depositAccount]);

  return (
    <ContentLayout
      backButton='Back to Deposit Accounts'
      onBack={redirectToDepositAccounts}
      title='Deposit Account Details'
      headerAction={
        isAllowedToDeactivate && (
          <Button color='secondary' width={160} mobileStretch onClick={openDeactivateDepositAccountDiaog}>
            Deactivate
          </Button>
        )
      }
      subtitle={
        <>
          This deposit account is stored in the WireVault blockchain and cannot be edited. <br />
          You can deactivate this account if it's no longer being used.
        </>
      }
    >
      <PageBody>
        <DepositAccountDetails depositAccount={depositAccount} />
        {depositAccountStatus && (
          <DepositAccountPaymentDetails
            status={depositAccount?.status}
            paymentDetails={paymentDetails}
            error={paymentDetailsData?.error}
            isLoading={isFetchingPaymentDetails}
          />
        )}
      </PageBody>
      <ConfirmModal
        isOpen={isOpenDeactivateAccountModal}
        header={'Deactivate Deposit Account'}
        body={'You will not be able to use this deposit account once you deactivate it.'}
        isLoading={isPendingDeactivateDepositAccount}
        onConfirm={mutate}
        onClose={onClose}
      />
      {hasReturnToWireButton && <RedirectButton onNavigate={redirectToWire} />}
    </ContentLayout>
  );
};
