import { COLORS } from '@/styles/colors';
import { ellipsis } from '@/styles/common';
import styled from 'styled-components';

export const DetailsBlockRow = styled.div<{
  hasBorder?: boolean;
  gradient?: string;
}>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  border-bottom: ${({ hasBorder }) => (hasBorder ? '1px solid #BEC2CC' : 'none')};
  padding-bottom: ${({ hasBorder }) => (hasBorder ? '12px' : '0')};
  ${({ gradient }) => gradient || ''};
  gap: 20px;
`;

export const DetailsBlockRowBig = styled(DetailsBlockRow)`
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const DetailsBlockRowName = styled.div`
  font-family: 'Poppins-Regular', sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #9197a5;
  flex-shrink: 0;
`;

export const DetailsBlockRowValue = styled.div`
  font-family: 'Poppins-Medium', sans-serif;
  color: ${COLORS.black};
  font-size: 14px;
  line-height: 20px;
  ${ellipsis}
`;

export const DetailsBlockRowValueBig = styled(DetailsBlockRowValue)`
  font-size: 20px;
  line-height: 30px;
`;

export const DetailsBlockRowValueBold = styled(DetailsBlockRowValue)`
  font-size: 16px;
  line-height: 24px;
  font-family: 'Poppins-SemiBold', sans-serif;
  font-weight: 600;
`;

export const DetailsBlockColumn = styled.div`
  ${ellipsis};
`;

export const DetailsBlockRowNameWrapper = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
`;
