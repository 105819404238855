import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FilterTypes } from '../_filters/FilterTypes';
import { prepareUrl } from '@/api/helpers/queryHelpers';
import { formatDate } from '@/helpers/dateHelpers/dateHelpers';
import { DEFAULT_DATE_FORMAT } from '@/helpers/dateHelpers/const';

export type ChipType = {
  id: string;
  name: string;
  label: string;
};

export const DATE_CHIP_ID = 'dateChip';

export const usePrepareChipsData = (initialValues: any, filterConfig: any) => {
  const navigate = useNavigate();

  const chips = useMemo(() => {
    const { date_created_after, date_created_before, ...restInitialValues } = initialValues;
    const params = Object.entries(restInitialValues);
    const chipsList = [];

    const dateChip = {
      id: DATE_CHIP_ID,
      name: 'dateChip',
      label: `${formatDate(date_created_after, DEFAULT_DATE_FORMAT)} - ${formatDate(
        date_created_before,
        DEFAULT_DATE_FORMAT
      )}`,
    };

    chipsList.push(dateChip);

    params.forEach(([fieldName, value]) => {
      let chipData = {
        id: '',
        name: '',
        label: '',
      };

      if (typeof value === 'string') {
        chipData = {
          id: `${fieldName}|${value}`,
          name: fieldName,
          label: value,
        };

        return chipsList.push(chipData);
      }

      if (Array.isArray(value)) {
        const config = filterConfig.find((configItem) => configItem.name === fieldName);

        value.forEach((fieldValue) => {
          let currentLabel = fieldValue;

          if (config?.type === FilterTypes.MULTI_SELECT_BY_ID) {
            const option = config.options.find((optionItem) => optionItem.value === fieldValue);

            currentLabel = option?.label;
          }

          chipData = {
            id: `${fieldName}|${fieldValue}`,
            name: fieldName,
            label: currentLabel,
          };
          chipsList.push(chipData);
        });
      }
    });

    return chipsList;
  }, [filterConfig, initialValues]);

  const prepareNewFilters = useCallback(
    (id: string) => {
      let [fieldName, fieldValue] = id.split('|');
      const { [fieldName]: oldFilterValue, ...restFilters } = initialValues;
      let newQueryParamsFilters = { ...restFilters };

      if (oldFilterValue) {
        if (Array.isArray(oldFilterValue)) {
          const newFieldValues = oldFilterValue.filter((item) => item !== fieldValue);

          if (newFieldValues.length) {
            newQueryParamsFilters[fieldName] = newFieldValues;
          }

          return newQueryParamsFilters;
        }

        newQueryParamsFilters = restFilters;
      }

      return newQueryParamsFilters;
    },
    [initialValues]
  );

  const removeChip = (id: string) => {
    const newFilters = prepareNewFilters(id);

    const formattedFilters = {
      ...newFilters,
      date_created_after: formatDate(newFilters.date_created_after),
      date_created_before: formatDate(newFilters.date_created_before),
    };

    const newQueryParams: any = {
      filters: JSON.stringify(formattedFilters),
    };
    const newUrl = prepareUrl(window.location, newQueryParams);

    navigate(newUrl);
  };

  return {
    chips,
    removeChip,
  };
};
