import styled from 'styled-components';
import { COLORS } from '@/styles/colors';
import { THEME } from '@/styles/theme';
import { flex } from '@/styles/layout';
import { Props } from 'react-select';
import { ValueOf } from '@/types/sharedTypes';
import { ellipsis } from '@/styles/common';

const VARIANT = {
  STANDART: 'standart',
  COMPACT: 'compact',
} as const;

type SelectVariantType = ValueOf<typeof VARIANT>;

type PropsType = {
  hasPortal?: boolean;
  error?: string;
  variant?: SelectVariantType;
};

const isCompactVariant = (variant: SelectVariantType) => {
  return variant === VARIANT.COMPACT;
};

const getControlBorderStyles = (props, isFocused, menuIsOpen) => {
  const { variant, error } = props;

  if (isCompactVariant(variant)) return 'none';

  if (error) {
    return `1px solid ${COLORS.red[600]}`;
  }

  if (isFocused) return `1px solid ${COLORS.green[500]}`;

  return menuIsOpen ? `1px solid ${COLORS.green[500]}` : `1px solid ${COLORS.grey[400]}`;
};

const getControlBorderHoverStyles = (variant, menuIsOpen, isDisabled) => {
  if (isCompactVariant(variant)) return 'none';

  return !menuIsOpen && !isDisabled && `1px solid ${COLORS.grey[850]}`;
};

const getControlBoxShadow = (variant, menuIsOpen) => {
  if (isCompactVariant(variant)) return 'none';

  return menuIsOpen ? '0px 0px 2px rgba(0, 134, 111, 0.60)' : 'none';
};

const getControlBackgroundStyles = (variant, menuIsOpen) => {
  if (isCompactVariant(variant) && menuIsOpen) return `${COLORS.grey[200]}`;
  if (isCompactVariant(variant) && !menuIsOpen) return `${COLORS.white}`;

  return `${COLORS.grey[250]}`;
};

const getSingleValueStyles = (variant) => {
  if (isCompactVariant(variant))
    return {
      fontWeight: 600,
    };

  return null;
};

const baseStyles = (props?: PropsType): Record<string, unknown> => ({
  control: (base, { isFocused, selectProps }: { isFocused: boolean; selectProps: Props }) => ({
    ...base,
    outline: 'none',
    transition: 'all 0.2s ease-in-out 0s',
    cursor: 'pointer',
    padding: isCompactVariant(props?.variant) ? '6px 10px' : '13px 16px',
    fontFamily: 'Poppins-Regular, sans-serif',
    fontSize: '16px',
    lineHeight: '24px',
    borderRadius: '10px',
    border: getControlBorderStyles(props, isFocused, selectProps.menuIsOpen),
    boxShadow: getControlBoxShadow(props?.variant, selectProps.menuIsOpen),
    minHeight: 0,
    height: isCompactVariant(props?.variant) ? 'auto' : '50px',
    flexWrap: 'nowrap',
    backgroundColor: getControlBackgroundStyles(props?.variant, selectProps.menuIsOpen),
    color: 'red',

    '&:hover': {
      backgroundColor: isCompactVariant(props?.variant) ? `${COLORS.grey[200]}` : `${COLORS.grey[250]}`,
      border: getControlBorderHoverStyles(props?.variant, selectProps.menuIsOpen, selectProps.isDisabled),
    },
  }),
  singleValue: (base) => ({
    ...base,
    ...getSingleValueStyles(props?.variant),
  }),
  menu: (base) => ({
    ...base,
    maxHeight: 'none',
    marginTop: '4px',
    zIndex: 2,
    borderRadius: '10px',
    border: `1px solid ${COLORS.grey[850]}`,
    boxShadow: '0px 4px 8px 0px rgba(0, 6, 38, 0.20)',
    overflow: 'hidden',
  }),
  menuList: (base) => ({
    ...base,
    textAlign: 'left',
    maxHeight: props.hasPortal ? '370px' : '240px',
    scrollPaddingTop: '10px',

    '::-webkit-scrollbar': {
      width: `${THEME.layout.scrollWidth}`,
    },

    '::-webkit-scrollbar-thumb': {
      background: '#d5dee3',
      borderRadius: '10px',
    },

    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }),
  menuPortal: (base) => ({ ...base, zIndex: THEME.select.zIndex }),
  option: (base) => ({
    ...base,
    padding: isCompactVariant(props?.variant) ? '10px' : '10px 17px',
    backgroundColor: `${COLORS.white}`,
    fontSize: '16px',
    color: `${COLORS.black}`,
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'Poppins-Regular, sans-serif',

    '&:hover': {
      backgroundColor: `${COLORS.grey[300]}`,
    },
  }),
  dropdownIndicator: (state, { selectProps: { menuIsOpen } }: { selectProps: { menuIsOpen: boolean } }) => ({
    ...state,
    cursor: 'pointer',
    transform: menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    padding: '0',
  }),
  container: () => ({
    position: 'relative',
  }),
  indicatorContainer: () => ({
    display: 'none',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
  }),
  placeholder: (base) => ({
    ...base,
    margin: 0,
    fontFamily: 'Poppins-Regular, sans-serif',
    fontSize: '16px',
    color: `${COLORS.grey[600]}`,
  }),
});

const multiSelectStyles = (): Record<string, unknown> => ({
  multiValue: (base) => ({
    ...base,
    maxWidth: '140px',
    borderRadius: '4px',
  }),
  multiValueGeneric: (base) => ({
    ...base,
  }),
  multiValueRemove: (base) => ({
    ...base,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    svg: {
      fill: `${COLORS.grey[400]}`,
      '&:hover': {
        fill: `${COLORS.grey[850]}`,
      },
    },
  }),
});

export const customStyles = (props?: PropsType): Record<string, unknown> => {
  return {
    ...baseStyles(props),
    ...multiSelectStyles(),
  };
};

export const Wrapper = styled.div`
  width: 100%;
`;

export const OptionWrapper = styled.div`
  ${flex({ justifyContent: 'space-between' })};
`;

export const OptionLabel = styled.div`
  ${ellipsis};
`;
