import { FormikSelect } from '../fields/FormikSelect/FormikSelect';
import { FormikInput } from '../fields/FormikInput/FormikInput';
import { BlockWrapper, DividerWrapper, PhoneInputWrapper, RadioGroupWrapper, Wrapper } from '../../styles';
import { FormikPasswordInput } from '../fields/FormikPasswordInput/FormikPasswordInput';
import { FormikTextArea } from '../fields/FormikTextArea/FormikTextArea';
import { RadioGroup } from '../fields/RadioGroup/RadioGroup';
import { FormDivider } from '../FormDivider';
import { FormikSelectNew } from '../fields/FormikSelectNew/FormikSelectNew';
import { FormikDatePicker } from '../FormikDatePicker/FormikDatePicker';
import { Box } from '@/components/Box/Box';
import { FormikPhoneInput } from '../fields/FormikPhoneInput/FormikPhoneInput';

const DEFAULT_COLUMNS = 1;

type FormBlockGeneratorType = {
  configuration: any;
  columns?: number;
  marginTop?: number;
  disableForm?: boolean;
  columnsWidth?: string;
  errorsApi?: any;
  radioGroupConfig?: {};
};

// TODO: add scroll to first error
// const ScrollToFieldError = ({ formik }) => {
//   const { errors, isSubmitting, isValidating } = formik;

//   useEffect(() => {
//     if (isSubmitting && !isValidating) {
//       let keys = Object.keys(errors);

//       if (keys.length > 0) {
//         const selector = `[name=${keys[0]}]`;
//         const errorElement = document.querySelector(selector) as HTMLElement;

//         if (errorElement) {
//           errorElement.scrollIntoView({ behavior: 'smooth' });
//           errorElement.focus();
//         }
//       }
//     }
//   }, [errors, isSubmitting, isValidating]);

//   return null;
// };

export const FormBlockGenerator = ({
  configuration,
  columns = DEFAULT_COLUMNS,
  marginTop,
  disableForm,
  columnsWidth,
  errorsApi,
  radioGroupConfig,
}: FormBlockGeneratorType) => {
  return (
    <BlockWrapper columns={columns} columnsWidth={columnsWidth} marginTop={marginTop}>
      {/* <ScrollToFieldError formik={formik} /> */}
      {Object.keys(configuration).map((key) => {
        const config = configuration[key];

        if (disableForm && config.type === 'confirm') return null;

        const customKey = `${key}_${config.placeholder}`;

        switch (config.type) {
          case 'select':
            return (
              <Wrapper key={customKey} gridColumn={config.gridColumn}>
                <FormikSelect
                  name={key}
                  label={config.title}
                  placeholder={config.placeholder}
                  options={config.options}
                  inputVariant={config.selectedVariant}
                  sizeVariant={config.selectedVariant}
                  disabled={disableForm}
                />
              </Wrapper>
            );

          case 'select-new':
            return (
              <Wrapper key={customKey} gridColumn={config.gridColumn}>
                <FormikSelectNew
                  name={key}
                  label={config.title}
                  placeholder={config.placeholder}
                  value={config.defaultValue}
                  options={config.options}
                  apiError={errorsApi}
                  hasPortal
                />
              </Wrapper>
            );

          case 'datepicker':
            return (
              <Box key={customKey} mb='24px'>
                <FormikDatePicker key={customKey} label={config.title} name={key} placeholder={config.placeholder} />
              </Box>
            );

          case 'password':
            return (
              <Wrapper key={customKey} gridColumn={config.gridColumn}>
                <FormikPasswordInput
                  label={config.title}
                  name={key}
                  placeholder={config.placeholder}
                  errorsApi={errorsApi}
                  autoComplete={config.autoComplete}
                  isCopyPasteDisabled
                />
              </Wrapper>
            );

          case 'password_without_visible':
            return (
              <Wrapper key={customKey} gridColumn={config.gridColumn}>
                <FormikInput
                  type={'password'}
                  label={config.title}
                  name={key}
                  placeholder={config.placeholder}
                  errorsApi={errorsApi}
                  autoComplete={config.autoComplete}
                  isCopyPasteDisabled
                />
              </Wrapper>
            );

          case 'confirm':
            return (
              <Wrapper key={customKey} gridColumn={config.gridColumn}>
                <FormikInput
                  label={config.title}
                  name={key}
                  placeholder={config.placeholder}
                  errorsApi={errorsApi}
                  autoComplete={'off'}
                  isCopyPasteDisabled
                  isConfirmTicks={true}
                />
              </Wrapper>
            );

          case 'text_area':
            return (
              <Wrapper key={customKey} gridColumn={config.gridColumn}>
                <FormikTextArea
                  name={key}
                  label={config.title}
                  placeholder={config.placeholder}
                  apiError={errorsApi}
                  rows={config.textareaRows}
                />
              </Wrapper>
            );

          case 'radio_group':
            return (
              <RadioGroupWrapper key={customKey} gridColumn={config.gridColumn}>
                <RadioGroup title={config.title} radioGroupConfig={radioGroupConfig} disabled={disableForm} />
              </RadioGroupWrapper>
            );

          case 'divider':
            return (
              <DividerWrapper key={customKey} gridColumn={config.gridColumn}>
                <FormDivider>{config.title}</FormDivider>
              </DividerWrapper>
            );

          case 'phone_input':
            const isPhoneDisabled = disableForm || config.disabled;

            return (
              <PhoneInputWrapper key={customKey} gridColumn={config.gridColumn}>
                <FormikPhoneInput name={key} disabled={isPhoneDisabled} label={config.title} apiError={errorsApi} />
              </PhoneInputWrapper>
            );

          default:
            return (
              <Wrapper key={customKey} gridColumn={config.gridColumn || '1 / 3'}>
                <FormikInput
                  label={config.title}
                  name={key}
                  placeholder={config.placeholder}
                  disabled={disableForm || config.disabled}
                  width={config.width}
                  iconRight={config.iconRight}
                  errorsApi={errorsApi}
                  autoComplete={config.autoComplete}
                  isCopyPasteDisabled={config.isCopyPasteDisabled}
                  tooltipMessage={config.tooltipMessage}
                />
              </Wrapper>
            );
        }
      })}
    </BlockWrapper>
  );
};
