import { useState } from 'react';
import { UserDetailsHeader } from '@/bundle/shared/components/UserDetails/UserDetailsHeader/UserDetailsHeader';
import {
  UserDetailsActionRow,
  UserDetailsCard,
  UserDetailsRow,
  UserDetailsRowTitle,
  UserDetailsRowValue,
} from '@/bundle/_Opco/shared/styles';
import { ReactComponent as LogoSmallIcon } from '../../../../../_OpcoUsersPage/Details/images/LogoSmall.svg';
import { OrganizationUserType } from '@/bundle/_Opco/types/types';
import { phoneFormatter } from '@/helpers/formatHelpers';
import { UserRole } from '@/bundle/_Opco/shared/UserRole/UserRole';
import { ROLE_LABEL_MAP } from '@/bundle/_Opco/shared/const';
import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import { OpcoUserStatus } from '@/bundle/_Opco/_OpcoUsersPage/ui/OpcoUserStatus/OpcoUserStatus';
import { Notification } from '@/components/Notification/Notification';
import { NotificationMessage, NotificationTitle } from '@/components/Notification/styles';
import { BlockWrapper, EditIconBlock, InviteNotification, Wrapper } from './styles';
import { ReactComponent as EditIcon } from '../../../../../../../images/EditIcon.svg';
import { Drawer } from '@/components/Drawer/Drawer';
import { EditMobilePhoneForm } from '@/bundle/_Opco/ui/OrganizationUsers/Details/ui/OrganizationUserAccountDetails/EditMobilePhoneForm';
import { ORGANIZATION_USER_STATUS } from '../../../const/const';
import { ConfirmModal } from '@/components/ConfirmModal/ConfirmModal';
import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { blockOrganizationUser, unblockOrganizationUser } from '@/bundle/_Opco/ui/OrganizationUsers/Details/api';
import { CheckPinCodeModal } from '@/bundle/shared/components/CheckPinCodeModal/CheckPinCodeModal';
import { PIN_TOKEN_EVENT_SCOPE, PinTokenEventScopeType, UPDATE_USER_MOBILE_PHONE_NOTIFICATION } from '@/const/shared';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { showSnackbar } from '@/components/Snackbar/Snackbar';

type OrganizationUserAccountDetailsType = {
  user: OrganizationUserType;
  onSave: () => void;
};

export const OrganizationUserAccountDetails = ({ user, onSave }: OrganizationUserAccountDetailsType) => {
  const { organizationId, userId } = useParams<{ organizationId: string; userId: string }>();

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isBlockOpen, setIsBlockOpen] = useState(false);
  const [isUnblockOpen, setIsUnblockOpen] = useState(false);
  const [isPinCodeOpen, setIsPinCodeOpen] = useState(false);
  const [eventScope, setEventScope] = useState<PinTokenEventScopeType>(null);

  const { email, mobile_phone, status, role } = user;

  const { mutate: blockUserMutate, isPending: isPendingBlockUser } = useMutation({
    mutationKey: ['block_organization_user', organizationId, userId],
    mutationFn: (pinCode: string) => {
      return blockOrganizationUser(organizationId, userId, pinCode);
    },
    onSuccess: (blockedUserResponse) => {
      if (blockedUserResponse?.error) {
        const error = getResponseError(blockedUserResponse?.error);

        showSnackbar(error, { variant: 'error' });

        return;
      }

      showSnackbar('User account has been blocked.');
      onSave();
    },
  });

  const { mutate: unblockUserMutate, isPending: isPendingUnblockUser } = useMutation({
    mutationKey: ['unblock_organization_user', organizationId, userId],
    mutationFn: (pinCode: string) => {
      return unblockOrganizationUser(organizationId, userId, pinCode);
    },
    onSuccess: (unblockUserResponse) => {
      if (unblockUserResponse?.error) {
        const error = getResponseError(unblockUserResponse?.error);

        showSnackbar(error, { variant: 'error' });

        return;
      }

      showSnackbar('User account has been unblocked.');
      onSave();
    },
  });

  const openEdit = () => setIsEditOpen(true);

  const closeEdit = () => setIsEditOpen(false);

  const openBlock = () => setIsBlockOpen(true);

  const closeBlock = () => setIsBlockOpen(false);

  const openUnblock = () => setIsUnblockOpen(true);

  const closeUnblock = () => setIsUnblockOpen(false);

  const openBlockUserPinCode = () => {
    setEventScope(PIN_TOKEN_EVENT_SCOPE.BLOCK_ORG_ADMIN);
    closeBlock();
    setIsPinCodeOpen(true);
  };

  const openUnblockUserPinCode = () => {
    setEventScope(PIN_TOKEN_EVENT_SCOPE.UNBLOCK_ORG_ADMIN);
    closeUnblock();
    setIsPinCodeOpen(true);
  };

  const closePinCode = () => {
    setIsPinCodeOpen(false);
    setEventScope(null);
  };

  const onSuccess = () => {
    onSave();
    closeEdit();

    showSnackbar(UPDATE_USER_MOBILE_PHONE_NOTIFICATION, { maxWidth: '375px' });
  };

  const checkPinCodeSuccess = (pinToken: string) => {
    if (!eventScope) return;

    if (eventScope === PIN_TOKEN_EVENT_SCOPE.BLOCK_ORG_ADMIN) {
      blockUserMutate(pinToken);
      closePinCode();

      return;
    }

    unblockUserMutate(pinToken);
    closePinCode();

    return;
  };

  const formattedMobilePhone = phoneFormatter(mobile_phone);
  const formattedRole = ROLE_LABEL_MAP[role];
  const isInvited = status === ORGANIZATION_USER_STATUS.INVITED;
  const isAvailableToBlock = status === ORGANIZATION_USER_STATUS.ACTIVE || status === ORGANIZATION_USER_STATUS.INVITED;
  const isAvailableToUnblock = status === ORGANIZATION_USER_STATUS.BLOCKED;

  return (
    <Box flexDirection='row' columnGap='28px'>
      <Wrapper>
        <BlockWrapper>
          <UserDetailsHeader
            title='WireVault Account Information'
            icon={<LogoSmallIcon />}
            subTitle='You must enter your PIN code to edit these fields.'
          />
          <UserDetailsCard>
            <UserDetailsRow>
              <UserDetailsRowTitle>Email Address</UserDetailsRowTitle>
              <UserDetailsRowValue>{email}</UserDetailsRowValue>
            </UserDetailsRow>
            <UserDetailsRow>
              <Box flexDirection='row' justifyContent='space-between'>
                <Box>
                  <UserDetailsRowTitle>Mobile Phone Number</UserDetailsRowTitle>
                  <UserDetailsRowValue>{formattedMobilePhone}</UserDetailsRowValue>
                </Box>
                {isInvited && (
                  <EditIconBlock onClick={openEdit}>
                    <EditIcon />
                  </EditIconBlock>
                )}
              </Box>
            </UserDetailsRow>
            <UserDetailsRow>
              <UserDetailsRowTitle>Role</UserDetailsRowTitle>
              <UserRole role={formattedRole}></UserRole>
            </UserDetailsRow>
            <UserDetailsActionRow>
              <Box>
                <UserDetailsRowTitle>Status</UserDetailsRowTitle>
                {/* TODO: refactor this to own status component */}
                <OpcoUserStatus status={status} />
              </Box>

              <Box display='flex' columnGap='12px' alignItems='center'>
                {isAvailableToBlock && (
                  <Button width={71} size='medium' color='secondary' onClick={openBlock}>
                    Block
                  </Button>
                )}

                {isAvailableToUnblock && (
                  <Button width={90} size='medium' color='secondary' onClick={openUnblock}>
                    Unblock
                  </Button>
                )}
              </Box>
            </UserDetailsActionRow>
          </UserDetailsCard>
        </BlockWrapper>
        {isInvited && (
          <InviteNotification>
            <Notification>
              <NotificationTitle>The invitation to WireVault has not been accepted.</NotificationTitle>
              <NotificationMessage>
                Please be sure the email address and mobile phone number are correct. You can change the information for
                this account until the invitation is accepted.
              </NotificationMessage>
            </Notification>
          </InviteNotification>
        )}
      </Wrapper>

      <ConfirmModal
        isLoading={isPendingBlockUser}
        isOpen={isBlockOpen}
        header='Block Org Admin'
        body='This User will no longer be able to access WireVault as an admin of the organization.
          You can choose to unblock them to enable to work with the transactions and wires of the organization later.'
        onClose={closeBlock}
        onConfirm={openBlockUserPinCode}
      />

      <ConfirmModal
        isLoading={isPendingUnblockUser}
        isOpen={isUnblockOpen}
        header='Unblock Org Admin'
        body='The User will be able to manage transactions and wires of this organization again.'
        onClose={closeUnblock}
        onConfirm={openUnblockUserPinCode}
      />

      <Drawer
        isOpen={isEditOpen}
        header='Edit Org Admin'
        subHeader='Please edit Org Admin’s information.'
        onClose={closeEdit}
      >
        <EditMobilePhoneForm onClose={closeEdit} mobilePhone={mobile_phone} onSuccess={onSuccess} />
      </Drawer>
      {isPinCodeOpen && (
        <CheckPinCodeModal
          isOpen={isPinCodeOpen}
          onSuccess={checkPinCodeSuccess}
          onClose={closePinCode}
          eventScope={eventScope}
        />
      )}
    </Box>
  );
};
