import { useReplaceUrl } from '@/hooks/useReplaceUrl';
import { useCallback } from 'react';

export const useTableSorting = () => {
  const { replaceUrl } = useReplaceUrl();

  const tableSorting = useCallback(
    (column, isSort, isSortAsc, isSortDesc) => {
      let sortParam;

      if (isSort) {
        sortParam = column;
      }

      if (isSortAsc) {
        sortParam = `-${column}`;
      }

      if (isSortDesc) {
        sortParam = null;
      }

      const newQueryParams = { ordering: sortParam };

      return replaceUrl(newQueryParams);
    },
    [replaceUrl]
  );

  return {
    tableSorting,
  };
};
