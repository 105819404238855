import { UserRoleType } from '@/const/user';
import { sessionStorageService } from './storageHelpers';
import { CURRENT_ROLE_KEY } from '@/const/shared';
import { SelfUserDetailsType } from '@/api/v1/users/getSelfUserDetailsApi';

export const getStorageCurrentRole = () => {
  const currentRole: UserRoleType = sessionStorageService.get(CURRENT_ROLE_KEY);

  return currentRole;
};

export const setStorageCurrentRole = (role: UserRoleType) => {
  sessionStorageService.set(CURRENT_ROLE_KEY, role);
};

export const getOrganizationName = (user: SelfUserDetailsType) => {
  if (!user || !user?.organization_user) return '';

  return user?.organization_user?.organization?.name || '';
};

export const hasMultipleRoles = (user: SelfUserDetailsType) => {
  if (!user) return false;

  return user?.active_roles?.length > 1;
};

export const hasRole = (user: SelfUserDetailsType, role: UserRoleType) => {
  if (!user) return false;

  return user.active_roles?.includes(role);
};
