import { FormGeneratorNew } from '@/components/form/FormGeneratorNew/FormGeneratorNew';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import {
  getChangedFormValues,
  getInitialValues,
  getMergedValues,
  getValidationSchema,
} from '@/helpers/formHelpers/formHelpers';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { ButtonsWrapper } from './styles';
import { Button } from '@/components/Button/Button';
import { OrganizationModifyType } from '../../types/types';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { getCreateOrganizationConfig, getEditOrganizationConfig, pricingTierOptionsFormatter } from './const';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { Notification } from '@/components/Notification/Notification';
import { createOrganization, loadOrganization, updateOrganization } from '../../api';
import { PricingTierType } from '@/api/v1/opco/organizations/getOrganizationPricingTier';

type FormValuesType = {
  ein: string;
  name: string;
  pricing_tier: { label: string; value: string };
  status: { label: string; value: 'active' | 'blocked' };
  is_kyc_allowed: boolean;
};

type ModifyOrganizationFormType = {
  organizationId?: string;
  isEditMode: boolean;
  onSuccess: () => void;
  onCancel: () => void;
  pricingTierList: PricingTierType[];
};

export const ModifyOrganizationForm = ({
  organizationId,
  onSuccess,
  onCancel,
  isEditMode,
  pricingTierList,
}: ModifyOrganizationFormType) => {
  const { data: organizationData, isPending: isPendingOrganization } = useQuery({
    queryKey: ['load_organization', organizationId],
    queryFn: () => loadOrganization(organizationId),
    enabled: isEditMode,
  });

  const { mutate, data, isPending } = useMutation({
    mutationKey: ['modify_organization'],
    mutationFn: (payload: OrganizationModifyType) => {
      return isEditMode ? updateOrganization(organizationId, payload) : createOrganization(payload);
    },
    onSuccess(response) {
      if (response.error) return;

      const currentEvent = isEditMode
        ? AMPLITUDE_EVENTS.EditOrganizationSuccess
        : AMPLITUDE_EVENTS.CreateOrganizationSuccess;

      amplitudeService.logEvent(currentEvent);

      onSuccess();
    },
  });

  const pricingTierOptions = pricingTierOptionsFormatter(pricingTierList);
  const createOrganizationConfig = getCreateOrganizationConfig(pricingTierOptions);

  const editOrganizationConfig = getEditOrganizationConfig(pricingTierOptions);
  const formConfig = isEditMode ? editOrganizationConfig : createOrganizationConfig;
  const formattedEditOrganizationData = {
    ...organizationData?.body,
    pricing_tier: organizationData?.body?.pricing_tier?.id,
  };

  const initialValues = isEditMode
    ? getMergedValues<FormValuesType>(editOrganizationConfig, formattedEditOrganizationData)
    : getInitialValues<FormValuesType>(createOrganizationConfig);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: getValidationSchema(formConfig),
    onSubmit: async (values: FormValuesType) => {
      if (!isEditMode) {
        const { name, ein, pricing_tier, is_kyc_allowed } = values;
        const payload = {
          name,
          ein,
          pricing_tier: pricing_tier.value,
          is_kyc_allowed,
        };

        return mutate(payload);
      }

      const payload = getChangedFormValues<FormValuesType>(values, initialValues);

      if (!payload) {
        onCancel();

        return;
      }

      const formattedPayload = {
        ...payload,
        ...(payload.status && { status: values.status.value }),
        ...(payload.pricing_tier && { pricing_tier: values.pricing_tier.value }),
      };

      await mutate(formattedPayload);
    },
  });

  const isLoading = isPending || (isEditMode && isPendingOrganization);
  const formError = getResponseError(data?.error);

  return (
    <>
      {formError && (
        <Notification variant='error' mb='24px'>
          {formError}
        </Notification>
      )}
      <FormikForm value={formik}>
        <FormGeneratorNew config={formConfig} apiError={data?.error} />
        <ButtonsWrapper>
          <Button type='button' color='secondary' onClick={onCancel}>
            Cancel
          </Button>
          <Button type='submit' isLoading={isLoading}>
            Save
          </Button>
        </ButtonsWrapper>
      </FormikForm>
    </>
  );
};
