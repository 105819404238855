import styled from 'styled-components';
import { body, bodySmall, caption, poppinsRegularFont, poppinsSemiBoldFont } from '@/styles/typography';
import { flex } from '@/styles/layout';
import { COLORS } from '@/styles/colors';
import { ReactComponent as ConfirmInputTicks } from '../../../../images/ConfirmInputTicks.svg';

export const FormikInputWrapper = styled.div<{
  width?: number;
  maxWidth?: number;
  minWidth?: number;
}>`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'none')};
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : 'auto')};
`;

export const LabelWrapper = styled.div`
  ${flex({ alignItems: 'center', justifyContent: 'space-between' })}
  ${bodySmall};
  column-gap: 10px;
`;

export const Label = styled.div`
  ${bodySmall};
  color: ${COLORS.black};
  ${poppinsRegularFont}
`;

export const CustomInputWrapper = styled.div`
  position: relative;
`;

export const Input = styled.input<{
  hasError?: boolean;
  width?: number;
  hasSmallIcon?: boolean;
  disabled?: boolean;
  isOpen?: boolean;
  hasConfirmTicks?: boolean;
  hasToggleEye?: boolean;
  hasValue?: boolean;
}>`
  display: block;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  color: ${COLORS.black};
  border: ${({ hasError }) => (hasError ? '1px solid #D30101' : '1px solid #BEC2CC')};
  outline: none;
  ${body};
  padding: 13px 16px;
  border-radius: 10px;
  height: 50px;
  margin-top: 4px;
  background: ${COLORS.grey[250]};
  ${poppinsRegularFont};

  ${({ hasConfirmTicks, hasToggleEye }) =>
    (hasConfirmTicks || hasToggleEye) &&
    `
    padding-right: 46px;
  `};

  ${({ hasConfirmTicks, hasToggleEye }) =>
    hasConfirmTicks &&
    hasToggleEye &&
    `
    padding-right: 76px;
  `};

  ${({ hasValue }) =>
    hasValue &&
    `
    padding-right: 40px;
  `}

  &[type='password'] {
    font-size: 22px;
    letter-spacing: 1px;
  }

  &:hover {
    border: 1px solid ${COLORS.grey[700]};
    background: ${COLORS.grey[250]};
  }

  &:focus {
    border: 1px solid #00866f;
    box-shadow: 0 0 2px rgba(0, 134, 111, 0.6);
    background: ${COLORS.white};
    color: #031020;
  }

  &:disabled {
    border: 1px solid ${COLORS.grey[300]};
    background-color: ${COLORS.grey[200]};
  }

  &:placeholder-shown {
    ${body};
  }

  ::placeholder {
    ${body};
    color: ${COLORS.grey[600]};
    font-family: ${poppinsRegularFont};
  }

  :-ms-input-placeholder {
    ${body};
    color: ${COLORS.grey[600]};
    font-family: ${poppinsRegularFont};
  }

  ::-ms-input-placeholder {
    ${body};
    color: ${COLORS.grey[600]};
    font-family: ${poppinsRegularFont};
  }
`;

export const InputSmall = styled(Input)`
  height: 40px;
`;

export const CustomInputDatePicker = styled(Input)<{ isOpen: boolean }>`
  &:hover {
    border: 1px solid #4b505a !important;
  }

  &:disabled {
    border: ${({ isOpen }) => (isOpen ? `1px solid ${COLORS.green[500]}` : `1px solid ${COLORS.grey[400]}`)};
    box-shadow: ${({ isOpen }) => (isOpen ? `0 0 2px rgba(0, 134, 111, 0.6)` : '')};
    background-color: #fafbfe;
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  bottom: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  right: 18px;
`;

export const FormikInputFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ConfirmationTicks = styled(ConfirmInputTicks)`
  position: absolute;
  top: 15px;
  right: 16px;
`;

export const LabelSlotWrapper = styled.span`
  ${poppinsSemiBoldFont};
  ${caption};
  color: ${COLORS.grey[700]};
`;
