import * as Yup from 'yup';
import {
  BANK_ABA_NUMBER_VALIDATOR,
  CONFIRM_BANK_ABA_NUMBER_VALIDATOR,
  CONFIRM_BANK_ACCOUNT_NUMBER,
  CONFIRM_INTERMEDIARY_BANK_ABA_NUMBER_VALIDATOR,
  CONFIRM_INTERMEDIARY_BANK_ACCOUNT_NUMBER,
  CONFIRM_INTERMEDIARY_SWIFT_CODE_VALIDATOR,
  CONFIRM_SWIFT_CODE_VALIDATOR,
  PHONE_NUMBER_VALIDATOR,
  REQUIRED_TEXT_FIELD_VALIDATOR,
  SWIFT_CODE_VALIDATOR,
  TEXT_FIELD_VALIDATOR,
} from '@/helpers/formHelpers/validators';

export const createDepositAccountValidationSchema = (isDomestic: boolean, isOpenIntermediaryBank: boolean) => {
  const domesticValidation = {
    name: REQUIRED_TEXT_FIELD_VALIDATOR,
    recipient_name: REQUIRED_TEXT_FIELD_VALIDATOR,
    recipient_address: REQUIRED_TEXT_FIELD_VALIDATOR,
    account_name: TEXT_FIELD_VALIDATOR,
    bank_aba_number: BANK_ABA_NUMBER_VALIDATOR,
    confirm_bank_aba_number: CONFIRM_BANK_ABA_NUMBER_VALIDATOR,
    bank_name: TEXT_FIELD_VALIDATOR,
    bank_account_number: REQUIRED_TEXT_FIELD_VALIDATOR,
    confirm_bank_account_number: CONFIRM_BANK_ACCOUNT_NUMBER,
    bank_address: TEXT_FIELD_VALIDATOR,
    bank_phone_number: PHONE_NUMBER_VALIDATOR,
  };

  const internationalValidation = {
    name: REQUIRED_TEXT_FIELD_VALIDATOR,
    recipient_name: REQUIRED_TEXT_FIELD_VALIDATOR,
    recipient_address: REQUIRED_TEXT_FIELD_VALIDATOR,
    account_name: TEXT_FIELD_VALIDATOR,
    bank_swift_code: SWIFT_CODE_VALIDATOR,
    confirm_bank_swift_code: CONFIRM_SWIFT_CODE_VALIDATOR,
    bank_name: TEXT_FIELD_VALIDATOR,
    bank_account_number: REQUIRED_TEXT_FIELD_VALIDATOR,
    confirm_bank_account_number: CONFIRM_BANK_ACCOUNT_NUMBER,
    bank_address: TEXT_FIELD_VALIDATOR,
    bank_phone_number: PHONE_NUMBER_VALIDATOR,
  };

  const intermediaryDomesticValidation = {
    intermediary_bank_aba_number: BANK_ABA_NUMBER_VALIDATOR,
    confirm_intermediary_bank_aba_number: CONFIRM_INTERMEDIARY_BANK_ABA_NUMBER_VALIDATOR,
    intermediary_bank_name: TEXT_FIELD_VALIDATOR,
    intermediary_bank_account_number: REQUIRED_TEXT_FIELD_VALIDATOR,
    confirm_intermediary_bank_account_number: CONFIRM_INTERMEDIARY_BANK_ACCOUNT_NUMBER,
    intermediary_bank_address: TEXT_FIELD_VALIDATOR,
    intermediary_bank_phone_number: PHONE_NUMBER_VALIDATOR,
  };

  const intermediaryInternationalValidation = {
    intermediary_bank_swift_code: SWIFT_CODE_VALIDATOR,
    confirm_intermediary_bank_swift_code: CONFIRM_INTERMEDIARY_SWIFT_CODE_VALIDATOR,
    intermediary_bank_name: TEXT_FIELD_VALIDATOR,
    intermediary_bank_account_number: REQUIRED_TEXT_FIELD_VALIDATOR,
    confirm_intermediary_bank_account_number: CONFIRM_INTERMEDIARY_BANK_ACCOUNT_NUMBER,
    intermediary_bank_address: TEXT_FIELD_VALIDATOR,
    intermediary_bank_phone_number: PHONE_NUMBER_VALIDATOR,
  };

  const domesticWithIntermediaryValidation = {
    ...domesticValidation,
    ...intermediaryDomesticValidation,
  };

  const internationalWithIntermediaryValidation = {
    ...internationalValidation,
    ...intermediaryInternationalValidation,
  };

  const currentDomesticValidation = isOpenIntermediaryBank ? domesticWithIntermediaryValidation : domesticValidation;

  const currentInternationalValidation = isOpenIntermediaryBank
    ? internationalWithIntermediaryValidation
    : internationalValidation;

  const currentValidation = isDomestic ? currentDomesticValidation : currentInternationalValidation;

  return Yup.object().shape(currentValidation);
};
