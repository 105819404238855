import { ContentLayout } from '@/bundle/Layouts/ContentLayout/ContentLayout';
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { loadOpcoUsers } from './api';
import { usePrepareQueryParams } from '@/helpers/apiHelpers/queryHelper';
import { OpcoUsersTable } from './ui/OpcoUsersTable';
import { PageFooter } from '@/components/Page/PageFooter/PageFooter';
import { Paginator } from '@/components/Paginator/Paginator';
import { PageBody } from '@/components/Page/PageBody/PageBody';
import { useState } from 'react';
import { Drawer } from '@/components/Drawer/Drawer';
import { CreateOpcoUserForm } from './ui/CreateOpcoUserForm/CreateOpcoUserForm';
import { Button } from '@/components/Button/Button';
import { ReactComponent as PlusIcon } from '../../../images/PlusIcon.svg';

export const OpcoUsersPage = () => {
  const queryClient = useQueryClient();
  const params = usePrepareQueryParams();
  const [isOpen, setIsOpen] = useState(false);

  const { data } = useQuery({
    queryKey: ['load_opco_users', params],
    queryFn: () => loadOpcoUsers(params),
    placeholderData: keepPreviousData,
  });

  const opcoUsers = data?.body?.results;
  const totalCount = data?.body?.count;
  const hasPaginator = totalCount > params?.limit;

  const openCreateUserForm = () => {
    setIsOpen(true);
  };

  const closeForm = () => {
    setIsOpen(false);
  };

  const createOpcoUserSuccess = () => {
    closeForm();
    queryClient.invalidateQueries({ queryKey: ['load_opco_users'] });
  };

  return (
    <ContentLayout
      title='OpCo Users'
      subtitle={
        <>
          View the list of OpCo Users.
          <br />
          Click on a particular row to view the data for the particular User.
        </>
      }
      headerAction={
        <Button width={150} iconPosition='left' icon={<PlusIcon />} onClick={openCreateUserForm} mobileStretch>
          Create User
        </Button>
      }
    >
      <PageBody>
        <OpcoUsersTable items={opcoUsers} />
      </PageBody>
      {hasPaginator && (
        <PageFooter>
          <Paginator page={params.page} limit={params.limit} total={totalCount} />
        </PageFooter>
      )}

      <Drawer
        isOpen={isOpen}
        onClose={closeForm}
        header='Create OpCo Admin'
        subHeader={<>Please provide information for the new OpCo Admin.</>}
      >
        <CreateOpcoUserForm onCancel={closeForm} onSuccess={createOpcoUserSuccess} />
      </Drawer>
    </ContentLayout>
  );
};
