import { css } from 'styled-components';
import { THEME } from './theme';

export const customVerticalScrollBase = css`
  ::-webkit-scrollbar {
    width: ${THEME.layout.scrollWidth};
  }

  ::-webkit-scrollbar-thumb {
    background: #d5dee3;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const ellipsis = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const resetList = css`
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
`;

export const resetButton = css`
  background: transparent;
  padding: 0;
  outline: none;
  border: none;
`;

export const overlayBackground = css`
  background: rgba(102, 111, 120, 0.5);
  backdrop-filter: blur(1.5px);
`;

export const customVerticalScroll = css`
  scroll-behavior: smooth;

  ${customVerticalScrollBase};
`;
