import { ErrorHintWrapper, ErrorHintText, ContactUsLink } from './styles';
import { ReactComponent as AlertIcon2 } from '../../../../images/Alert2Icon.svg';
import { getContactUsPath } from '../../../../bundle/pages/ContactUsPage/urls/getContactUsUrl';

export const ErrorHint = () => {
  return (
    <ErrorHintWrapper>
      <AlertIcon2 />
      <ErrorHintText>
        Something went wrong. Please contact <ContactUsLink to={getContactUsPath()}>WireVault team</ContactUsLink>
      </ErrorHintText>
    </ErrorHintWrapper>
  );
};
