import { CSSObject } from 'styled-components';
import { BoxWrapperPropsType, SpacingPropsType } from './styles';

const getSpacingStyles = ({ mt, mr, mb, ml, m }: SpacingPropsType) => {
  // When <m> is defined it gets highest priority to parse, otherwise parse partial margins
  if (m) {
    if (Array.isArray(m)) {
      return { margin: m.join(' ') };
    } else {
      return { margin: m };
    }
  }

  return {
    marginTop: mt,
    marginRight: mr,
    marginBottom: mb,
    marginLeft: ml,
  };
};

export const boxStyles = (props: BoxWrapperPropsType): CSSObject => {
  const { display, justifyContent, alignItems, flexDirection, flexWrap, rowGap, columnGap, width, position } = props;
  const displayStyle = justifyContent || alignItems || flexDirection ? 'flex' : display;
  const spacingStyles = getSpacingStyles(props);

  return {
    display: displayStyle,
    justifyContent,
    alignItems,
    flexDirection,
    flexWrap,
    rowGap,
    columnGap,
    width,
    position,
    ...spacingStyles,
  };
};
