import { COLORS } from '@/styles/colors';
import styled from 'styled-components';

export const SelectOptionsWrapper = styled.div<{ height?: number }>`
  max-width: 100%;
  height: ${({ height }) => (height ? `${height}px` : 'max-content')};
  background: white;
  padding: 5px 0;
  overflow: auto;
  z-index: 1;
  overflow-wrap: anywhere;
  border: 1px solid #4b505a;
  box-shadow: 0px 4px 8px 0px #00062633;
  border-radius: 10px;
`;

export const TSelectOptionsItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  cursor: pointer;
  color: ${COLORS.black};
  min-height: 36px;
  grid-gap: 10px;
  font-size: 14px;
  line-height: 24px;

  &:hover {
    background: #eceef4;
    border-radius: 4px;
  }
`;
