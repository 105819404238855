import { ChipsButton, ChipsTitle, ChipsWrapper } from './styles';

type ChipsPropsType = {
  title: string;
  onClick: () => void;
  isRemovable?: boolean;
};

export const Chips = ({ title, onClick, isRemovable }: ChipsPropsType) => {
  return (
    <ChipsWrapper>
      <ChipsTitle>{title}</ChipsTitle>
      {isRemovable && <ChipsButton onClick={onClick}>&times;</ChipsButton>}
    </ChipsWrapper>
  );
};
