import { DetailsRow } from '@/components/DetailsWidget/DetailsRow/DetailsRow';
import { DetailsWidget } from '@/components/DetailsWidget/DetailsWidget';
import { Status } from '../../../../components/Status/Status';
import { WIRE_STATUS_DB } from '@/const/wire';
import { dateFormatter, formatAmount } from '@/helpers/formatHelpers';
import { AssignedWireType } from '@/types/wireTypes';
import { Notification } from '@/components/Notification/Notification';
import { ContactUsLink } from '@/components/Widget/ui/ErrorHint/styles';
import { getContactUsPath } from '../../../pages/ContactUsPage/urls/getContactUsUrl';
import { DetailsRowNotes } from '@/components/DetailsWidget/DetailsRowNotes/DetailsRowNotes';

type WireDetailsType = {
  wire: AssignedWireType;
};

const TOOLTIP_DATA = { name: 'blockchain wire', content: 'This wire has been submitted to the WireVault blockchain.' };

export const WireDetails = ({ wire }: WireDetailsType) => {
  const {
    name,
    contract,
    status,
    ref_id,
    date_created,
    expected_payment_date,
    created_by,
    date_modified,
    amount,
    notes,
  } = wire;
  const tooltip = status === WIRE_STATUS_DB.PENDING ? TOOLTIP_DATA : null;
  const isFailedWire = status === WIRE_STATUS_DB.FAILED;

  return (
    <DetailsWidget>
      <DetailsRow name='Transaction Name'>{contract?.name || '-'}</DetailsRow>
      <DetailsRow name='Wire Name' direction='column'>
        {name}
      </DetailsRow>
      {isFailedWire && (
        <Notification variant='error' fontVariant='medium' verticalAlign='center' mb='12px' inline>
          Something went wrong. Please contact&nbsp;
          <ContactUsLink to={getContactUsPath()}>WireVault team</ContactUsLink>
        </Notification>
      )}

      <DetailsRow name='Status' tooltip={tooltip}>
        <Status status={status} />
      </DetailsRow>
      <DetailsRow name='Wire Amount' hasBorder>
        {formatAmount(amount)}
      </DetailsRow>
      <DetailsRow name='Wire ID'>{ref_id || '-'}</DetailsRow>
      <DetailsRow name='Wire Creation Date'>{dateFormatter(date_created)}</DetailsRow>
      <DetailsRow name='Expected Payment Date'>{dateFormatter(expected_payment_date)}</DetailsRow>
      <DetailsRow name='Created by'>{created_by?.email || '-'}</DetailsRow>
      <DetailsRow name='Last Update'>{dateFormatter(date_modified)}</DetailsRow>
      <DetailsRowNotes name='Notes'>{notes}</DetailsRowNotes>
    </DetailsWidget>
  );
};
