import { IBilledEventsParams } from '@/bundle/_Opco/_BillingPage/hooks/filterListConfig';
import { ContractFilterListParams } from '@/bundle/_OrgAdmin/pages/Transactions/hooks/useFiltersListConfig';

export interface IOrganizationsListFilterListParam {
  name?: string | null;
  date_picker?: string | null;
}

export interface FilterParamsType
  extends QueryParamsType,
    ContractFilterListParams,
    IOrganizationsListFilterListParam,
    IBilledEventsParams {}

export interface QueryParamsType {
  page?: number | null;
  limit?: number | null;
  ordering?: string | null;
  filters?: FilterParamsType | null;
  search?: string | null;
  email?: string | null;
}

export const DEFAULT_LIMIT = 8;

export const defaultQueryParams: QueryParamsType = {
  page: 0,
  limit: DEFAULT_LIMIT,
  ordering: null,
  filters: null,
  search: null,
  email: null,
};

export const defaultFilterParams: FilterParamsType = {
  contract__name: null,
  name: null,
  wire__contract__name: null,
  organization__id: null,
  date_created_after: null,
  date_created_before: null,
};

export const prepareFilters = (query: URLSearchParams): FilterParamsType => {
  const filtersStr = query.get('filters');

  if (!filtersStr || filtersStr === '{}') {
    return null;
  }

  const filterValues: FilterParamsType = JSON.parse(filtersStr);

  const filters: FilterParamsType = {};

  const filerNames = Object.keys(defaultFilterParams) as (keyof FilterParamsType)[];

  filerNames.forEach((filterName) => {
    const value = filterValues[filterName];

    if (value) {
      // @ts-ignore
      filters[filterName] = value;
    }
  });

  return filters;
};

export const prepareUrl = (location: Location, queryParams: QueryParamsType): string => {
  let newUrl = location.pathname;

  const newSearchParams = new URLSearchParams(location.search);

  if (JSON.stringify(defaultQueryParams) !== JSON.stringify(queryParams)) {
    const paramNameList = Object.keys(queryParams) as Array<keyof QueryParamsType>;

    paramNameList.forEach((paramName) => {
      const currentParam = queryParams[paramName];

      if (JSON.stringify(currentParam) !== JSON.stringify(defaultQueryParams[paramName]) && currentParam !== null) {
        return newSearchParams.set(paramName as string, queryParams[paramName] as string);
      }

      return newSearchParams.delete(paramName);
    });

    let paramPrefix = '&';

    if (!newUrl.includes('?')) {
      paramPrefix = '?';
    }

    newUrl += paramPrefix + newSearchParams.toString();
  }

  return newUrl;
};
