import capitalize from 'lodash/capitalize';
import { IntermediaryBankBlock } from '@/bundle/_OrgAdmin/pages/OutboundWire/_Details/styles';
import { LoadPaymentDetailsWidget } from '@/bundle/_OrgAdmin/pages/OutboundWire/_Details/ui/LoadPaymentDetailsWidget/LoadPaymentDetailsWidget';
import { Button } from '@/components/Button/Button';
import { DetailsBlockGenerator } from '@/components/DetailsBlockGenerator/DetailsBlockGenerator';
import { EmailLink } from '@/components/EmailLink/EmailLink';
import { Notification } from '@/components/Notification/Notification';
import { NotificationMessage } from '@/components/Notification/styles';
import { WIRE_STATUS_DB } from '@/const/wire';
import { isPaymentDetailsProvidedWireStatus, isRecipientChainProxy, isRetryWireStatus } from '@/helpers/wireHelpers';
import { AssignedWireType, WireStatusType } from '@/types/wireTypes';
import { useParams } from 'react-router-dom';
import { changeWireStatus, loadRecipientPaymentDetails } from '../../api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getHasIntermediaryBank,
  getPaymentDetailsConfig,
  getPaymentDetailsIntermediaryBankConfig,
} from '@/bundle/shared/helpers/configHelpers';
import { getShowPaymentDetails } from '@/bundle/shared/helpers/getShowPaymentDetails';
import { ProxyLockedPaymentDetailsPreview } from '../ProxyLockedPaymentDetailsPreview/ProxyLockedPaymentDetailsPreview';
import { DetailsWidget } from '@/components/DetailsWidget/DetailsWidget';
import { PaymentDetailsHeaderSlot } from '@/bundle/shared/components/PaymentDetailsHeaderSlot/PaymentDetailsHeaderSlot';
import { SecureMetaWrapper } from '@/bundle/shared/components/PaymentDetailsHeaderSlot/styles';
import { ReactComponent as RecipientChainProxyIcon } from '@/images/RecipientChainProxyIcon.svg';
import { useUser } from '@/context/userContext';
import { Box } from '@/components/Box/Box';
import { PaymentDetailsCollectionType } from '@/bundle/_OrgAdmin/pages/OutboundWire/_Details/ui/OutboundWirePaymentDetails/styles';

const HAS_PAYMENT_DETAILS_STATUSES: Partial<WireStatusType>[] = [
  WIRE_STATUS_DB.CANCELLED,
  WIRE_STATUS_DB.PAYMENT_DETAILS_PROVIDED,
  WIRE_STATUS_DB.EXECUTED,
];

type RecipientPaymentDetailsType = {
  wire: AssignedWireType;
};

export const RecipientPaymentDetails = ({ wire }: RecipientPaymentDetailsType) => {
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();
  const { user } = useUser();

  const { data: paymentDetailsData, isFetching: isFetchingPaymentDetails } = useQuery({
    queryKey: ['load_recipient_payment_details', id],
    queryFn: () => loadRecipientPaymentDetails(id),
    enabled: !!wire?.is_payment_details_allowed_to_view && !!wire?.is_payment_details_exist,
  });

  const { mutate: mutateWireStatus, isPending: isPendingWireStatus } = useMutation({
    mutationKey: ['change_wire_status'],
    mutationFn: () => {
      return changeWireStatus(id, WIRE_STATUS_DB.RECIPIENT_ASSIGNED);
    },
    onSuccess(updatedWire) {
      if (updatedWire.error) return;

      queryClient.setQueryData(['load_recipient_wire', id], updatedWire);
    },
  });

  const paymentDetails = paymentDetailsData?.body;
  const wireStatus = wire?.status;
  const email = wire?.created_by?.email;
  const paymentDetailsHasError = !isFetchingPaymentDetails && !paymentDetails;
  const hasPaymentDetailsWidget = HAS_PAYMENT_DETAILS_STATUSES.includes(wireStatus);

  const isRetryStatus = isRetryWireStatus(wireStatus);
  const hasIntermediaryBank = getHasIntermediaryBank(paymentDetails);
  const paymentDetailsConfig = getPaymentDetailsConfig(paymentDetails);
  const paymentDetailsIntermediaryBankConfig = getPaymentDetailsIntermediaryBankConfig(paymentDetails);
  const isPaymentDetailsProvided = isPaymentDetailsProvidedWireStatus(wireStatus);
  const showPaymentDetails = getShowPaymentDetails(!!paymentDetails, wire);
  const showLockedPaymentDetailsPreview =
    isPaymentDetailsProvidedWireStatus(wireStatus) && !wire?.is_payment_details_allowed_to_view;
  const isChainProxy = isRecipientChainProxy(wire);
  const showPaymentDetailsHeaderSlot =
    wire?.is_payment_details_exist && isChainProxy && user.id === wire?.assigned_recipient_user_by?.id;
  const paymentDetailsCollectionType = capitalize(wire?.payment_details_collection_type);

  return (
    <>
      {isFetchingPaymentDetails && !isRetryStatus && (
        <DetailsWidget header='Wire Info' headerOffset={12}>
          <LoadPaymentDetailsWidget isLoading={isFetchingPaymentDetails} />
        </DetailsWidget>
      )}

      {paymentDetailsHasError && !isRetryStatus && showPaymentDetails && (
        <DetailsWidget header='Wire Info' headerOffset={12}>
          <LoadPaymentDetailsWidget hasError={!isFetchingPaymentDetails && !paymentDetails} />
        </DetailsWidget>
      )}

      {hasPaymentDetailsWidget && paymentDetails && !isFetchingPaymentDetails && (
        <DetailsWidget
          header='Wire Info'
          action={<PaymentDetailsCollectionType>{paymentDetailsCollectionType}</PaymentDetailsCollectionType>}
          headerOffset={12}
          headlineSlot={
            showPaymentDetailsHeaderSlot && (
              <PaymentDetailsHeaderSlot
                icon={<RecipientChainProxyIcon />}
                header='This info was provided by the Recipient.'
                meta={<SecureMetaWrapper>Secure</SecureMetaWrapper>}
              />
            )
          }
        >
          <LoadPaymentDetailsWidget paymentDetails={paymentDetails} />
          <DetailsBlockGenerator configuration={paymentDetailsConfig} />
          {hasIntermediaryBank && (
            <>
              <IntermediaryBankBlock>Intermediary Bank</IntermediaryBankBlock>
              <DetailsBlockGenerator configuration={paymentDetailsIntermediaryBankConfig} />
            </>
          )}
          {isPaymentDetailsProvided && (
            <Notification variant='info' mt='12px'>
              <NotificationMessage>
                If you need to make a change to your wire info for this wire before it is sent, you will need to contact{' '}
                <EmailLink email={email} /> to request a cancellation.
              </NotificationMessage>
            </Notification>
          )}
        </DetailsWidget>
      )}

      {isRetryStatus && (
        <DetailsWidget header='Wire Info'>
          <Notification variant='error'>
            <NotificationMessage>
              This wire has not been recorded in the WireVault blockchain. You will need to provide the data once again.
              Please click "Retry".
            </NotificationMessage>
          </Notification>
          <Box alignItems='center' mt='20px'>
            <Button isLoading={isPendingWireStatus} width={160} onClick={mutateWireStatus}>
              Retry
            </Button>
          </Box>
        </DetailsWidget>
      )}
      {showLockedPaymentDetailsPreview && <ProxyLockedPaymentDetailsPreview />}
    </>
  );
};
