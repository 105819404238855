import { Box } from '@/components/Box/Box';
import { PIN_CODE_EMPTY_VALUES } from '../../const';
import { Dot } from './styles';
import { isNumber } from '@/helpers/objectHelpers';

type PinCodeDotsType = {
  pinCode: number[];
  error: boolean;
};

export const PinCodeDots = ({ pinCode = [], error }: PinCodeDotsType) => {
  return (
    <Box flexDirection={'row'} columnGap={'20px'}>
      {PIN_CODE_EMPTY_VALUES.map((_, index) => {
        const hasPinValue = isNumber(pinCode[index]);

        return <Dot key={index} hasValue={hasPinValue} hasError={error} />;
      })}
    </Box>
  );
};
