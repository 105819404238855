import { ChangeEvent } from 'react';
import { useField } from 'formik';
import { RadioGroupNew, RadioOptionType } from '../RadioGroupNew/RadioGroupNew';

interface FormikRadioGropNewType {
  label: string;
  name: string;
  options: RadioOptionType[];
}

export const FormikRadioGropNew = ({ label, name, options }: FormikRadioGropNewType) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, _, { setValue }] = useField(name);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return <RadioGroupNew {...field} label={label} value={field.value} onChange={handleChange} options={options} />;
};
