import { ChangeEvent } from 'react';
import {
  SwitchToggleWrapper,
  SwitchToggleInput,
  SwitchToggleFakeInput,
  SwitchToggleInputWrapper,
  SwitchToggleLabel,
  SizeType,
} from './styles';

type SwitchToggleType = {
  name: string;
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  disabled?: boolean;
  size?: SizeType;
};

export const SwitchToggle = ({ name = '', checked, label, disabled, onChange, size = 'medium' }: SwitchToggleType) => {
  return (
    <SwitchToggleWrapper>
      <SwitchToggleLabel>
        {label && <>{label}</>}
        <SwitchToggleInputWrapper size={size}>
          <SwitchToggleInput
            name={name}
            type='checkbox'
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            size={size}
          />
          <SwitchToggleFakeInput disabled={disabled} size={size} />
        </SwitchToggleInputWrapper>
      </SwitchToggleLabel>
    </SwitchToggleWrapper>
  );
};
