import { getFormInitialValues } from '@/helpers/formHelpers/formHelpers';

const DEPOSIT_ACCOUNT_DOMESTIC_PAYMENT_FORM_CONFIG = {
  name: {
    title: 'Deposit Account Name*',
    placeholder: 'Enter Deposit Account Name',
    gridColumn: '1 / 3',
    autoComplete: 'new-field',
  },
  payment_type: {
    title: 'Payment Type',
    type: 'radio_group',
    gridColumn: '1 / 3',
  },
  recipient_name: {
    title: 'Recipient*',
    placeholder: 'Enter Recipient Name',
    gridColumn: '1 / 3',
  },
  recipient_address: {
    title: 'Recipient Address*',
    placeholder: 'Enter Recipient Address',
    gridColumn: '1 / 3',
  },
  account_name: {
    title: 'Account Name',
    placeholder: 'Enter Account Name',
    gridColumn: '1 / 3',
  },
  bank_aba_number: {
    type: 'password_without_visible',
    title: 'Routing / ABA Number*',
    placeholder: 'Enter Number',
    gridColumn: '1 / 2',
    autoComplete: 'new-password',
  },
  confirm_bank_aba_number: {
    type: 'confirm',
    title: 'Confirm Routing / ABA Number*',
    placeholder: 'Enter Number',
    gridColumn: '2 / 3',
  },
  bank_name: {
    title: 'Bank Name',
    placeholder: 'Enter Bank Name',
    gridColumn: '1 / 3',
  },
  bank_account_number: {
    type: 'password_without_visible',
    title: 'Account Number*',
    placeholder: 'Enter Number',
  },
  confirm_bank_account_number: {
    type: 'confirm',
    title: 'Confirm Account Number*',
    placeholder: 'Enter Number',
  },
  bank_address: {
    title: 'Bank Address',
    placeholder: 'Enter Bank Address',
    gridColumn: '1 / 3',
  },
  bank_phone_number: {
    type: 'phone_input',
    title: 'Bank Phone Number',
    placeholder: 'Enter Bank Phone Number With Country Code',
    gridColumn: '1 / 3',
  },
};

const DEPOSIT_ACCOUNT_DOMESTIC_PAYMENT_INTERMEDIARY_BANK_FORM_CONFIG = {
  intermediary_bank_aba_number: {
    type: 'password_without_visible',
    title: 'Routing / ABA Number*',
    placeholder: 'Enter Number',
    gridColumn: '1 / 2',
  },
  confirm_intermediary_bank_aba_number: {
    type: 'confirm',
    title: 'Confirm Routing / ABA Number*',
    placeholder: 'Enter Number',
    gridColumn: '2 / 3',
  },
  intermediary_bank_name: {
    title: 'Bank Name',
    placeholder: 'Enter Bank Name',
    gridColumn: '1 / 3',
  },
  intermediary_bank_account_number: {
    type: 'password_without_visible',
    title: 'Account Number*',
    placeholder: 'Enter Number',
    gridColumn: '1 / 2',
  },
  confirm_intermediary_bank_account_number: {
    type: 'confirm',
    title: 'Confirm Account Number*',
    placeholder: 'Enter Number',
    gridColumn: '2 / 3',
  },
  intermediary_bank_address: {
    title: 'Bank Address',
    placeholder: 'Enter Bank Address',
    gridColumn: '1 / 3',
  },
  intermediary_bank_phone_number: {
    type: 'phone_input',
    title: 'Bank Phone Number',
    placeholder: 'Enter Bank Phone Number With Country Code',
    gridColumn: '1 / 3',
  },
};

const initialDomesticPaymentFormConfig = {
  ...DEPOSIT_ACCOUNT_DOMESTIC_PAYMENT_FORM_CONFIG,
};
const initialDomesticPaymentFormWithIntermediaryBankFormConfig = {
  ...DEPOSIT_ACCOUNT_DOMESTIC_PAYMENT_FORM_CONFIG,
  ...DEPOSIT_ACCOUNT_DOMESTIC_PAYMENT_INTERMEDIARY_BANK_FORM_CONFIG,
};
const intermediaryBankInitialValues = getFormInitialValues(
  DEPOSIT_ACCOUNT_DOMESTIC_PAYMENT_INTERMEDIARY_BANK_FORM_CONFIG
);

export const CREATE_DEPOSIT_ACCOUNT_DOMESTIC_FORM_CONFIG = {
  DEPOSIT_ACCOUNT_DOMESTIC_PAYMENT_FORM_CONFIG,
  DEPOSIT_ACCOUNT_DOMESTIC_PAYMENT_INTERMEDIARY_BANK_FORM_CONFIG,
  initialDomesticPaymentFormConfig,
  initialDomesticPaymentFormWithIntermediaryBankFormConfig,
  intermediaryBankInitialValues,
};
