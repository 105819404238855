import { COLORS } from '@/styles/colors';
import { THEME } from '@/styles/theme';
import styled from 'styled-components';

export const IconDetailsWrapper = styled.div`
  cursor: pointer;
`;

export const ModalCreateTransactionWrapper = styled.div`
  display: flex;
  z-index: ${THEME.modal.zIndex};
  position: fixed;
  background: rgba(145, 170, 192, 0.5);
  backdrop-filter: blur(3px);
  margin: 0;
  padding: 0;
  border: 0;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const Modal = styled.div`
  padding: 42px;
  width: 30.83vw;
  background: #ffffff;
  box-shadow: 0 2px 20px rgba(63, 77, 90, 0.25);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ButtonBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 20px;
  padding-top: 65px;
`;

export const FormSubmitLoaderWrapper = styled.div<{
  width?: number;
  height?: number;
  background?: string;
}>`
  border-radius: 10px;
  background: ${({ background }) => (background ? background : `${COLORS.grey[300]}`)};
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  height: ${({ height }) => (height ? `${height}px` : '50px')};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DatePickerWrapper = styled.div`
  width: 50%;
`;
