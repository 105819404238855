import { useQuery } from '@tanstack/react-query';
import { Widget } from '@/components/Widget/Widget';
import { AssignedWireType } from '@/types/wireTypes';
import { WidgetExecutorWrapper } from '@/bundle/_OrgAdmin/pages/OutboundWire/_Details/styles';
import { DetailsBlockGenerator } from '@/components/DetailsBlockGenerator/DetailsBlockGenerator';
import { UserInvitedNotification } from '@/bundle/shared/Notifications/UserInvitedNotification';
import { getExecutorDetailsConfig } from '@/bundle/shared/helpers/configHelpers';
import { RecipientChainProxyDetails } from '@/bundle/shared/components/RecipientChainProxyDetails';
import { DocumentVerificationPassedDetails } from '@/bundle/shared/components/DocumentVerificationPassedDetails/DocumentVerificationPassedDetails';
import { getDocumentVerifiedRecipientFullName } from '@/bundle/_Recipient/_Details/ui/RecipientDocumentVerification/helpers';
import { WIRE_STATUS_DB } from '@/const/wire';
import {
  isExecutorInvitedWireStatus,
  isRecipientInvitedWireStatus,
  isRecipientProxy,
  isRecipientProxyInvitedWireStatus,
} from '@/helpers/wireHelpers';
import { getRecipientTrustStampStatusByOrgAdmin } from '../../api';

type OutboundWireUserDetailsType = {
  wire: AssignedWireType;
  isShowExecutorForm: boolean;
  isShowEditExecutorForm: boolean;
  isShowEditRecipientForm: boolean;
  onEditExecutorForm: () => void;
  onEditRecipientForm: () => void;
};

export const OutboundWireUserDetails = ({
  wire,
  isShowExecutorForm,
  isShowEditExecutorForm,
  isShowEditRecipientForm,
  onEditExecutorForm,
  onEditRecipientForm,
}: OutboundWireUserDetailsType) => {
  const outboundWireStatus = wire?.status;
  const isProxy = isRecipientProxy(wire);

  const hasAssignedRecipient = isProxy ? wire?.assigned_proxy_user : wire?.assigned_recipient;
  const isRecipientProxyInvited = isRecipientProxyInvitedWireStatus(outboundWireStatus);
  const isRecipientInvited = isRecipientInvitedWireStatus(outboundWireStatus);

  const shouldShowDocumentVerificationPassedDetails = !!(
    wire?.is_pd_provider_kyc_required &&
    wire?.view_payment_details_event_register &&
    wire?.status !== WIRE_STATUS_DB.RECIPIENT_ASSIGNED &&
    wire?.status !== WIRE_STATUS_DB.RECIPIENT_PROXY_ASSIGNED
  );

  const { data: trustStampStatusData } = useQuery({
    queryKey: ['load_trust_stamp_status_by_org_admin', wire?.id],
    queryFn: () => getRecipientTrustStampStatusByOrgAdmin(wire?.id),
    enabled: shouldShowDocumentVerificationPassedDetails,
  });
  const documentVerifiedRecipientFullName = getDocumentVerifiedRecipientFullName(trustStampStatusData?.body);

  const executorDetailsConfig = getExecutorDetailsConfig(wire);
  const hasExecutor = wire?.assigned_executor && !isShowExecutorForm && !isShowEditExecutorForm;
  const isExecutorInvited = isExecutorInvitedWireStatus(outboundWireStatus);

  return (
    <>
      {hasAssignedRecipient && !isShowEditRecipientForm && (
        <RecipientChainProxyDetails
          wire={wire}
          isRecipientEdit={isProxy ? isRecipientProxyInvited : isRecipientInvited}
          onEditRecipient={onEditRecipientForm}
        />
      )}

      {shouldShowDocumentVerificationPassedDetails && (
        <DocumentVerificationPassedDetails fullName={documentVerifiedRecipientFullName} />
      )}

      {hasExecutor && (
        <WidgetExecutorWrapper>
          <Widget title='Executor' hasButton={isExecutorInvited} setIsOpen={onEditExecutorForm}>
            {isExecutorInvited && <UserInvitedNotification />}
            <DetailsBlockGenerator configuration={executorDetailsConfig} />
          </Widget>
        </WidgetExecutorWrapper>
      )}
    </>
  );
};
