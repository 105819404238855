import DatePicker from 'react-datepicker';
import { useField } from 'formik';
import { DatePickerHeader } from '@/components/form/FormikDatePicker/ui/DatePickerHeader';
import { DatePickerWrapperStyles, Wrapper } from '@/components/form/FormikDatePicker/styles';
import { DatePickerInput } from '@/components/form/FormikDatePicker/ui/DatePickerInput';
import { MouseEvent, useRef, useState } from 'react';
import { useOutsideClick } from '@/hooks/useOutsideClick';
import { POPPER_MODIFIERS } from './const';
import { InputVariantType } from '../fields/FormikInput/FormikInput';
import 'react-datepicker/dist/react-datepicker.css';
import { ResponseErrorType } from '@/types/sharedTypes';
import { getFieldError } from '@/helpers/formHelpers/formHelpers';

interface DataPickerType {
  name: string;
  label?: string;
  placeholder?: string;
  variant?: InputVariantType;
  isClearable?: boolean;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  startDate?: Date;
  endDate?: Date;
  minDate?: Date;
  apiError?: ResponseErrorType;
  hasErrorComponent?: boolean;
}

export const FormikDatePicker = ({
  name,
  label,
  placeholder,
  variant,
  isClearable,
  selectsStart,
  selectsEnd,
  startDate,
  endDate,
  minDate,
  apiError,
  hasErrorComponent = false,
}: DataPickerType) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, { setValue }] = useField(name);
  const ref = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { error, apiError: apiErrorMessage } = getFieldError(name, meta, apiError);

  const onOpen = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsOpen(!isOpen);
  };

  const onClose = () => setIsOpen(false);

  const onClear = () => setValue('');

  const onSelect = (value: Date) => {
    setValue(value);
    setIsOpen(false);
  };

  useOutsideClick(wrapperRef, onClose);

  return (
    <Wrapper ref={wrapperRef}>
      <DatePickerWrapperStyles />
      <DatePicker
        ref={wrapperRef}
        open={isOpen}
        calendarClassName='wv-datepicker'
        wrapperClassName='wv-datepicker'
        placeholderText={placeholder}
        selected={field?.value}
        dateFormat={'MM/dd/yyyy'}
        showMonthDropdown
        showYearDropdown
        onSelect={onSelect}
        onCloseOnSelect={() => setIsOpen(false)}
        useWeekdaysShort
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        popperModifiers={POPPER_MODIFIERS}
        shouldCloseOnSelect
        showPopperArrow={false}
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        customInput={
          <DatePickerInput
            ref={ref}
            label={label}
            value={field.value}
            isClearable={isClearable}
            onOpen={onOpen}
            onClear={onClear}
            variant={variant}
            apiError={apiErrorMessage}
            error={error}
            hasErrorComponent={hasErrorComponent}
          />
        }
        renderCustomHeader={({ date, changeYear, changeMonth, decreaseMonth, increaseMonth }) => (
          <DatePickerHeader
            onYearSelect={changeYear}
            onMonthSelect={changeMonth}
            onDecreaseMonth={decreaseMonth}
            onIncreaseMonth={increaseMonth}
            date={date}
          />
        )}
      />
    </Wrapper>
  );
};
