import { COLORS } from '@/styles/colors';
import styled from 'styled-components';

export const WireEditInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const WireEditInfoItem = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

export const WireEditInfoLabel = styled.div`
  font-family: 'Poppins-Regular', sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #9197a5;
`;

export const WireEditInfoValue = styled(WireEditInfoLabel)`
  font-family: 'Poppins-Medium', sans-serif;
  color: ${COLORS.black};
`;
