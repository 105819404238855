import { FormConfigType } from '@/helpers/formHelpers/formHelpers';
import { REQUIRED_TEXT_FIELD_VALIDATOR, TEXT_FIELD_VALIDATOR } from '@/helpers/formHelpers/validators';

// TODO: maybe reuse same form in ProfilePage config
export const PERSONAL_DETAILS_FORM_CONFIG: FormConfigType = [
  {
    name: 'first_name',
    label: 'First Name*',
    placeholder: 'Enter First Name',
    validator: REQUIRED_TEXT_FIELD_VALIDATOR,
  },
  {
    name: 'middle_name',
    label: 'Middle Name',
    placeholder: 'Enter Middle Name',
    validator: TEXT_FIELD_VALIDATOR,
  },
  {
    name: 'last_name',
    label: 'Last Name*',
    placeholder: 'Enter Last Name',
    validator: REQUIRED_TEXT_FIELD_VALIDATOR,
  },
];
