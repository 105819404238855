import { envConfig } from '@/config/envConfig';
import { localStorageService } from '@/helpers/storageHelpers';
import { forwardRef, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

export type GoogleReCaptchaType = {
  resetCaptcha: () => void;
  executeCaptcha: () => Promise<string>;
};

const DISABLED_GOOGLE_CAPTCHA_KEY = 'DISABLED_GOOGLE_CAPTCHA';

/*
  === Mocked captcha that devs can use in DEV/QA environments ===
  Note: Add <DISABLED_GOOGLE_CAPTCHA> key with <true> value in local storage
  ===============================================================
  ALWAYS test with real captcha if you make changes in this file!
*/
const MockGoogleCaptcha = forwardRef((_, ref) => {
  useImperativeHandle(
    ref,
    () => {
      return {
        resetCaptcha() {
          return null;
        },
        async executeCaptcha() {
          return Promise.resolve(envConfig.RECAPCHA_SITE_KEY);
        },
      };
    },
    []
  );

  return null;
});

const GoogleCaptcha = forwardRef((_, ref) => {
  const recaptchaRef = useRef<ReCAPTCHA>();

  useImperativeHandle(
    ref,
    () => {
      return {
        resetCaptcha() {
          try {
            recaptchaRef.current?.reset();
          } catch (error) {
            console.error('google recaptcha failed with ', error?.message);
          }
        },
        async executeCaptcha() {
          return recaptchaRef.current?.executeAsync();
        },
      };
    },
    []
  );

  return <ReCAPTCHA ref={recaptchaRef} sitekey={envConfig.RECAPCHA_SITE_KEY} size='invisible' />;
});

export const GoogleReCaptcha = forwardRef((_, ref) => {
  const [selected, setSelected] = useState(false);
  const [mockedCaptcha, setMockedCaptcha] = useState(false);

  useLayoutEffect(() => {
    const isDisabledCaptcha = localStorageService.get(DISABLED_GOOGLE_CAPTCHA_KEY);

    if (isDisabledCaptcha && (envConfig.ENV_NAME === 'development' || envConfig.ENV_NAME === 'qa')) {
      setMockedCaptcha(true);
    }

    setSelected(true);
  }, []);

  if (!selected) return;

  return mockedCaptcha ? <MockGoogleCaptcha ref={ref} /> : <GoogleCaptcha ref={ref} />;
});
