import styled from 'styled-components';
import { flex, flexCenterColumn } from '@/styles/layout';
import { COLORS } from '@/styles/colors';
import { media } from '@/styles/responsive';

export const AuthBody = styled.div`
  ${flexCenterColumn};
  flex: 1;
  background-color: ${COLORS.grey[300]};

  ${media.mobile`
    background-color: ${COLORS.white};
  `}
`;

export const AuthHeader = styled.div`
  background: ${COLORS.black};
  padding: 20px 30px;
  box-shadow: 0 2px 10px rgba(19, 62, 112, 0.2);

  ${media.mobile`
    padding: 20px 16px;
  `}
`;

export const AuthPage = styled.div`
  ${flex({ flexDirection: 'column' })};
  height: 100%;
`;

export const LinkWrapper = styled.div`
  width: max-content;
`;
