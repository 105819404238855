import { matchPath, useLocation } from 'react-router-dom';
import { useSidebar } from '../../hooks/useSidebar';
import {
  MenuBody,
  MenuList,
  MenuSection,
  MenuSectionIcon,
  MenuSectionLink,
  MenuSectionTitle,
  MenuWrapper,
} from './styles';

type SidebarMenuType = {
  onNavigate: () => void;
};

export const isActiveMenu = (pathname: string, paths: string[]) => {
  return paths.some((path) => matchPath(path, pathname));
};

export const SidebarMenu = ({ onNavigate }: SidebarMenuType) => {
  const sidebar = useSidebar();
  const { pathname } = useLocation();

  return (
    <MenuWrapper>
      <MenuBody>
        <MenuList>
          {sidebar.menu.map((section) => {
            const { title, url, icon, paths } = section;
            const isActive = isActiveMenu(pathname, paths);
            const Icon = icon;

            return (
              <MenuSection key={title} isActive={isActive}>
                <MenuSectionLink to={url} onClick={onNavigate}>
                  <MenuSectionIcon>
                    <Icon />
                  </MenuSectionIcon>
                  <MenuSectionTitle>{title}</MenuSectionTitle>
                </MenuSectionLink>
              </MenuSection>
            );
          })}
        </MenuList>
      </MenuBody>
    </MenuWrapper>
  );
};
