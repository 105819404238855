import { PaymentDetailsGeneralType } from '@/types/paymentDetailsTypes';
import { DetailsDividerRow } from '@/components/DetailsWidget/DetailsDividerRow/DetailsDividerRow';
import { DetailsRow } from '@/components/DetailsWidget/DetailsRow/DetailsRow';
import { isDomesticPaymentType } from '@/helpers/paymentDetailsHelpers';
import { IntermediaryBankDetailsWrapper } from './styles';
import { phoneFormatter } from '@/helpers/formatHelpers';

type IntermediaryBankDetailsType = {
  paymentDetails: PaymentDetailsGeneralType;
};

export const IntermediaryBankDetails = ({ paymentDetails }: IntermediaryBankDetailsType) => {
  const {
    blockchain_payment_details_data: blockchainPaymentDetails,
    internal_payment_details_data: internalPaymentDetails,
    is_blockchain_payment_details_data_from_bc: isDataFromBlockchain,
  } = paymentDetails;

  const isDomesticPayment = isDomesticPaymentType(internalPaymentDetails?.payment_type);
  const bankCodeLabel = isDomesticPayment ? 'Routing / ABA Number' : 'Swift Code';
  const bankCodeValue =
    (isDomesticPayment
      ? blockchainPaymentDetails?.intermediary_bank_aba_number
      : blockchainPaymentDetails?.intermediary_bank_swift_code) || '-';

  const formattedPhoneNumber = phoneFormatter(internalPaymentDetails?.intermediary_bank_phone_number);

  return (
    <IntermediaryBankDetailsWrapper>
      <DetailsDividerRow />
      <DetailsRow name='Intermediary Bank' isHeader></DetailsRow>
      <DetailsRow name={bankCodeLabel} hasHighlight={isDataFromBlockchain}>
        {bankCodeValue}
      </DetailsRow>
      <DetailsRow name='Bank Name'>{internalPaymentDetails?.intermediary_bank_name || '-'}</DetailsRow>
      <DetailsRow name='Account Number' hasHighlight={isDataFromBlockchain}>
        {blockchainPaymentDetails?.intermediary_bank_account_number || '-'}
      </DetailsRow>
      <DetailsRow name='Bank Address'>{internalPaymentDetails?.intermediary_bank_address || '-'}</DetailsRow>
      <DetailsRow name='Bank Phone Number'>{formattedPhoneNumber}</DetailsRow>
    </IntermediaryBankDetailsWrapper>
  );
};
