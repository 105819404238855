import { ReactComponent as LogoSmallIcon } from '../images/LogoSmall.svg';
import { Box } from '@/components/Box/Box';
import { OpcoUserStatus } from '../../ui/OpcoUserStatus/OpcoUserStatus';
import { Button } from '@/components/Button/Button';
import { ConfirmModal } from '@/components/ConfirmModal/ConfirmModal';
import { useState } from 'react';
import { isBlockedOpcoUserStatus } from '../../helpers/opcoUserHelpers';
import { phoneFormatter } from '@/helpers/formatHelpers';
import { OpcoUserType } from '@/bundle/_Opco/types/types';
import { useMutation } from '@tanstack/react-query';
import { blockOpcoUser, resetOpcoUser } from '../api';
import { showSnackbar } from '@/components/Snackbar/Snackbar';
import { PIN_TOKEN_EVENT_SCOPE } from '@/const/shared';
import { useUser } from '@/context/userContext';
import { ResetTimeInfo } from './ResetTimeInfo/ResetTimeInfo';
import {
  UserDetailsActionRow,
  UserDetailsCard,
  UserDetailsRow,
  UserDetailsRowTitle,
  UserDetailsRowValue,
  UserDetailsWrapper,
} from '@/bundle/_Opco/shared/styles';
import { UserRole } from '@/bundle/_Opco/shared/UserRole/UserRole';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { UserDetailsHeader } from '@/bundle/shared/components/UserDetails/UserDetailsHeader/UserDetailsHeader';
import { CheckPinCodeModal } from '@/bundle/shared/components/CheckPinCodeModal/CheckPinCodeModal';

export type OpcoUserAccountDetailsType = {
  userDetails: OpcoUserType;
  onSuccess: () => void;
};

export const OpcoUserAccountDetails = ({ userDetails, onSuccess }: OpcoUserAccountDetailsType) => {
  const { user: currentUser } = useUser();
  const { user, role } = userDetails;
  const { email, mobile_phone, status } = user;

  const [actionEventScope, setActionEventScope] = useState(null);
  const [isOpenBlockModal, setIsOpenBlockModal] = useState(false);
  const [isOpenResetModal, setIsOpenResetModal] = useState(false);
  const [isPinCodeOpen, setIsPinCodeOpen] = useState(false);

  const isOwnAccount = currentUser?.id === user?.id;
  const lastResetDetails = user?.last_reset_details;

  const { mutate: blockOpcoUserMutate, isPending: isPendingBlockOpcoUser } = useMutation({
    mutationKey: ['block_opco_user'],
    mutationFn: (pinToken: string) => {
      return blockOpcoUser(user?.id, pinToken);
    },
    onSuccess(blockOpcoUserResponse) {
      if (blockOpcoUserResponse?.error) {
        const error = getResponseError(blockOpcoUserResponse?.error);

        showSnackbar(error, { variant: 'error' });

        return;
      }

      showSnackbar('User account has been blocked.');
      onSuccess();
    },
    onSettled() {
      setIsOpenBlockModal(false);
    },
  });

  const { mutate: resetOpcouserMutate, isPending: isPendingResetOpcoUser } = useMutation({
    mutationKey: ['reset_opco_user'],
    mutationFn: (pinToken: string) => {
      return resetOpcoUser(user?.id, pinToken);
    },
    onSuccess(resetOpcoUserResponse) {
      if (resetOpcoUserResponse?.error) {
        const error = getResponseError(resetOpcoUserResponse?.error);

        showSnackbar(error, { variant: 'error' });

        return;
      }

      showSnackbar('User account has been reset.');
      onSuccess();
    },
    onSettled() {
      setIsOpenBlockModal(false);
    },
  });

  const closePinCode = () => {
    setIsPinCodeOpen(false);
    setActionEventScope(null);
  };

  const checkPinCodeSuccess = (pinToken: string) => {
    if (!actionEventScope) return;

    if (actionEventScope === PIN_TOKEN_EVENT_SCOPE.BLOCK_OPCO_USER) {
      blockOpcoUserMutate(pinToken);
      closePinCode();

      return;
    }

    if (actionEventScope === PIN_TOKEN_EVENT_SCOPE.RESET_OPCO_USER) {
      resetOpcouserMutate(pinToken);
      closePinCode();

      return;
    }
  };

  const openBlockOpcoUserPinCode = () => {
    setIsPinCodeOpen(true);
    setIsOpenBlockModal(false);
    setActionEventScope(PIN_TOKEN_EVENT_SCOPE.BLOCK_OPCO_USER);
  };

  const openResetOpcoUserPinCode = () => {
    setIsPinCodeOpen(true);
    setIsOpenResetModal(false);
    setActionEventScope(PIN_TOKEN_EVENT_SCOPE.RESET_OPCO_USER);
  };

  const isBlockedUserStatus = isBlockedOpcoUserStatus(status);
  const formattedMobilePhone = phoneFormatter(mobile_phone);

  return (
    <UserDetailsWrapper>
      <UserDetailsHeader
        title='WireVault Account Information'
        icon={<LogoSmallIcon />}
        isOwnAccount={isOwnAccount}
        subTitle='You must enter your PIN code to edit these fields.'
      />
      <UserDetailsCard>
        <UserDetailsRow>
          <UserDetailsRowTitle>Email Address</UserDetailsRowTitle>
          <UserDetailsRowValue>{email}</UserDetailsRowValue>
        </UserDetailsRow>
        <UserDetailsRow>
          <UserDetailsRowTitle>Mobile Phone Number</UserDetailsRowTitle>
          <UserDetailsRowValue>{formattedMobilePhone}</UserDetailsRowValue>
        </UserDetailsRow>
        <UserDetailsRow>
          <UserDetailsRowTitle>Role</UserDetailsRowTitle>
          <UserRole role={role} />
        </UserDetailsRow>
        <UserDetailsActionRow>
          <Box>
            <UserDetailsRowTitle>Status</UserDetailsRowTitle>
            <OpcoUserStatus status={status} />
          </Box>
          {!isOwnAccount && (
            <Box display='flex' columnGap='12px' alignItems='center'>
              {!isBlockedUserStatus && (
                <Button width={71} size='medium' color='secondary' onClick={() => setIsOpenBlockModal(true)}>
                  Block
                </Button>
              )}
              <Button width={71} size='medium' color='secondary' onClick={() => setIsOpenResetModal(true)}>
                Reset
              </Button>
            </Box>
          )}
        </UserDetailsActionRow>
        {!!lastResetDetails && <ResetTimeInfo info={lastResetDetails} />}
      </UserDetailsCard>

      <ConfirmModal
        isOpen={isOpenBlockModal}
        header='Block User'
        body='This User will no longer be able to access WireVault. You can choose to reset their account to reenable their access later.'
        onClose={() => setIsOpenBlockModal(false)}
        onConfirm={openBlockOpcoUserPinCode}
        isLoading={isPendingBlockOpcoUser}
      />
      <ConfirmModal
        isOpen={isOpenResetModal}
        header='Reset Account'
        body={
          <>
            The User will need to complete the onboarding procedure once again.
            <br />
            An account can be reset once per hour.
          </>
        }
        onClose={() => setIsOpenResetModal(false)}
        onConfirm={openResetOpcoUserPinCode}
        isLoading={isPendingResetOpcoUser}
      />
      {isPinCodeOpen && (
        <CheckPinCodeModal
          isOpen={isPinCodeOpen}
          eventScope={actionEventScope}
          onSuccess={checkPinCodeSuccess}
          onClose={() => setIsPinCodeOpen(false)}
        />
      )}
    </UserDetailsWrapper>
  );
};
