import styled from 'styled-components';
import { bodySmall, poppinsMediumFont, poppinsRegularFont, subTitle } from '@/styles/typography';
import { COLORS } from '@/styles/colors';
import { flex } from '@/styles/layout';

export const FormTitle = styled.div`
  width: 100%;
  margin-bottom: 14px;
  ${poppinsMediumFont};
  ${subTitle};
  color: ${COLORS.black};
`;

export const FormMessage = styled.div`
  width: 100%;
  color: ${COLORS.grey[600]};
  ${bodySmall};
  ${poppinsRegularFont};
  margin-bottom: 28px;
`;

export const FormButtonBlock = styled.div`
  ${flex({ justifyContent: 'end' })}
  column-gap: 12px;
  margin-top: 12px;
`;

export const FormButtonBlockCentered = styled(FormButtonBlock)`
  ${flex({ justifyContent: 'space-between' })};
  margin-top: 12px;
  column-gap: 20px;
`;
