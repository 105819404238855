import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { COLORS } from '../../../../styles/colors';
import { bodySmall } from '../../../../styles/typography';

export const ErrorHintWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 16px;
  border-radius: 10px;
  background-color: ${COLORS.red[100]};
  ${bodySmall};
  font-weight: 600;
`;

export const ErrorHintText = styled.div`
  margin-left: 12px;
`;
export const ContactUsLink = styled(Link)`
  color: ${COLORS.blue[500]};
`;
