import { useField } from 'formik';
import {
  FormikInputWrapper,
  IconWrapper,
  LabelWrapper,
  CustomInputWrapper,
  Label,
  Input,
  FormikInputFooter,
  InputSmall,
  ConfirmationTicks,
  LabelSlotWrapper,
} from './styles';
import { InputError } from '../InputError/InputError';
import { ReactComponent as Warning } from '../../../../images/Warning.svg';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { getFieldError } from '@/helpers/formHelpers/formHelpers';
import { ReactNode } from 'react';

export type InputVariantType = 'default' | 'small';

export const INPUT_MAP = {
  default: Input,
  small: InputSmall,
};

type FormikInputType = {
  id?: string;
  label?: string;
  name?: string;
  type?: string;
  errorsApi?: any;
  disabled?: boolean;
  width?: number;
  placeholder?: string;
  maxLength?: number;
  minWidth?: number;
  iconRight?: any;
  isCopyPasteDisabled?: boolean;
  onRightIconClick?: any;
  toUpperCase?: boolean;
  variant?: InputVariantType;
  onBlur?: () => void;
  hasErrorComponent?: boolean;
  autoComplete?: string;
  tooltipMessage?: string;
  isConfirmTicks?: boolean;
  hasClear?: boolean;
  autoFocus?: boolean;
  labelSlot?: ReactNode;
};

export const FormikInput = ({
  id,
  label,
  type = 'text',
  disabled,
  width,
  placeholder,
  maxLength,
  minWidth,
  name,
  errorsApi,
  iconRight,
  isCopyPasteDisabled,
  onRightIconClick,
  toUpperCase,
  variant = 'default',
  onBlur,
  hasErrorComponent = true,
  autoComplete = 'on',
  tooltipMessage,
  isConfirmTicks,
  autoFocus,
  labelSlot,
}: FormikInputType) => {
  const [field, meta, { setValue }] = useField(name);

  const { apiError, error, hasAnyError } = getFieldError(name, meta, errorsApi);
  const hasConfirmTicks = isConfirmTicks && field.value?.trim() && !meta.error;

  const onChangeHandler = (event: any) => {
    const value = toUpperCase ? event.target.value.toUpperCase() : event.target.value;

    setValue(value);
  };

  const onBlurHandler = (event: any) => {
    if (onBlur) {
      onBlur();
    }

    field.onBlur(event);
  };

  const onCopyPasteHandler = (event: any) => {
    if (isCopyPasteDisabled) {
      event.preventDefault();

      return false;
    }
  };

  const InputComponent = INPUT_MAP[variant];

  return (
    <FormikInputWrapper width={width} minWidth={minWidth}>
      <LabelWrapper>
        <Label>{label}</Label>
        {labelSlot && <LabelSlotWrapper>{labelSlot}</LabelSlotWrapper>}
        {!!tooltipMessage && (
          <>
            <Warning data-tooltip-id='input-tooltip' />
            <Tooltip id='input-tooltip'>{tooltipMessage}</Tooltip>
          </>
        )}
      </LabelWrapper>
      <CustomInputWrapper>
        <InputComponent
          {...field}
          id={id}
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
          type={type}
          disabled={disabled}
          placeholder={placeholder}
          maxLength={maxLength}
          hasError={hasAnyError}
          hasConfirmTicks={hasConfirmTicks}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          onPaste={onCopyPasteHandler}
          onCopy={onCopyPasteHandler}
        />
        {!!iconRight && <IconWrapper onClick={() => onRightIconClick?.()}>{iconRight}</IconWrapper>}
        {hasConfirmTicks && <ConfirmationTicks />}
      </CustomInputWrapper>
      <FormikInputFooter>{hasErrorComponent && <InputError error={error} apiError={apiError} />}</FormikInputFooter>
    </FormikInputWrapper>
  );
};
