import { COLORS } from '@/styles/colors';
import { resetButton } from '@/styles/common';
import { flex } from '@/styles/layout';
import { body, poppinsMediumFont } from '@/styles/typography';
import styled from 'styled-components';

export const CompleteRegistrationTitle = styled.div`
  width: 100%;
  text-align: start;
  font-family: 'Poppins-Medium', sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${COLORS.black};
  margin-bottom: 12px;
`;

export const CompleteRegistrationMessage = styled.div`
  color: ${COLORS.black};
  margin-bottom: 28px;
  font-family: 'Poppins-Regular', sans-serif;
  font-size: 16px;
  line-height: 24px;
`;

export const CompleteRegistrationButtonBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const CheckboxRowsWrapper = styled.div`
  margin-bottom: 28px;
`;

export const CheckboxRow = styled.label`
  ${flex({ justifyContent: 'space-between' })};
  width: 100%;
  margin-bottom: 13px;
  cursor: pointer;
`;

export const CheckboxWrapper = styled.div`
  margin-right: 18px;
  margin-top: 6px;
`;

export const CheckboxLabel = styled.div`
  width: 100%;
  font-family: 'Poppins-Regular', sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin-top: 2px;
`;

export const CheckboxLabelPhoneCode = styled.span`
  font-weight: 600;
`;

export const TermsLink = styled.a`
  color: ${COLORS.blue[500]};
  font-size: 16px;
  font-family: 'Poppins-Regular', sans-serif;
  text-decoration: underline;
  cursor: pointer;
`;

export const CancelButton = styled.button`
  ${resetButton};
  ${poppinsMediumFont};
  ${body};
  color: ${COLORS.red[500]};
  cursor: pointer;
`;
