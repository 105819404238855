import { PersonalDetailsType } from '@/bundle/shared/components/UserDetails/UserPersonalInfo';
import { useFormik } from 'formik';
import {
  FormValues,
  getChangedFormValues,
  getMergedValues,
  getValidationSchema,
} from '@/helpers/formHelpers/formHelpers';
import { EDIT_PERSONAL_DETAILS_FORM_CONFIG } from '@/bundle/pages/ProfilePage/ui/const';
import { FormGeneratorNew } from '@/components/form/FormGeneratorNew/FormGeneratorNew';
import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import { useMutation } from '@tanstack/react-query';
import { updateSelfUserDetails } from '@/bundle/pages/ProfilePage/api';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';

interface EditOpcoPersonalDetailsFormType {
  personalDetails: PersonalDetailsType;
  onClose: () => void;
  onSuccess: () => void;
}

export const EditOpcoPersonalDetailsForm = ({
  personalDetails,
  onClose,
  onSuccess,
}: EditOpcoPersonalDetailsFormType) => {
  const { mutate, isPending } = useMutation({
    mutationKey: ['edit_opco_personal_details'],
    mutationFn: (values: FormValues) => {
      return updateSelfUserDetails(values);
    },
    onSuccess: (updateOpcoSelfDetails) => {
      if (updateOpcoSelfDetails?.error) {
        return;
      }

      amplitudeService.logEvent(AMPLITUDE_EVENTS.ProfileEditPersonalInfoSuccess);

      onSuccess();
      onClose();
    },
  });

  const formik = useFormik({
    initialValues: getMergedValues(EDIT_PERSONAL_DETAILS_FORM_CONFIG, personalDetails),
    validationSchema: getValidationSchema(EDIT_PERSONAL_DETAILS_FORM_CONFIG),
    onSubmit: (values) => {
      const payload = getChangedFormValues<FormValues>(values, personalDetails as FormValues);

      if (!payload) {
        onClose();

        return;
      }

      mutate(payload);
    },
  });

  return (
    <Box flexDirection='column' rowGap='14px'>
      <FormikForm value={formik}>
        <FormGeneratorNew config={EDIT_PERSONAL_DETAILS_FORM_CONFIG} />
        <Box display='flex' columnGap='20px' mt='14px'>
          <Button color='secondary' onClick={onClose}>
            Cancel
          </Button>
          <Button type='submit' isLoading={isPending}>
            Save
          </Button>
        </Box>
      </FormikForm>
    </Box>
  );
};
