import { OpcoUserStatus } from '../ui/OpcoUserStatus/OpcoUserStatus';
import { dateTimeUTCFormatter } from '@/helpers/formatHelpers';
import { FULL_DATE_TIME_FORMAT } from '@/helpers/dateHelpers/const';
import { UserRole } from '@/bundle/_Opco/shared/UserRole/UserRole';

export const OPCO_USERS_TABLE_CONFIG = [
  {
    key: 1,
    name: 'user__email,-user__date_created',
    header: 'EMAIL ADDRESS',
    width: '30%',
    hasSort: true,
    hasTitle: true,
    getValue({ user }) {
      return user?.email;
    },
  },
  {
    key: 2,
    name: 'user__first_name,-user__date_created',
    header: 'FIRST NAME',
    width: '14%',
    hasSort: true,
    hasTitle: true,
    getValue({ user }) {
      return user?.first_name || '-';
    },
  },
  {
    key: 3,
    name: 'user__last_name,-user__date_created',
    header: 'LAST NAME',
    width: '14%',
    hasSort: true,
    hasTitle: true,
    getValue({ user }) {
      return user?.last_name || '-';
    },
  },
  {
    key: 4,
    name: 'role',
    header: 'ROLE',
    width: '12%',
    getValue({ role }) {
      return <UserRole role={role} />;
    },
  },
  {
    key: 5,
    name: 'user__status,-user__date_created',
    header: 'STATUS',
    width: '12%',
    hasSort: true,
    getValue({ user }) {
      return <OpcoUserStatus status={user?.status} />;
    },
  },
  {
    key: 6,
    name: 'user__last_login,-user__date_created',
    header: 'LAST LOGIN',
    width: '18%',
    hasSort: true,
    hasTooltip: true,
    hasTitle: true,
    getValue({ user }) {
      return dateTimeUTCFormatter(user?.last_login, FULL_DATE_TIME_FORMAT);
    },
  },
];
