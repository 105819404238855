import { COLORS } from '@/styles/colors';
import { flex } from '@/styles/layout';
import { bodySmall, poppinsMediumFont, poppinsRegularFont, subTitle } from '@/styles/typography';
import styled from 'styled-components';

export const ViewPaymentDetailsContainer = styled.div`
  width: 100%;
  height: max-content;
  max-height: 110px;
  ${flex({ alignItems: 'center', justifyContent: 'space-between' })}
  padding: 28px;
  border: 1px solid ${COLORS.grey[400]};
  border-radius: 20px;
  background-color: ${COLORS.white};
`;

export const ViewPaymentDetailsHeader = styled.div`
  ${poppinsMediumFont};
  ${subTitle};
  color: ${COLORS.grey[900]};
`;

export const ViewPaymentDetailsText = styled.div`
  ${poppinsRegularFont};
  ${bodySmall};
  color: ${COLORS.grey[600]};
`;
