import { DetailsRow } from '@/components/DetailsWidget/DetailsRow/DetailsRow';
import { DetailsWidget } from '@/components/DetailsWidget/DetailsWidget';
import { AssignedWireType, WireUserType } from '@/types/wireTypes';
import { phoneFormatter } from '@/helpers/formatHelpers';

type ExecutorDetailsType = {
  wire: AssignedWireType;
};

export const ExecutorDetails = ({ wire }: ExecutorDetailsType) => {
  const { assigned_executor } = wire;
  const assignedExecutor = assigned_executor?.user || ({} as WireUserType);
  const { first_name, middle_name, last_name, email, mobile_phone } = assignedExecutor;
  const formattedPhoneNumber = phoneFormatter(mobile_phone);

  return (
    <DetailsWidget header='Executor'>
      <DetailsRow name='First Name'>{first_name || '-'}</DetailsRow>
      <DetailsRow name='Middle Name'>{middle_name || '-'}</DetailsRow>
      <DetailsRow name='Last Name'>{last_name || '-'}</DetailsRow>
      <DetailsRow name='Email Address'>{email || '-'}</DetailsRow>
      <DetailsRow name='Mobile Phone Number'>{formattedPhoneNumber}</DetailsRow>
    </DetailsWidget>
  );
};
