import { COLORS } from '@/styles/colors';
import { flex } from '@/styles/layout';
import styled from 'styled-components';

export type SizeType = 'medium' | 'small';

export const SwitchToggleWrapper = styled.div``;

export const SwitchToggleLabel = styled.label`
  ${flex({ justifyContent: 'space-between' })};
  cursor: pointer;
`;

export const SwitchToggleInputWrapper = styled.div<{ size: SizeType }>`
  width: 44px;
  height: 24px;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;

  ${({ size }) =>
    size === 'small' &&
    ` width: 36px;
      height: 20px;
  `};
`;

export const SwitchToggleFakeInput = styled.div<{ disabled?: boolean; size: SizeType }>`
  ${flex({ alignItems: 'center' })}
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${COLORS.grey[400]};
  border-radius: 12px;

  &:before {
    content: '';
    position: absolute;
    height: 20px;
    width: 20px;
    left: 2px;
    background-color: ${COLORS.white};
    transition: 0.4s;
    border-radius: 50%;

    ${({ size }) =>
      size === 'small' &&
      ` width: 16px;
        height: 16px;
  `};
  }

  ${({ disabled }) =>
    disabled &&
    `
    background-color: ${COLORS.grey[300]};
    
    &:before {
      opacity: 0.8;
    }
  `};
`;

export const SwitchToggleInput = styled.input<{ size: SizeType }>`
  opacity: 0;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  cursor: pointer;

  &:checked + ${SwitchToggleFakeInput} {
    background: ${COLORS.green[500]};
    &:before {
      transform: translateX(20px);

      ${({ size }) => size === 'small' && `transform: translateX(16px);`};
    }
  }
`;
