import { DetailsRow } from '@/components/DetailsWidget/DetailsRow/DetailsRow';
import { DetailsWidget } from '@/components/DetailsWidget/DetailsWidget';
import { WireStatusType } from '@/types/wireTypes';
import { UserInvitedNotification } from '@/bundle/shared/Notifications/UserInvitedNotification';
import { WIRE_STATUS_DB } from '@/const/wire';
import { phoneFormatter } from '@/helpers/formatHelpers';
import { AssignedDepositor } from '@/api/v1/depositor/getDepositorWireApi';
import { DetailsEditButton } from '@/bundle/_OrgAdmin/shared/ui/DetailsEditButton/DetailsEditButton';

type DepositorDetailsType = {
  depositor: AssignedDepositor;
  wireStatus: WireStatusType;
  onEdit: () => void;
};

export const DepositorDetails = ({ depositor, wireStatus, onEdit }: DepositorDetailsType) => {
  const { first_name, middle_name, last_name, email, mobile_phone } = depositor;
  const isDepositorInvited = wireStatus === WIRE_STATUS_DB.DEPOSITOR_INVITED;
  const formattedPhoneNumber = phoneFormatter(mobile_phone);

  return (
    <DetailsWidget header='Depositor' action={isDepositorInvited && <DetailsEditButton onEdit={onEdit} />}>
      {isDepositorInvited && <UserInvitedNotification />}
      <DetailsRow name='First Name'>{first_name || '-'}</DetailsRow>
      <DetailsRow name='Middle Name'>{middle_name || '-'}</DetailsRow>
      <DetailsRow name='Last Name'>{last_name || '-'}</DetailsRow>
      <DetailsRow name='Email Address'>{email || '-'}</DetailsRow>
      <DetailsRow name='Mobile Phone Number'>{formattedPhoneNumber}</DetailsRow>
    </DetailsWidget>
  );
};
