import { ButtonBlock, PaymentDetailsFormMessage, PaymentDetailsFormTitle, PaymentDetailsFormWrapper } from './styles';
import { useFormik } from 'formik';
import { FormikForm } from '../../../../../../components/form/FormikForm/FormikForm';
import { useEffect } from 'react';

import {
  DOMESTIC_FORM_CONFIG,
  DOMESTIC_INTERMEDIARY_FORM_CONFIG,
  INTERNATIONAL_FORM_CONFIG,
  INTERNATIONAL_INTERMEDIARY_FORM_CONFIG,
} from '../../const/formConfig';
import { Button } from '../../../../../../components/Button/Button';
import { CreateNewAccountLink } from '../CreateNewAccountLink/CreateNewAccountLink';
import { SelectNew } from '@/components/SelectNew/SelectNew';
import { loadDepositAccountsIds } from '../../api';
import { useQuery } from '@tanstack/react-query';
import { PaymentDetailsGeneralType } from '@/types/paymentDetailsTypes';
import { ResponseErrorType } from '@/types/sharedTypes';
import {
  FormConfigType,
  getInitialValues,
  getMergedValues,
  getValidationSchema,
} from '@/helpers/formHelpers/formHelpers';
import { FormGeneratorNew } from '@/components/form/FormGeneratorNew/FormGeneratorNew';

type OptionsType = {
  id: string;
  name: string;
};

type PaymentDetailsFormType = {
  selectedDepositAccount: PaymentDetailsGeneralType;
  onChange: (item: any) => void;
  isLoading: boolean;
  selectedDepositAccountId: string;
  error: ResponseErrorType;
  isPaymentDetailsUpdating: boolean;
  onCreate: (payload) => void;
};

const preparedDepositAccountsOptions = (depositAccounts: OptionsType[]) => {
  return depositAccounts?.map((item) => {
    return { value: item.id, label: item.name };
  });
};

const formatPayload = (formConfig, values: {}) => {
  return formConfig
    .filter(({ type }) => type !== 'divider')
    .reduce((result, formField) => {
      result[formField.name] = values[formField.name];

      return result;
    }, {});
};

const getCurrentFormConfig = (depositAccount): FormConfigType => {
  if (!depositAccount) {
    return [];
  }

  const blockchainPaymentDetails = depositAccount?.blockchain_payment_details_data;
  const isDomesticDepositAccount = !!blockchainPaymentDetails?.bank_aba_number;
  const isInternationalDepositAccount = !!blockchainPaymentDetails?.bank_swift_code;
  const isDomesticIntermediaryBank = !!blockchainPaymentDetails?.intermediary_bank_aba_number;
  const isInternationalIntermediaryBank = !!blockchainPaymentDetails?.intermediary_bank_swift_code;

  if (isDomesticDepositAccount && !isDomesticIntermediaryBank) return DOMESTIC_FORM_CONFIG;

  if (isInternationalDepositAccount && !isInternationalIntermediaryBank) return INTERNATIONAL_FORM_CONFIG;

  if (isDomesticDepositAccount && isDomesticIntermediaryBank) {
    return [...DOMESTIC_FORM_CONFIG, ...DOMESTIC_INTERMEDIARY_FORM_CONFIG];
  }

  if (isInternationalDepositAccount && isInternationalIntermediaryBank) {
    return [...INTERNATIONAL_FORM_CONFIG, ...INTERNATIONAL_INTERMEDIARY_FORM_CONFIG];
  }

  return [];
};

export const SelectDepositAccountForm = ({
  selectedDepositAccount,
  onChange,
  isLoading,
  selectedDepositAccountId,
  error,
  isPaymentDetailsUpdating,
  onCreate,
}: PaymentDetailsFormType) => {
  const config = getCurrentFormConfig(selectedDepositAccount);
  const initialValues = getInitialValues(config);

  const { data: depositAccountIdsData } = useQuery({
    queryKey: ['load_deposit_accounts_ids'],
    queryFn: () => loadDepositAccountsIds(),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: getValidationSchema(config),
    onSubmit: async (values) => {
      const payload = formatPayload(config, values);

      await onCreate(payload);
    },
  });

  const paymentDetailsInitialValues = {
    ...selectedDepositAccount?.blockchain_payment_details_data,
    ...selectedDepositAccount?.internal_payment_details_data,
  };

  useEffect(() => {
    if (!selectedDepositAccount) return;

    formik.setValues({
      ...formik.values,
      ...getMergedValues(config, paymentDetailsInitialValues),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDepositAccount]);

  const depositAccountsOptions = preparedDepositAccountsOptions(depositAccountIdsData?.body?.results);

  return (
    <PaymentDetailsFormWrapper>
      <PaymentDetailsFormTitle>Wire Info</PaymentDetailsFormTitle>
      <PaymentDetailsFormMessage>Please select and confirm wire info for the wire.</PaymentDetailsFormMessage>
      <SelectNew
        label='Deposit Account*'
        placeholder='Select Deposit Account'
        options={depositAccountsOptions}
        onChange={onChange}
        postfixLabel={<CreateNewAccountLink />}
        isLoading={isLoading}
        disable={isLoading}
        emptyOptionPlaceholder='Please create a deposit account.'
      />
      <FormikForm value={formik}>
        {selectedDepositAccountId && (
          <div>
            <FormGeneratorNew config={config} apiError={error} />
            <ButtonBlock>
              <Button width={160} size='medium' type='submit' isLoading={isPaymentDetailsUpdating}>
                Submit
              </Button>
            </ButtonBlock>
          </div>
        )}
      </FormikForm>
    </PaymentDetailsFormWrapper>
  );
};
