import { GRADIENTS } from '@/styles/gradients';
import { formatBlockchainId, phoneFormatter } from '@/helpers/formatHelpers';

export const DOMESTIC_PAYMENT_DETAILS_CONFIG = (paymentDetails) => {
  if (!paymentDetails) return;

  const {
    blockchain_payment_details_data: blockchainPaymentDetails,
    internal_payment_details_data: internalPaymentDetails,
    is_blockchain_payment_details_data_from_bc: isDataFromBlockchain,
  } = paymentDetails;

  return {
    blockchainId: {
      label: 'Blockchain ID',
      value: formatBlockchainId(paymentDetails?.blockchain_id),
      title: paymentDetails?.blockchain_id || '-',
    },
    recipient: {
      label: 'Recipient',
      value: internalPaymentDetails?.recipient_name || '-',
    },
    recipient_address: {
      label: 'Recipient Address',
      value: internalPaymentDetails?.recipient_address || '-',
    },
    accountName: {
      label: 'Account Name',
      value: internalPaymentDetails?.account_name || '-',
    },
    abaNumber: {
      label: 'Routing / ABA Number',
      value: blockchainPaymentDetails?.bank_aba_number,
      gradient: isDataFromBlockchain ? GRADIENTS.green : '',
    },
    bankName: {
      label: 'Bank Name',
      value: internalPaymentDetails?.bank_name || '-',
    },
    accountNumber: {
      label: 'Account Number',
      value: blockchainPaymentDetails?.bank_account_number,
      gradient: isDataFromBlockchain ? GRADIENTS.green : '',
    },
    bankAddress: {
      label: 'Bank Address',
      value: internalPaymentDetails?.bank_address || '-',
    },
    bankPhoneNumber: {
      label: 'Bank Phone Number',
      value: phoneFormatter(internalPaymentDetails?.bank_phone_number),
    },
  };
};

export const INTERNATIONAL_PAYMENT_DETAILS_CONFIG = (paymentDetails) => {
  if (!paymentDetails) return;

  const {
    blockchain_payment_details_data: blockchainPaymentDetails,
    internal_payment_details_data: internalPaymentDetails,
    is_blockchain_payment_details_data_from_bc: isDataFromBlockchain,
  } = paymentDetails;

  return {
    blockchainId: {
      label: 'Blockchain ID',
      value: formatBlockchainId(paymentDetails?.blockchain_id),
      title: paymentDetails?.blockchain_id || '-',
    },
    recipient: {
      label: 'Recipient',
      value: internalPaymentDetails?.recipient_name || '-',
    },
    recipient_address: {
      label: 'Recipient Address',
      value: internalPaymentDetails?.recipient_address || '-',
    },
    accountName: {
      label: 'Account Name',
      value: internalPaymentDetails?.account_name || '-',
    },
    swiftCode: {
      label: 'Swift Code',
      value: blockchainPaymentDetails?.bank_swift_code,
      gradient: isDataFromBlockchain ? GRADIENTS.green : '',
    },
    bankName: {
      label: 'Bank Name',
      value: internalPaymentDetails?.bank_name || '-',
    },
    accountNumber: {
      label: 'Account Number',
      value: blockchainPaymentDetails?.bank_account_number,
      gradient: isDataFromBlockchain ? GRADIENTS.green : '',
    },
    bankAddress: {
      label: 'Bank Address',
      value: internalPaymentDetails?.bank_address || '-',
    },
    bankPhoneNumber: {
      label: 'Bank Phone Number',
      value: phoneFormatter(internalPaymentDetails?.bank_phone_number),
    },
  };
};
