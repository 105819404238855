import { LineOfChipsWrapper } from './styles';
import { usePrepareChipsData, ChipType, DATE_CHIP_ID } from './usePrepareChipsData';
import { trimString } from '@/helpers/stringHelpers';
import { Chips } from '../Chips/Chips';

type LineOfChipsPropsType = {
  initialValues?: any;
  filterConfig?: any;
};

export const LineOfChips = ({ initialValues, filterConfig }: LineOfChipsPropsType) => {
  const { chips, removeChip } = usePrepareChipsData(initialValues, filterConfig);

  const removeChipHandler = (item: ChipType, isRemovable: boolean) => {
    if (!isRemovable) return;

    removeChip(item.id);
  };

  return (
    <LineOfChipsWrapper>
      {chips.map((item) => {
        const trimmedLabel = trimString(item.label, 50);
        const isRemovable = item.id !== DATE_CHIP_ID;

        return (
          <Chips
            key={item.id}
            title={trimmedLabel}
            onClick={() => removeChipHandler(item, isRemovable)}
            isRemovable={isRemovable}
          />
        );
      })}
    </LineOfChipsWrapper>
  );
};
