import { forwardRef } from 'react';
import { SelectOptionsWrapper, SelectOptionsItem } from './styles';
import { useOutsideClick } from '../../../hooks/useOutsideClick';

export type OptionsListType = {
  value: string;
  options: any;
  onChange: (currentValue: any) => void;
};

export type SelectOptionsType = OptionsListType & {
  onClose: () => void;
};

export const OptionsList = ({ value, options, onChange }) => {
  return (
    <>
      {options?.map((option: any) => {
        const isSelected = value === option.value;

        return (
          <SelectOptionsItem isSelected={isSelected} key={option.value} onClick={() => onChange(option.value)}>
            <div>{option.label}</div>
          </SelectOptionsItem>
        );
      })}
    </>
  );
};

export const SelectOptions = forwardRef<HTMLElement, SelectOptionsType>(
  ({ value, options, onChange, onClose }, forwardedRef) => {
    useOutsideClick(forwardedRef, () => {
      onClose();
    });

    return (
      <SelectOptionsWrapper>
        <OptionsList value={value} options={options} onChange={onChange} />
      </SelectOptionsWrapper>
    );
  }
);
