import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CREATE_DEPOSIT_ACCOUNT_DOMESTIC_FORM_CONFIG } from './const/depositAccountDomesticPaymentFormConfig';
import { CREATE_DEPOSIT_ACCOUNT_INTERNATIONAL_FORM_CONFIG } from './const/depositAccountInternationalPaymentFormConfig';
import { PAYMENT_TYPE_DB } from '@/const/shared';
import { useDepositAccountRadioGroupConfig } from './hooks/useDepositAccountRadioGroupConfig';
import { isDomesticPaymentType, isInternationalPaymentType } from '@/helpers/paymentDetailsHelpers';
import { useFormikForm } from '@/components/form/useFormikForm/useFormikForm';
import { createDepositAccountValidationSchema } from './const/createDepositAccountValidationSchema';
import { createDepositAccount } from './api';
import { getDepositAccountsUrl } from '../urls/getDepositAccountsUrl';
import { isValidForm } from '@/helpers/formHelpers/formHelpers';
import { ContentLayout } from '@/bundle/Layouts/ContentLayout/ContentLayout';
import { CreateDepositAccountForm, CreateDepositAccountFormButton, CreateDepositAccountWrapper } from './styles';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { FormBlockGenerator } from '@/components/form/FormBlockGenerator/FormBlockGenerator';
import { AddIntermediaryBankForm } from './ui/AddIntermediaryBankForm/AddIntermediaryBankForm';
import { Button } from '@/components/Button/Button';
import { ConfirmModal, ConfirmModalHint } from '@/components/ConfirmModal/ConfirmModal';
import { getDepositAccountDetailsUrl } from '../_Details/urls/getDepositAccountDetailsUrl';
import { PaymentType } from '@/types/paymentDetailsTypes';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import {
  CREATE_DEPOSIT_ACCOUNT_REDIRECT,
  CREATE_DEPOSIT_ACCOUNT_SUCCESS,
  DEPOSIT_ACCOUNT_MAP,
} from '@/bundle/_OrgAdmin/pages/DepositAccounts/_Create/const/amplitudeEventsMap';

const {
  DEPOSIT_ACCOUNT_DOMESTIC_PAYMENT_FORM_CONFIG,
  intermediaryBankInitialValues,
  DEPOSIT_ACCOUNT_DOMESTIC_PAYMENT_INTERMEDIARY_BANK_FORM_CONFIG,
} = CREATE_DEPOSIT_ACCOUNT_DOMESTIC_FORM_CONFIG;
const {
  DEPOSIT_ACCOUNT_INTERNATIONAL_PAYMENT_FORM_CONFIG,
  internationalIntermediaryBankInitialValues,
  DEPOSIT_ACCOUNT_INTERNATIONAL_PAYMENT_INTERMEDIARY_BANK_FORM_CONFIG,
} = CREATE_DEPOSIT_ACCOUNT_INTERNATIONAL_FORM_CONFIG;

const depositAccountMap = (
  isDomesticPayment: boolean,
  isInternationalPayment: boolean,
  isOpenIntermediaryBank: boolean
) => {
  if (isDomesticPayment && !isOpenIntermediaryBank) return DEPOSIT_ACCOUNT_MAP.DOMESTIC_WITHOUT_INTERMEDIARY;

  if (isDomesticPayment && isOpenIntermediaryBank) return DEPOSIT_ACCOUNT_MAP.DOMESTIC_WITH_INTERMEDIARY;

  if (isInternationalPayment && !isOpenIntermediaryBank) return DEPOSIT_ACCOUNT_MAP.INTERNATIONAL_WITHOUT_INTERMEDIARY;

  if (isInternationalPayment && isOpenIntermediaryBank) return DEPOSIT_ACCOUNT_MAP.INTERNATIONAL_WITH_INTERMEDIARY;
};

const useInitialValues = (isDomesticPayment: boolean) => {
  const { getInitialsValues } = useFormikForm();

  const { initialDomesticPaymentFormWithIntermediaryBankFormConfig } = CREATE_DEPOSIT_ACCOUNT_DOMESTIC_FORM_CONFIG;
  const { initialInternationalPaymentWithIntermediaryBankFormConfig } =
    CREATE_DEPOSIT_ACCOUNT_INTERNATIONAL_FORM_CONFIG;

  const currentConfig = isDomesticPayment
    ? initialDomesticPaymentFormWithIntermediaryBankFormConfig
    : initialInternationalPaymentWithIntermediaryBankFormConfig;

  const initialValues = getInitialsValues({}, currentConfig);

  return { initialValues };
};

export const CreateDepositAccountPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const [currentPaymentType, setCurrentPaymentType] = useState<PaymentType>(PAYMENT_TYPE_DB.DOMESTIC as PaymentType);
  const [isOpenIntermediaryBank, setIsOpenIntermediaryBank] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { changePaymentType } = useDepositAccountRadioGroupConfig();

  const isDomesticPayment = isDomesticPaymentType(currentPaymentType);
  const isInternationalPayment = isInternationalPaymentType(currentPaymentType);

  const { initialValues } = useInitialValues(isDomesticPayment);
  const validationSchema = createDepositAccountValidationSchema(isDomesticPayment, isOpenIntermediaryBank);

  const { mutate, data, isPending } = useMutation({
    mutationKey: ['create_deposit_account'],
    mutationFn: (payload) => {
      return createDepositAccount(payload, isDomesticPayment);
    },
    onSuccess(createdDepositAccount) {
      if (createdDepositAccount.error) return;

      const currentEvent = depositAccountMap(isDomesticPayment, isInternationalPayment, isOpenIntermediaryBank);

      amplitudeService.logEvent(CREATE_DEPOSIT_ACCOUNT_SUCCESS[currentEvent]);

      const { state } = location;
      const newState = state?.transactionId ? { transactionId: state?.transactionId, wireId: state?.wireId } : state;
      const id = createdDepositAccount?.body?.id;

      queryClient.invalidateQueries({ queryKey: ['load_deposit_account', id] });
      navigate(getDepositAccountDetailsUrl(id), { state: newState });
    },
    onSettled() {
      setIsModalOpen(false);
    },
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      await mutate(values);
    },
  });

  const submitHandler = () => {
    formik.handleSubmit();
  };

  const navigateToDepositAccounts = () => {
    navigate(getDepositAccountsUrl());
  };

  const openConfirmationModal = async () => {
    const isValid = await isValidForm(formik);

    if (!isValid) return;

    const currentEvent = depositAccountMap(isDomesticPayment, isInternationalPayment, isOpenIntermediaryBank);

    amplitudeService.logEvent(CREATE_DEPOSIT_ACCOUNT_REDIRECT[currentEvent]);

    setIsModalOpen(true);
  };

  const toggleIntermediaryBankFormVisibility = () => {
    setIsOpenIntermediaryBank(!isOpenIntermediaryBank);

    const initialIntermediaryBankValues = isDomesticPayment
      ? intermediaryBankInitialValues
      : internationalIntermediaryBankInitialValues;

    formik.setValues({
      ...formik.values,
      ...initialIntermediaryBankValues,
    });
  };

  const paymentFormConfig = isDomesticPayment
    ? DEPOSIT_ACCOUNT_DOMESTIC_PAYMENT_FORM_CONFIG
    : DEPOSIT_ACCOUNT_INTERNATIONAL_PAYMENT_FORM_CONFIG;

  const intermediaryBankConfig = isDomesticPayment
    ? DEPOSIT_ACCOUNT_DOMESTIC_PAYMENT_INTERMEDIARY_BANK_FORM_CONFIG
    : DEPOSIT_ACCOUNT_INTERNATIONAL_PAYMENT_INTERMEDIARY_BANK_FORM_CONFIG;

  // Used to preserve <deposit account name> when switching <current payment type>
  useEffect(() => {
    formik.setValues({
      ...initialValues,
      name: formik.values.name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPaymentType]);

  return (
    <ContentLayout
      title='Create Deposit Account'
      backButton='Back to Deposit Accounts'
      onBack={navigateToDepositAccounts}
    >
      <CreateDepositAccountWrapper>
        <CreateDepositAccountForm>
          <FormikForm value={formik}>
            <FormBlockGenerator
              configuration={paymentFormConfig}
              radioGroupConfig={changePaymentType(currentPaymentType, setCurrentPaymentType)}
              errorsApi={data?.error}
              columns={2}
            />
            <AddIntermediaryBankForm
              isOpen={isOpenIntermediaryBank}
              onToggle={toggleIntermediaryBankFormVisibility}
              error={data?.error}
              intermediaryBankConfig={intermediaryBankConfig}
            />
          </FormikForm>

          <CreateDepositAccountFormButton>
            <Button width={160} size='medium' onClick={openConfirmationModal}>
              Submit
            </Button>
          </CreateDepositAccountFormButton>
        </CreateDepositAccountForm>
      </CreateDepositAccountWrapper>

      <ConfirmModal
        isOpen={isModalOpen}
        header={'Confirm Deposit Account'}
        isLoading={isPending}
        body={
          <>
            <ConfirmModalHint>You are certifying the validity of your deposit account information.</ConfirmModalHint>
            <ConfirmModalHint>
              This information will be committed to the WireVault blockchain and cannot be changed.
            </ConfirmModalHint>
            <ConfirmModalHint>
              You can deactivate this deposit account at anytime and create a new one.
            </ConfirmModalHint>
          </>
        }
        onClose={() => setIsModalOpen(false)}
        onConfirm={submitHandler}
      />
    </ContentLayout>
  );
};
