import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { ContentLayout } from '../Layouts/ContentLayout/ContentLayout';
import { NoResults } from '../../components/NoResults/NoResults';
import { PageBody } from '../../components/Page/PageBody/PageBody';
import { ReactComponent as NoTransactionsIcon } from '../../images/NoTransactionsIcon.svg';
import { Paginator } from '../../components/Paginator/Paginator';
import { PageFooter } from '../../components/Page/PageFooter/PageFooter';
import { ExecutorWiresList } from './ui/ExecutorWiresList';
import { usePrepareQueryParams } from '@/helpers/apiHelpers/queryHelper';
import { loadExecutorWires } from './api';
import { UpsellBanner } from '@/bundle/shared/components/UpsellBanner/UpsellBanner';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { useHasOrgAdminRole } from '@/bundle/shared/hooks/useHasOrgAdminRole';

export const ExecutorWiresPage = () => {
  const searchParams = usePrepareQueryParams();
  const { hasOrgAdminRole } = useHasOrgAdminRole();

  const { data, isPending } = useQuery({
    queryKey: ['load_executor_wires', searchParams],
    queryFn: () => loadExecutorWires(searchParams),
    placeholderData: keepPreviousData,
  });

  const wires = data?.body?.results;
  const hasWires = !!wires?.length;
  const totalCount = data?.body?.count;
  const hasPaginator = totalCount > searchParams?.limit;

  const sendUpsellRequest = () => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.UpsellExecutorDashboard);
  };

  return (
    <ContentLayout
      title='Wires'
      subtitle='View the wires assigned to you. Click on the wire card to mark the wire as executed.'
      banner={hasOrgAdminRole ? null : <UpsellBanner onSent={sendUpsellRequest} />}
    >
      <PageBody>
        {hasWires && <ExecutorWiresList list={wires} />}
        {!hasWires && !isPending && (
          <NoResults marginTop={128} icon={<NoTransactionsIcon />} message='There is no wire yet.' />
        )}
      </PageBody>
      {hasPaginator && (
        <PageFooter>
          <Paginator page={searchParams?.page} limit={searchParams?.limit} total={totalCount} />
        </PageFooter>
      )}
    </ContentLayout>
  );
};
