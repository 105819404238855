import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { LoginTitle, LoginHintWrapper, LoginHint, ForgotPasswordLabel } from './styles';
import { login } from './api';
import { useMutation } from '@tanstack/react-query';
import { NavigationLink } from '@/components/NavigationLink/NavigationLink';
import { Box } from '@/components/Box/Box';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { loginValidationSchema } from './const/const';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { Notification } from '@/components/Notification/Notification';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { FormikInput } from '@/components/form/fields/FormikInput/FormikInput';
import { FormikPasswordInput } from '@/components/form/fields/FormikPasswordInput/FormikPasswordInput';
import { Button } from '@/components/Button/Button';
import { AuthLayout } from '@/bundle/Auth/ui/AuthLayout/AuthLayout';
import { AuthStep } from '@/bundle/Auth/ui/AuthStep/AuthStep';
import { useEffect, useRef, useState } from 'react';
import { getForgotPasswordCheckEmailUrl } from '@/bundle/Auth/ForgotPasswordFlow/ForgotPasswordCheckEmailPage/urls/getForgotPasswordCheckEmailUrl';
import { envConfig } from '@/config/envConfig';
import { useAuthNavigateToStep } from '../../hooks/useAuthNavigateToStep';
import { ExternalLink } from '@/components/ExternalLink/ExternalLink';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { LoginPayload } from '@/api/v1/users/public/loginApi';
import { GoogleReCaptcha, GoogleReCaptchaType } from '@/components/GoogleReCaptcha/GoogleReCaptcha';

const ERROR_NOTIFICATION_OFFSET = { mb: '24px' };
const CREATE_ACCOUNT_URL = `${envConfig.LANDING_URL}create-account/free`;

export const LoginPage = () => {
  const { state } = useLocation();
  const navigateToStep = useAuthNavigateToStep();
  const recaptchaRef = useRef<GoogleReCaptchaType>();
  const [captchaLoading, setCaptchaLoading] = useState(false);

  useEffect(() => {
    // Clear expired history state on page reload
    if (state?.sessionExpired) {
      window.history.replaceState(null, '');
    }

    // Clear account blocked state on page reload
    if (state?.accountBlocked) {
      window.history.replaceState(null, '');
    }
  }, [state?.sessionExpired, state?.accountBlocked]);

  const { mutate, data, isPending } = useMutation({
    mutationKey: ['login'],
    mutationFn: (payload: LoginPayload) => {
      return login(payload);
    },
    onSuccess(loginResponse) {
      if (loginResponse?.error) {
        recaptchaRef.current?.resetCaptcha();

        return;
      }

      amplitudeService.logEvent(AMPLITUDE_EVENTS.LogINEmailPwdCheckSuccess);

      return navigateToStep(loginResponse?.body);
    },
    retry: false,
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (values) => {
      setCaptchaLoading(true);

      recaptchaRef.current
        ?.executeCaptcha()
        .then(async (token) => {
          setCaptchaLoading(false);

          const payload = {
            email: values.email,
            password: values.password,
            recaptcha_response: token,
          };

          await mutate(payload);
        })
        .catch((error) => {
          console.error('google captcha failed with', error?.message);

          setCaptchaLoading(false);
        });
    },
    enableReinitialize: true,
    validationSchema: loginValidationSchema,
  });

  const error = data?.error;
  const formError = getResponseError(data?.error) || getResponseError(data?.error, 'recaptcha_response');

  return (
    <AuthLayout>
      <AuthStep>
        <LoginTitle>Login</LoginTitle>

        {state?.sessionExpired && !error && (
          <Notification variant='error' {...ERROR_NOTIFICATION_OFFSET}>
            You have been logged out because your session has expired.
          </Notification>
        )}

        {state?.accountBlocked && !error && (
          <Notification variant='error' {...ERROR_NOTIFICATION_OFFSET}>
            You don`t have access to the application.
          </Notification>
        )}

        {formError && (
          <Notification variant='error' {...ERROR_NOTIFICATION_OFFSET}>
            {formError}
          </Notification>
        )}
        <FormikForm value={formik}>
          <FormikInput label={'Email Address*'} placeholder={'Enter Email Address'} name='email' errorsApi={error} />
          <FormikPasswordInput
            label={'Password*'}
            placeholder={'Enter Your Password'}
            name='password'
            errorsApi={error}
            subLabel={
              <ForgotPasswordLabel>
                <NavigationLink
                  to={getForgotPasswordCheckEmailUrl()}
                  onClick={() => amplitudeService.logEvent(AMPLITUDE_EVENTS.ForgotPasswordStartRedirect)}
                >
                  {'Forgot password?'}
                </NavigationLink>
              </ForgotPasswordLabel>
            }
          />
          <GoogleReCaptcha ref={recaptchaRef} />
          <Box mt='12px' mb='24px'>
            <Button isLoading={isPending || captchaLoading} type='submit' onClick={formik.handleSubmit}>
              Login
            </Button>
          </Box>
        </FormikForm>
        <LoginHintWrapper>
          <LoginHint>New to WireVault?</LoginHint>
          <ExternalLink href={CREATE_ACCOUNT_URL} target='_blank' variant='small'>
            Create your free account.
          </ExternalLink>
        </LoginHintWrapper>
      </AuthStep>
    </AuthLayout>
  );
};
