import { Notification } from '@/components/Notification/Notification';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Widget } from '@/components/Widget/Widget';
import { DepositAccountLink } from '../DepositAccountLink/DepositAccountLink';
import { IntermediaryBankBlock, Separator } from '../../styles';
import {
  domesticIntermediaryBank,
  internationalIntermediaryBank,
  paymentDetailsDomesticWidgetConfig,
  paymentDetailsInternationalWidgetConfig,
} from '../../const';
import { isDomesticPaymentType } from '@/helpers/paymentDetailsHelpers';
import { isDepositorUnassignedWireStatus } from '@/helpers/wireHelpers';
import { WireStatusType } from '@/types/wireTypes';
import { DetailsBlockGenerator } from '@/components/DetailsBlockGenerator/DetailsBlockGenerator';
import { PaymentDetailsGeneralType } from '@/types/paymentDetailsTypes';

const DeleteTooltip = () => {
  return <Tooltip id='delete-tooltip'>Reset Wire Info</Tooltip>;
};

type PaymentDetailsType = {
  paymentDetails: PaymentDetailsGeneralType;
  hasIntermediaryBank: boolean;
  wireStatus: WireStatusType;
  hasAssignedDepositor: boolean;
  onReset: () => void;
};

export const PaymentDetails = ({
  paymentDetails,
  hasIntermediaryBank,
  wireStatus,
  hasAssignedDepositor,
  onReset,
}: PaymentDetailsType) => {
  const isDepositorUnassignedWire = isDepositorUnassignedWireStatus(wireStatus);
  const currentPaymentType = paymentDetails?.internal_payment_details_data?.payment_type;
  const isDomesticPayment = isDomesticPaymentType(currentPaymentType);

  const currentPaymentDetailsConfig = isDomesticPayment
    ? paymentDetailsDomesticWidgetConfig(paymentDetails)
    : paymentDetailsInternationalWidgetConfig(paymentDetails);
  const currentIntermediaryBankConfig =
    hasIntermediaryBank && isDomesticPayment
      ? domesticIntermediaryBank(paymentDetails)
      : internationalIntermediaryBank(paymentDetails);

  const hasBlockchainData = paymentDetails?.is_blockchain_payment_details_data_from_bc;
  const hasDatabaseData = paymentDetails?.is_blockchain_payment_details_data_from_bc === false && hasAssignedDepositor;

  return (
    <Widget
      title='Wire Info'
      hasDeleteButton={isDepositorUnassignedWire}
      onToggle={onReset}
      tooltip={<DeleteTooltip />}
    >
      {hasBlockchainData && (
        <Notification variant='success' fontVariant='medium' verticalAlign='center' mb='15px'>
          Stored in the WireVault blockchain.
        </Notification>
      )}

      {hasDatabaseData && (
        <Notification variant='info-secondary' mb='15px'>
          The WireVault blockchain is unavailable. The wire info cannot be confirmed at this time. Please try again
          later.
        </Notification>
      )}
      <DetailsBlockGenerator configuration={currentPaymentDetailsConfig} />
      {hasIntermediaryBank && (
        <>
          <IntermediaryBankBlock>{'Intermediary Bank'}</IntermediaryBankBlock>
          <DetailsBlockGenerator configuration={currentIntermediaryBankConfig} />
        </>
      )}
      <Separator />
      <DepositAccountLink depositAccount={paymentDetails?.deposit_account} />
    </Widget>
  );
};
