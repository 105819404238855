import './loader.css';

export const FormSubmitLoader = () => {
  return (
    <div className='loader3'>
      <div className='circle1'></div>
      <div className='circle1'></div>
      <div className='circle1'></div>
    </div>
  );
};
