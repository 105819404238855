import { AssignedWireType, WireStatusType } from '@/types/wireTypes';
import { WIRE_STATUS_DB } from '@/const/wire';

export const isDepositSentWireStatus = (status: WireStatusType) => {
  return status === WIRE_STATUS_DB.DEPOSIT_SENT;
};

export const isExecutedWireStatus = (status: WireStatusType) => {
  return status === WIRE_STATUS_DB.EXECUTED;
};

export const isCancelledWireStatus = (status: WireStatusType) => {
  return status === WIRE_STATUS_DB.CANCELLED;
};

export const isPendingWireStatus = (status: WireStatusType) => {
  return status === WIRE_STATUS_DB.PENDING;
};

export const isProvidePaymentDetailsWireStatus = (status: WireStatusType) => {
  return status === WIRE_STATUS_DB.PROVIDE_PAYMENT_DETAILS;
};

export const isPaymentDetailsProvidedWireStatus = (status: WireStatusType) => {
  return status === WIRE_STATUS_DB.PAYMENT_DETAILS_PROVIDED;
};

export const isRetryWireStatus = (status: WireStatusType) => {
  return status === WIRE_STATUS_DB.RETRY;
};

export const isCreatedWireStatus = (status: WireStatusType) => {
  return status === WIRE_STATUS_DB.CREATED;
};

export const isDepositorUnassignedWireStatus = (status: WireStatusType) => {
  return status === WIRE_STATUS_DB.DEPOSITOR_UNASSIGNED;
};

export const isRecipientInvitedWireStatus = (status: WireStatusType) => {
  return status === WIRE_STATUS_DB.RECIPIENT_INVITED;
};

export const isRecipientAssignedWireStatus = (status: WireStatusType) => {
  return status === WIRE_STATUS_DB.RECIPIENT_ASSIGNED;
};

export const isRecipientProxyInvitedWireStatus = (status: WireStatusType) => {
  return status === WIRE_STATUS_DB.RECIPIENT_PROXY_INVITED;
};

export const isRecipientProxyAssignedWireStatus = (status: WireStatusType) => {
  return status === WIRE_STATUS_DB.RECIPIENT_PROXY_ASSIGNED;
};

export const isExecutorInvitedWireStatus = (status: WireStatusType) => {
  return status === WIRE_STATUS_DB.EXECUTOR_INVITED;
};

// Org Admin invite recipient proxy, which can invite another recipient or provide payment details by himself
export const isRecipientProxy = (wire: AssignedWireType) => {
  if (!wire) return null;

  return !!wire?.assigned_proxy_user;
};

// Recipient proxy, which invites another recipient to provide payment details
export const isRecipientChainProxy = (wire: AssignedWireType) => {
  if (!wire) return null;

  return isRecipientProxy(wire) && !!wire?.assigned_user;
};
