import { useFormik } from 'formik';
import { FormGeneratorNew } from '@/components/form/FormGeneratorNew/FormGeneratorNew';
import { EDIT_PERSONAL_DETAILS_FORM_CONFIG } from '@/bundle/pages/ProfilePage/ui/const';
import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import {
  FormValues,
  getChangedFormValues,
  getMergedValues,
  getValidationSchema,
} from '@/helpers/formHelpers/formHelpers';
import { useState } from 'react';
import { ConfirmModal } from '@/components/ConfirmModal/ConfirmModal';
import { useMutation } from '@tanstack/react-query';
import { updateSelfUserDetails } from '@/bundle/pages/ProfilePage/api';
import { PersonalDetailsType } from '@/bundle/shared/components/UserDetails/UserPersonalInfo';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';

interface EditPersonalDetailsFormType {
  onClose: () => void;
  isOrgAdmin: boolean;
  loadUser: () => void;
  personalDetails: PersonalDetailsType;
}

export const EditPersonalDetailsForm = ({
  onClose,
  isOrgAdmin,
  loadUser,
  personalDetails,
}: EditPersonalDetailsFormType) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const closeModal = () => setIsOpenModal(false);
  const openModal = () => setIsOpenModal(true);

  const { data, mutate, isPending } = useMutation({
    mutationKey: ['edit_user_general_info'],
    mutationFn: (values: FormValues) => {
      return updateSelfUserDetails(values);
    },
    onSuccess: (updateSelfUserResponse) => {
      if (updateSelfUserResponse?.error) {
        closeModal();

        return;
      }

      amplitudeService.logEvent(AMPLITUDE_EVENTS.ProfileEditPersonalInfoSuccess);

      loadUser();
      closeModal();
      onClose();
    },
  });

  const formik = useFormik({
    initialValues: getMergedValues(EDIT_PERSONAL_DETAILS_FORM_CONFIG, personalDetails),
    validationSchema: getValidationSchema(EDIT_PERSONAL_DETAILS_FORM_CONFIG),
    onSubmit: (values) => {
      const payload = getChangedFormValues<FormValues>(values, personalDetails as FormValues);

      if (!payload) {
        onClose();

        return;
      }

      if (isOrgAdmin) {
        mutate(payload);

        return;
      }

      amplitudeService.logEvent(AMPLITUDE_EVENTS.ProfileEditPersonalInfoConfirm);
      openModal();
    },
  });

  const onConfirm = () => {
    const payload = getChangedFormValues<FormValues>(formik.values, personalDetails as FormValues);

    if (!payload) {
      closeModal();
      onClose();

      return;
    }

    mutate(payload);
  };

  return (
    <>
      <Box flexDirection='column' rowGap='14px'>
        <FormikForm value={formik}>
          <FormGeneratorNew config={EDIT_PERSONAL_DETAILS_FORM_CONFIG} apiError={data?.error} />
          <Box display='flex' columnGap='20px' mt='14px'>
            <Button color='secondary' onClick={onClose}>
              Cancel
            </Button>
            <Button type='submit'>Save</Button>
          </Box>
        </FormikForm>
      </Box>
      <ConfirmModal
        isOpen={isOpenModal}
        header='Edit Personal Info'
        body='Your information will be updated in all wires assigned to you.'
        onClose={closeModal}
        onConfirm={onConfirm}
        isLoading={isPending}
      />
    </>
  );
};
