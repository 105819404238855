import { useNavigate } from 'react-router-dom';
import { AuthTitle } from '../../ui/AuthTitle/AuthTitle';
import { AuthMessage } from '../../ui/styles';
import { getLoginUrl } from '@/bundle/Auth/LoginFlow/LoginPage/urls/getLoginUrl';
import { Button } from '@/components/Button/Button';
import { Box } from '@/components/Box/Box';
import { useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { sendForgotPassword } from './api';
import { useFormik } from 'formik';
import { checkEmailValidationSchema } from './const/checkEmailValidationSchema';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { CustomInputWrapper } from '@/components/form/fields/FormikInput/styles';
import { FormikInput } from '@/components/form/fields/FormikInput/FormikInput';
import { NextStepButtonWrapper } from './styles';
import { AuthLayout } from '../../ui/AuthLayout/AuthLayout';
import { AuthStep } from '../../ui/AuthStep/AuthStep';
import { Notification } from '@/components/Notification/Notification';
import { getForgotPasswordEmailSentUrl } from '../ForgotPasswordEmailSentPage/urls/getForgotPasswordEmailSentUrl';
import { ForgotPasswordPayload } from '@/api/v1/users/public/forgotPasswordApi';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { GoogleReCaptcha, GoogleReCaptchaType } from '@/components/GoogleReCaptcha/GoogleReCaptcha';

export const ForgotPasswordCheckEmailPage = () => {
  const navigate = useNavigate();
  const recaptchaRef = useRef<GoogleReCaptchaType>();
  const [captchaLoading, setCaptchaLoading] = useState(false);

  const { mutate, data, isPending } = useMutation({
    mutationKey: ['send_forgot_password'],
    mutationFn: (payload: ForgotPasswordPayload) => {
      return sendForgotPassword(payload);
    },
    onSuccess(sendForgotPasswordResponse) {
      if (sendForgotPasswordResponse?.error) {
        recaptchaRef.current?.resetCaptcha();

        return;
      }

      amplitudeService.logEvent(AMPLITUDE_EVENTS.ForgotPasswordSendLinkToEmail);

      return navigate(getForgotPasswordEmailSentUrl());
    },
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async ({ email }) => {
      setCaptchaLoading(true);

      recaptchaRef.current
        ?.executeCaptcha()
        .then(async (token) => {
          setCaptchaLoading(false);

          const payload = {
            email,
            recaptcha_response: token,
          };

          await mutate(payload);
        })
        .catch((error) => {
          console.error('google captcha failed with', error?.message);

          setCaptchaLoading(false);
        });
    },
    enableReinitialize: true,
    validationSchema: checkEmailValidationSchema,
  });

  const captchaError = getResponseError(data?.error, 'recaptcha_response');

  const navigateToLogin = () => {
    navigate(getLoginUrl());
  };

  return (
    <AuthLayout>
      <AuthStep>
        <AuthTitle>Set Your New Password</AuthTitle>
        <Box mb='36px'>
          <AuthMessage>
            Please enter the email address associated with your account and we will send you a link to set your new
            password.
          </AuthMessage>
        </Box>

        {!!captchaError && (
          <Notification variant='error' mb='24px'>
            {captchaError}
          </Notification>
        )}

        <FormikForm value={formik}>
          <CustomInputWrapper>
            <FormikInput
              id='email-address'
              autoComplete='new-field'
              name={'email'}
              label={'Email Address*'}
              placeholder={'Enter Your Email Address'}
              errorsApi={data?.error}
            />
          </CustomInputWrapper>
          <GoogleReCaptcha ref={recaptchaRef} />
          <NextStepButtonWrapper>
            <Button isLoading={isPending || captchaLoading} onClick={formik.handleSubmit} disabled={!formik.isValid}>
              Send Link
            </Button>
          </NextStepButtonWrapper>
        </FormikForm>

        <Box justifyContent='center'>
          <Button color='secondary' onClick={navigateToLogin}>
            Return to Login
          </Button>
        </Box>
      </AuthStep>
    </AuthLayout>
  );
};
