import styled from 'styled-components';

export const ChipsWrapper = styled.div`
  width: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #eceeef;
  border-radius: 8px;
  padding: 2px 8px;
  margin: 0 8px 8px 0;
`;

export const ChipsTitle = styled.div`
  font-family: 'Poppins-Regular', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: rgba(34, 37, 40, 0.7);
`;

export const ChipsButton = styled.div`
  cursor: pointer;
  color: rgba(34, 37, 40, 0.7);
  margin-left: 4px;

  &:hover {
    color: #ff7575;
  }
`;
