import { media } from '@/styles/responsive';
import styled from 'styled-components';

export const PaymentDetails = styled.div``;

export const PaymentDetailsWrapper = styled.div`
  max-width: 524px;
  width: 100%;

  ${media.mobile`
    max-width: none;
  `}
`;
