import { useCallback } from 'react';
import { RotateIconBlock, WidgetTitle, WidgetWrapper, WidgetWrapperDarkStroke } from './styles';
import { ReactComponent as RotateIcon } from '../../images/RotateIcon.svg';
import { DetailsEditButton } from '@/bundle/_OrgAdmin/shared/ui/DetailsEditButton/DetailsEditButton';

export const WIDGET_COMPONENT_MAP = {
  default: WidgetWrapper,
  dark: WidgetWrapperDarkStroke,
};

type WidgetType = {
  title?: string;
  wrapperVariant?: string;
  hasButton?: boolean;
  setIsOpen?: (boolean) => void;
  requestFunction?: any;
  children: any;
  headerOffset?: number;
  hasDeleteButton?: boolean;
  onToggle?: () => void;
  tooltip?: any;
};

export const Widget = ({
  title,
  wrapperVariant = 'default',
  hasButton,
  setIsOpen,
  requestFunction,
  children,
  headerOffset = 20,
  hasDeleteButton,
  onToggle,
  tooltip,
}: WidgetType) => {
  const WidgetWrapperCmp = WIDGET_COMPONENT_MAP[wrapperVariant];

  const onClick = useCallback(() => {
    setIsOpen((prev) => !prev);
    requestFunction?.();
  }, [setIsOpen, requestFunction]);

  return (
    <WidgetWrapperCmp>
      {title && (
        <WidgetTitle marginBottom={headerOffset}>
          {title}
          {hasButton && <DetailsEditButton onEdit={onClick} />}
          {hasDeleteButton && (
            <RotateIconBlock onClick={onToggle} data-tooltip-id='delete-tooltip'>
              {tooltip}
              <RotateIcon />
            </RotateIconBlock>
          )}
        </WidgetTitle>
      )}
      {children}
    </WidgetWrapperCmp>
  );
};
