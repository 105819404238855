export const getBilledEventsList = (billedEvents = []) => {
  return billedEvents.map(({ id, date_created, wire, user, organization, id_verification }) => {
    return {
      id: id,
      org_name: organization?.name,
      date_viewed: date_created,
      wire_name: wire?.name,
      id_verification,
      wire_ref_id: wire?.ref_id,
      user_viewed: user?.email,
      transaction_name: wire?.contract?.name,
      payment_details_collection_type: wire?.payment_details_collection_type,
    };
  });
};

export const getOrganizationOptions = (organizations = []) => {
  return organizations.map((organization) => {
    return {
      value: organization?.id,
      label: organization?.name,
    };
  });
};
