import styled from 'styled-components';

export const FormikSelectWrapper = styled.div<{
  width?: number;
  minWidth?: number;
}>`
  width: 100%;
`;

export const CustomSelectInputWrapper = styled.div`
  position: relative;
  margin-bottom: 3px;
`;
