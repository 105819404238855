import { TableCell, TableRow } from '../styles';
import { TableConfigItemType, TableConfigItemsType } from '../types/types';

export const TableEmptyRow = ({ columns }: TableConfigItemsType) => {
  return (
    <TableRow>
      {columns.map((column: TableConfigItemType) => {
        return (
          <TableCell key={column.key} width={column.width}>
            -
          </TableCell>
        );
      })}
    </TableRow>
  );
};
