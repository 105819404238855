import styled from 'styled-components';
import { flex } from '@/styles/layout';
import { body, bodySmall, poppinsMediumFont } from '@/styles/typography';
import { COLORS } from '@/styles/colors';
import { media } from '@/styles/responsive';

export const ButtonWrapper = styled.button<{
  width: number;
  size: 'large' | 'medium';
  color: string;
  mobileStretch?: boolean;
}>`
  position: relative;
  ${flex({ justifyContent: 'center', alignItems: 'center' })};
  ${poppinsMediumFont};
  ${body};
  text-align: center;
  padding: 13px 16px;
  border-style: none;
  border-radius: 10px;
  color: ${COLORS.white};
  background-color: ${COLORS.green[500]};
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  height: 50px;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background: #018e76;
  }

  &:disabled {
    color: ${COLORS.grey[600]};
    background-color: ${COLORS.grey[300]};
    cursor: not-allowed !important;
    border: none !important;
    pointer-events: none;
  }

  ${media.mobile`
    ${({ mobileStretch }) =>
      mobileStretch &&
      `
      width: 100%;
    `};
  `}

  ${({ size }) =>
    size === 'medium' &&
    `
    height: 40px;
    ${bodySmall};
  `};

  ${({ color }) =>
    color === 'secondary' &&
    `
    color: ${COLORS.green[500]};
    background-color: ${COLORS.white};
    border: 1px solid ${COLORS.green[500]};

    &:hover {
      background: ${COLORS.mint[100]};
    }
  `};
`;

export const ButtonContent = styled.div<{ hasIcon: boolean }>`
  ${flex({ alignItems: 'center' })};
  justify-content: ${({ hasIcon }) => (hasIcon ? 'space-between' : 'center')};
  width: ${({ hasIcon }) => (hasIcon ? '100%' : '0')};

  ${media.mobile`
    justify-content: center;
    gap: 10px;
  `}
`;
