import { COLORS } from '@/styles/colors';
import { media } from '@/styles/responsive';
import { body, headline4, poppinsMediumFont, poppinsRegularFont, poppinsSemiBoldFont } from '@/styles/typography';
import styled from 'styled-components';

export const HeaderMessage = styled.h2`
  width: 100%;
  color: ${COLORS.black};
  ${poppinsSemiBoldFont};
  ${headline4};
  margin: 0 0 16px 0;
  text-align: center;
`;

export const Message = styled.div`
  color: ${COLORS.grey[600]};
  ${poppinsRegularFont};
  ${body};
  text-align: center;
  max-width: 420px;
  width: 100%;

  ${media.mobile`
    max-width: 100%;
  `}
`;

export const ContinueMessage = styled.div`
  color: ${COLORS.grey[950]};
  ${poppinsMediumFont};
  ${body};
  text-align: center;
`;
