import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { bodySmall2, poppinsMediumFont } from '@/styles/typography';
import { COLORS } from '@/styles/colors';
import { resetButton } from '@/styles/common';
import { media } from '@/styles/responsive';
import './animation.css';

// We only use this breakpoint for snackbar design, since our mobile breakpoint(768px) is too wide for it.
const SNACKBAR_MOBILE_BREAKPOINT = '480px';

export const StyledSnackbar = styled(ToastContainer).attrs({
  className: 'wv-snackbar',
})`
  &.Toastify__toast-container {
    width: auto;
    max-width: none;

    @media only screen and (max-width: ${SNACKBAR_MOBILE_BREAKPOINT}) {
      width: 100%;
    }
  }

  &.Toastify__toast-container--top-right {
    top: 2em;
    right: 2em;

    ${media.tablet`
      top: 5rem;
    `}
  }

  .Toastify__toast {
    max-width: 340px;
    background-color: ${COLORS.grey[900]};
    color: ${COLORS.grey[300]};
    border-radius: 12px;
    padding: 0;
    ${poppinsMediumFont};
    ${bodySmall2};

    @media only screen and (max-width: ${SNACKBAR_MOBILE_BREAKPOINT}) {
      margin: 0 16px 16px 16px;
      max-width: none !important;
    }
  }

  .Toastify__toast-body {
    padding: 13px 20px 16px 20px;
  }

  .Toastify__toast-icon {
    width: 26px;
    margin-right: 16px;
  }

  .Toastify__progress-bar--error {
    background: linear-gradient(90deg, ${COLORS.red[400]} -0.62%, rgba(227, 65, 76, 0.25) 108.89%);
  }

  .Toastify__progress-bar--success {
    background: linear-gradient(90deg, ${COLORS.green[400]} 0.92%, rgba(1, 146, 102, 0.25) 107.47%);
  }

  .Toastify__progress-bar {
    right: 40px;
    width: auto;
  }
`;

export const CloseButtonWrapper = styled.button`
  ${resetButton};
  padding: 10px;
  background-color: ${COLORS.grey[850]};
  cursor: pointer;
`;
