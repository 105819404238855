import { COLORS } from '@/styles/colors';
import { resetList } from '@/styles/common';
import { flex, flexCenter } from '@/styles/layout';
import { media } from '@/styles/responsive';
import { bodySmall, poppinsRegularFont, poppinsSemiBoldFont, subTitleSmall } from '@/styles/typography';
import styled from 'styled-components';

export const PinCodeWrapper = styled.div`
  ${flex()};
  background-color: ${COLORS.white};
  box-shadow: 0 2px 10px rgba(19, 62, 112, 0.2);
  border-radius: 20px;

  ${media.tablet`
    border-radius: 0px;
    height: 100%
  `}

  ${media.mobile`
    ${flex({ flexDirection: 'column' })};
  `}
`;

export const InstructionRow = styled.div``;

export const InstructionsWrapper = styled.div`
  width: 640px;
  padding: 28px 32px;
  border-right: 1px solid ${COLORS.grey[300]};

  ${media.tablet`
    width: 100%;
  `}

  ${media.mobile`
    padding: 28px 16px 14px;
  `}
`;

export const InstructionHeader = styled.div`
  ${flex({ alignItems: 'center' })};
  ${poppinsSemiBoldFont};
  ${subTitleSmall};
`;

export const InstructionSubHeader = styled.div`
  ${poppinsSemiBoldFont}
`;

export const InstructionBody = styled.div`
  margin-top: 8px;
  ${poppinsRegularFont};
  ${bodySmall};
`;

export const HeaderIcon = styled.div`
  ${flexCenter};
  width: 42px;
  height: 42px;
  margin-right: 16px;
  background-color: ${COLORS.grey[200]};
  border-radius: 12px;
`;

export const RulesList = styled.ul`
  ${resetList};
  ${flex({ flexDirection: 'column' })};
  gap: 10px;
  margin-top: 12px;
`;

export const RulesListItem = styled.li`
  ${flex({ alignItems: 'center' })};
  margin-left: 16px;

  &::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 14px;
    background-color: ${COLORS.green[500]};
    border-radius: 50%;
  }
`;
