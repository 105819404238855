import { DetailsRow } from '@/components/DetailsWidget/DetailsRow/DetailsRow';
import { IntermediaryDetailsHeader, PaymentDetailsDivider, Title } from './styles';
import { phoneFormatter } from '@/helpers/formatHelpers';
import { PaymentDetailsGeneralType } from '@/types/paymentDetailsTypes';

type SourceWirePaymentDetailsIntermediaryBankType = {
  paymentDetails: PaymentDetailsGeneralType;
  isDomesticPayment: boolean;
  bankCodeLabel: string;
};

export const SourceWirePaymentDetailsIntermediaryBank = ({
  paymentDetails,
  isDomesticPayment,
  bankCodeLabel,
}: SourceWirePaymentDetailsIntermediaryBankType) => {
  const { internal_payment_details_data, blockchain_payment_details_data } = paymentDetails;
  const { intermediary_bank_name, intermediary_bank_address, intermediary_bank_phone_number } =
    internal_payment_details_data;
  const { intermediary_bank_aba_number, intermediary_bank_swift_code, intermediary_bank_account_number } =
    blockchain_payment_details_data;
  const intermediaryBankCodeValue =
    (isDomesticPayment ? intermediary_bank_aba_number : intermediary_bank_swift_code) || '-';

  return (
    <>
      <PaymentDetailsDivider />
      <IntermediaryDetailsHeader>Intermediary Bank</IntermediaryDetailsHeader>
      <DetailsRow name={<Title>{bankCodeLabel}</Title>}>{intermediaryBankCodeValue}</DetailsRow>
      <DetailsRow name={<Title>Bank Name</Title>}>{intermediary_bank_name || '-'}</DetailsRow>
      <DetailsRow name={<Title>Account Number</Title>}>{intermediary_bank_account_number || '-'}</DetailsRow>
      <DetailsRow name={<Title>Bank Address</Title>}>{intermediary_bank_address || '-'}</DetailsRow>
      <DetailsRow name={<Title>Bank Phone Number</Title>}>{phoneFormatter(intermediary_bank_phone_number)}</DetailsRow>
    </>
  );
};
