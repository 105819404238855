import { ContentLayout } from '../Layouts/ContentLayout/ContentLayout';
import { Paginator } from '../../components/Paginator/Paginator';
import { PageBody } from '../../components/Page/PageBody/PageBody';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { PageFooter } from '../../components/Page/PageFooter/PageFooter';
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { usePrepareQueryParams } from '@/helpers/apiHelpers/queryHelper';
import { loadOrganizations, loadPricingTierList } from './api';
import { OpcoOrganizationsTable } from './ui/OpcoOrganizationsTable';
import { Drawer } from '@/components/Drawer/Drawer';
import { useState } from 'react';
import { ModifyOrganizationForm } from './ui/ModifyOrganizationForm/ModifyOrganizationForm';
import { OrganizationListItemType } from '@/bundle/_Opco/types/types';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { Button } from '@/components/Button/Button';
import { ReactComponent as PlusIcon } from '../../images/PlusIcon.svg';

export const OpcoOrganizationsPage = () => {
  const queryClient = useQueryClient();
  const searchParams = usePrepareQueryParams();

  const [organizationId, setOrganizationId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const { data } = useQuery({
    queryKey: ['load_organizations', searchParams],
    queryFn: () => loadOrganizations(searchParams),
    placeholderData: keepPreviousData,
  });

  const { data: pricingTierList } = useQuery({
    queryKey: ['load_pricing_tier'],
    queryFn: () => loadPricingTierList(),
  });

  const openCreateOrganizationForm = () => {
    setIsOpen(true);
    setIsEditMode(false);

    amplitudeService.logEvent(AMPLITUDE_EVENTS.CreateOrganizationRedirect);
  };

  const closeForm = () => {
    setIsOpen(false);
  };

  const modifyOrganizationSuccess = () => {
    setIsOpen(false);
    queryClient.invalidateQueries({ queryKey: ['load_organizations', searchParams] });
  };

  const onEditOrganization = (item: OrganizationListItemType) => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.EditOrganizationRedirect);
    setOrganizationId(item.id);
    setIsOpen(true);
    setIsEditMode(true);
  };

  const organizations = data?.body?.results;
  const totalCount = data?.body?.count;
  const hasPaginator = totalCount > searchParams?.limit;
  const header = isEditMode ? 'Edit Organization' : 'Create Organization';
  const subHeader = isEditMode
    ? 'Edit your organization details.'
    : 'Please provide information for the new organization.';

  return (
    <ContentLayout
      title='Organization List'
      headerAction={
        <Button width={220} iconPosition='left' icon={<PlusIcon />} onClick={openCreateOrganizationForm} mobileStretch>
          Create Organization
        </Button>
      }
    >
      <PageBody>
        <OpcoOrganizationsTable items={organizations} onCellAction={onEditOrganization} />
      </PageBody>
      {hasPaginator && (
        <PageFooter>
          <Paginator page={searchParams.page} limit={searchParams.limit} total={totalCount} />
        </PageFooter>
      )}

      <Drawer isOpen={isOpen} header={header} subHeader={subHeader} onClose={closeForm}>
        <ModifyOrganizationForm
          organizationId={organizationId}
          isEditMode={isEditMode}
          onSuccess={modifyOrganizationSuccess}
          onCancel={closeForm}
          pricingTierList={pricingTierList?.body?.results}
        />
      </Drawer>
    </ContentLayout>
  );
};
