import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import { RequestModalMessage } from './styles';

export const RequestSentModal = () => {
  return (
    <Box width={'420px'} rowGap={'32px'} alignItems='center' flexDirection={'column'} justifyContent={'column'}>
      <RequestModalMessage>
        Your request is sent. Once authorized OpCoAdmin submits your request, you will be informed via email
        notification.
      </RequestModalMessage>
      <Button color='primary'>Ok</Button>
    </Box>
  );
};
