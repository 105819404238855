import { isNumber } from '@/helpers/objectHelpers';
import { useEffect, useState } from 'react';

const INTERVAL_DELAY = 1000;

export const useTimer = (initialSeconds: number, onFinish?: () => void) => {
  const [seconds, setSeconds] = useState<number>(null);

  useEffect(() => {
    setSeconds(initialSeconds);
  }, [initialSeconds]);

  useEffect(() => {
    if (!isNumber(seconds)) return;

    const intervalId = setInterval(() => {
      if (seconds === 0) {
        onFinish?.();
        clearInterval(intervalId);

        return;
      }

      setSeconds((value) => value - 1);
    }, INTERVAL_DELAY);

    return () => clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds]);

  return { seconds };
};
