import styled from 'styled-components';
import { poppinsMediumFont, subTitle } from '@/styles/typography';
import { COLORS } from '@/styles/colors';
import { media } from '@/styles/responsive';

export const WidgetWrapper = styled.div`
  width: 100%;
  border: 1px solid #bec2cc;
  border-radius: 20px;
  padding: 28px;
  position: relative;
  z-index: 1;

  ${media.mobile`
    padding: 16px;
  `}
`;

export const WidgetWrapperDarkStroke = styled(WidgetWrapper)`
  border: 1px solid #333539;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  padding: 28px 28px 36px;
`;

export const WidgetTitle = styled.div<{ marginBottom: number }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${poppinsMediumFont};
  ${subTitle};
  color: ${COLORS.grey[900]};
  margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};
`;

export const RotateIconBlock = styled.div`
  padding: 8px;
  border: 1px solid ${COLORS.grey[300]};
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    path {
      transition: fill 0.2s ease;
      stroke: ${COLORS.green[500]};
    }
  }
`;
