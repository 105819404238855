import styled from 'styled-components';
import { ellipsis } from '../../../styles/common';
import { flex } from '../../../styles/layout';
import {
  body,
  bodySmall,
  poppinsMediumFont,
  poppinsRegularFont,
  poppinsSemiBoldFont,
  subTitle,
} from '../../../styles/typography';
import { COLORS } from '../../../styles/colors';
import { GRADIENTS_NEW } from '../../../styles/gradients';

export const Row = styled.div<{ hasHighlight?: boolean; hasBorder?: boolean; direction?: 'column' | 'row' }>`
  ${flex({ justifyContent: 'space-between' })};
  ${bodySmall};
  margin-bottom: 12px;
  position: relative;
  z-index: 2;

  ${({ hasHighlight }) =>
    hasHighlight &&
    `
    background: ${GRADIENTS_NEW.green};
  `}

  ${({ hasBorder }) =>
    hasBorder &&
    `
    border-top: 1px solid ${COLORS.grey[400]};
    border-bottom: 1px solid ${COLORS.grey[400]};
    padding: 12px 0;
  `}

  ${({ direction }) =>
    direction &&
    `
    flex-direction: ${direction};
  `}

  &:last-child {
    margin-bottom: 0;
  }
`;

export const RowName = styled.div<{ isHeader?: boolean }>`
  ${flex({ alignItems: 'center' })};
  gap: 8px;
  flex-shrink: 0;
  margin-right: 25px;
  ${poppinsRegularFont};
  ${bodySmall};
  color: ${COLORS.grey[600]};

  ${({ isHeader }) =>
    isHeader &&
    `
    margin-bottom: 14px;
    ${poppinsMediumFont};
    ${body};
    color: ${COLORS.black};
  `}
`;

export const RowValue = styled.div<{ hasBorder?: boolean; direction: 'column' | 'row' }>`
  ${poppinsMediumFont};
  ${ellipsis};
  color: ${COLORS.black};

  ${({ hasBorder }) =>
    hasBorder &&
    `
    ${poppinsSemiBoldFont};
    ${body};
  `}

  ${({ direction }) =>
    direction === 'column' &&
    `
    ${subTitle};
  `}
`;
