import { Route } from 'react-router-dom';
import { PasswordChangedPage } from '../ForgotPasswordFlow/PasswordChangedPage/PasswordChangedPage';
import { getPasswordChangedPath } from '../ForgotPasswordFlow/PasswordChangedPage/urls/getPasswordChangedUrl';
import { LoginPage } from '@/bundle/Auth/LoginFlow/LoginPage/LoginPage';
import { getLoginPath } from '@/bundle/Auth/LoginFlow/LoginPage/urls/getLoginUrl';
import { TwoFAPage } from '@/bundle/Auth/LoginFlow/TwoFAPage/TwoFAPage';
import { getTwoFAPath } from '@/bundle/Auth/LoginFlow/TwoFAPage/urls/getTwoFAUrl';
import { getForgotPassowrdEmailSentPath } from '../ForgotPasswordFlow/ForgotPasswordEmailSentPage/urls/getForgotPasswordEmailSentUrl';
import { ForgotPasswordEmailSentPage } from '../ForgotPasswordFlow/ForgotPasswordEmailSentPage/ForgotPasswordEmailSentPage';
import { getCompleteRegistrationPath } from '@/bundle/Auth/InviteUserFlow/CompleteRegistrationPage/urls/getCompleteRegistrationUrl';
import { CompleteRegistrationPage } from '@/bundle/Auth/InviteUserFlow/CompleteRegistrationPage/CompleteRegistrationPage';
import { getPinCodePath } from '@/bundle/Auth/LoginFlow/CheckPinCodePage/urls/getPinCodeUrl';
import { CreatePinCodePage } from '@/bundle/Auth/InviteUserFlow/CreatePinCodePage/CreatePinCodePage';
import { getCreatePinCodePath } from '@/bundle/Auth/InviteUserFlow/CreatePinCodePage/urls/getCreatePinCodeUrl';
import { CheckPinCodePage } from '@/bundle/Auth/LoginFlow/CheckPinCodePage/CheckPinCodePage';
import { ForgotPasswordCheckEmailPage } from '../ForgotPasswordFlow/ForgotPasswordCheckEmailPage/ForgotPasswordCheckEmailPage';
import { getForgotPasswordCheckEmailPath } from '../ForgotPasswordFlow/ForgotPasswordCheckEmailPage/urls/getForgotPasswordCheckEmailUrl';
import { getForgotPasswordTwoFAPath } from '../ForgotPasswordFlow/ForgotPasswordTwoFAPage/urls/getForgotPasswordTwoFAUrl';
import { ForgotPasswordTwoFAPage } from '../ForgotPasswordFlow/ForgotPasswordTwoFAPage/ForgotPasswordTwoFAPage';
import { getForgotPasswordCompleteRegistrationPath } from '../ForgotPasswordFlow/ForgotPasswordCompleteRegistrationPage/urls/getForgotPasswordCompleteRegistrationUrl';
import { ForgotPasswordCompleteRegistrationPage } from '../ForgotPasswordFlow/ForgotPasswordCompleteRegistrationPage/ForgotPasswordCompleteRegistrationPage';
import { getForgotPasswordCheckPinCodePath } from '../ForgotPasswordFlow/ForgotPasswordCheckPinCodePage/urls/getForgotPasswordCheckPinCodeUrl';
import { ForgotPasswordCheckPinCodePage } from '../ForgotPasswordFlow/ForgotPasswordCheckPinCodePage/ForgotPasswordCheckPinCodePage';
import { AUTH_STEP, FORGOT_PASSWORD_STEP } from '../const/const';
import { AuthProtectedRoute } from '@/bundle/Routes/AuthProtectedRoute';
import { getResetRequestFromEmailPath } from '../InviteUserFlow/ResetRequestFromEmailPage/urls/getResetRequestFromEmailUrl';
import { ResetRequestFromEmailPage } from '../InviteUserFlow/ResetRequestFromEmailPage/ResetRequestFromEmailPage';
import { getResetRequestAuthPath } from '../InviteUserFlow/ResetRequestAuthPage/urls/getResetRequestAuthUrl';
import { ResetRequestAuthPage } from '../InviteUserFlow/ResetRequestAuthPage/ResetRequestAuthPage';
import { getSetPasswordLinkExpiredPath } from '../InviteUserFlow/SetPasswordLinkExpiredPage/urls/getSetPasswordLinkExpiredUrl';
import { SetPasswordLinkExpiredPage } from '../InviteUserFlow/SetPasswordLinkExpiredPage/SetPasswordLinkExpiredPage';
import { getLinkExpiredPath } from '../pages/LinkExpiredPage/urls/getLinkExpiredUrl';
import { LinkExpiredPage } from '../pages/LinkExpiredPage/LinkExpiredPage';
import { getSelectRolePath } from '../LoginFlow/SelectRolePage/urls/getSelectRoleUrl';
import { SelectRolePage } from '../LoginFlow/SelectRolePage/SelectRolePage';
import { getForgotPasswordSetPasswordPath } from '../ForgotPasswordFlow/ForgotPasswordSetPasswordPage/urls/getForgotPasswordSetPasswordUrl';
import { ForgotPasswordSetPasswordPage } from '../ForgotPasswordFlow/ForgotPasswordSetPasswordPage/ForgotPasswordSetPasswordPage';
import { getForgotPasswordValidateTotpPath } from '../ForgotPasswordFlow/ForgotPasswordValidateTotpPage/urls/getForgotPasswordValidateTotpUrl';
import { ForgotPasswordValidateTotpPage } from '../ForgotPasswordFlow/ForgotPasswordValidateTotpPage/ForgotPasswordValidateTotpPage';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { getForgotPasswordLinkExpiredPath, getForgotPasswordPasswordChangedPath } from '../ForgotPasswordFlow/const';
import { getSetInitialPasswordValidateTotpPath } from '../InviteUserFlow/SetInitialPasswordValidateTotpPage/urls/getSetInitialPasswordValidateTotpUrl';
import { SetInitialPasswordValidateTotpPage } from '../InviteUserFlow/SetInitialPasswordValidateTotpPage/SetInitialPasswordValidateTotpPage';
import { getSetInitialPasswordPath } from '@/bundle/Auth/InviteUserFlow/SetPasswordPage/urls/getSetInitialPasswordUrl';
import { LegacySetPasswordPage } from '@/bundle/Auth/InviteUserFlow/SetPasswordPage/LegacySetPasswordPage';
import { SetInitialPasswordPage } from '@/bundle/Auth/InviteUserFlow/SetPasswordPage/SetInitialPasswordPage';
import { getLegacySetPasswordPath } from '@/bundle/Auth/InviteUserFlow/SetPasswordPage/urls/getLegacySetPasswordUrl';
import { getPersonalDetailsVerificationPath } from '../LoginFlow/PersonalDetailsVerificationPage/urls/getPersonalDetailsVerificationUrl';
import { PersonalDetailsVerificationPage } from '../LoginFlow/PersonalDetailsVerificationPage/PersonalDetailsVerificationPage';

export const AuthRoutes = [
  // Login auth flow
  <Route key={getLoginPath()} path={getLoginPath()} element={<LoginPage />} />,
  <Route
    key={getTwoFAPath()}
    path={getTwoFAPath()}
    element={
      <AuthProtectedRoute allowedStep={AUTH_STEP.TWO_FACTOR_AUTHENTICATION}>
        <TwoFAPage />
      </AuthProtectedRoute>
    }
  />,
  <Route
    key={getPinCodePath()}
    path={getPinCodePath()}
    element={
      <AuthProtectedRoute allowedStep={AUTH_STEP.PIN_AUTHENTICATION}>
        <CheckPinCodePage />
      </AuthProtectedRoute>
    }
  />,
  <Route key={getSelectRolePath()} path={getSelectRolePath()} element={<SelectRolePage />} />,
  <Route
    key={getPersonalDetailsVerificationPath()}
    path={getPersonalDetailsVerificationPath()}
    element={
      <AuthProtectedRoute allowedStep={AUTH_STEP.SET_NAME_VERIFICATION}>
        <PersonalDetailsVerificationPage />
      </AuthProtectedRoute>
    }
  />,
  // End of Login auth flow

  // Invite user auth flow
  <Route
    key={getSetInitialPasswordValidateTotpPath()}
    path={getSetInitialPasswordValidateTotpPath()}
    element={<SetInitialPasswordValidateTotpPage />}
  />,
  <Route key={getSetInitialPasswordPath()} path={getSetInitialPasswordPath()} element={<SetInitialPasswordPage />} />,
  <Route key={getLegacySetPasswordPath()} path={getLegacySetPasswordPath()} element={<LegacySetPasswordPage />} />,
  <Route
    key={getResetRequestFromEmailPath()}
    path={getResetRequestFromEmailPath()}
    element={<ResetRequestFromEmailPage />}
  />,
  <Route key={getResetRequestAuthPath()} path={getResetRequestAuthPath()} element={<ResetRequestAuthPage />} />,
  <Route
    key={getCompleteRegistrationPath()}
    path={getCompleteRegistrationPath()}
    element={
      <AuthProtectedRoute allowedStep={AUTH_STEP.COMPLETE_REGISTRATION}>
        <CompleteRegistrationPage />
      </AuthProtectedRoute>
    }
  />,
  <Route
    key={getCreatePinCodePath()}
    path={getCreatePinCodePath()}
    element={
      <AuthProtectedRoute allowedStep={AUTH_STEP.SET_PIN}>
        <CreatePinCodePage />
      </AuthProtectedRoute>
    }
  />,
  // End of Invite user auth flow

  // Forgot password flow
  <Route
    key={getForgotPasswordCheckEmailPath()}
    path={getForgotPasswordCheckEmailPath()}
    element={<ForgotPasswordCheckEmailPage />}
  />,
  <Route
    key={getForgotPasswordValidateTotpPath()}
    path={getForgotPasswordValidateTotpPath()}
    element={<ForgotPasswordValidateTotpPage />}
  />,
  <Route
    key={getForgotPasswordTwoFAPath()}
    path={getForgotPasswordTwoFAPath()}
    element={
      <AuthProtectedRoute allowedStep={FORGOT_PASSWORD_STEP.TWO_FACTOR_AUTHENTICATION}>
        <ForgotPasswordTwoFAPage />
      </AuthProtectedRoute>
    }
  />,
  <Route
    key={getForgotPasswordCompleteRegistrationPath()}
    path={getForgotPasswordCompleteRegistrationPath()}
    element={
      <AuthProtectedRoute allowedStep={FORGOT_PASSWORD_STEP.COMPLETE_REGISTRATION}>
        <ForgotPasswordCompleteRegistrationPage />
      </AuthProtectedRoute>
    }
  />,
  <Route
    key={getForgotPasswordCheckPinCodePath()}
    path={getForgotPasswordCheckPinCodePath()}
    element={
      <AuthProtectedRoute allowedStep={FORGOT_PASSWORD_STEP.PIN_AUTHENTICATION}>
        <ForgotPasswordCheckPinCodePage />
      </AuthProtectedRoute>
    }
  />,
  <Route
    key={getForgotPasswordSetPasswordPath()}
    path={getForgotPasswordSetPasswordPath()}
    element={
      <AuthProtectedRoute allowedStep={FORGOT_PASSWORD_STEP.SET_PASSWORD}>
        <ForgotPasswordSetPasswordPage />
      </AuthProtectedRoute>
    }
  />,
  <Route
    key={getForgotPassowrdEmailSentPath()}
    path={getForgotPassowrdEmailSentPath()}
    element={<ForgotPasswordEmailSentPage />}
  />,
  <Route
    key={getForgotPasswordLinkExpiredPath()}
    path={getForgotPasswordLinkExpiredPath()}
    element={
      <SetPasswordLinkExpiredPage
        onRedirect={() => amplitudeService.logEvent(AMPLITUDE_EVENTS.ForgotPasswordLinkExpired)}
      />
    }
  />,
  <Route
    key={getForgotPasswordPasswordChangedPath()}
    path={getForgotPasswordPasswordChangedPath()}
    element={
      <PasswordChangedPage
        onRedirect={() => amplitudeService.logEvent(AMPLITUDE_EVENTS.ForgotPasswordSetPasswordSuccessLoginRedirect)}
      />
    }
  />,
  // End of Forgot password flow

  // Informational pages routes
  <Route
    key={getPasswordChangedPath()}
    path={getPasswordChangedPath()}
    element={
      <PasswordChangedPage onRedirect={() => amplitudeService.logEvent(AMPLITUDE_EVENTS.SetPasswordRedirectSignIn)} />
    }
  />,
  <Route
    key={getSetPasswordLinkExpiredPath()}
    path={getSetPasswordLinkExpiredPath()}
    element={<SetPasswordLinkExpiredPage />}
  />,
  <Route key={getLinkExpiredPath()} path={getLinkExpiredPath()} element={<LinkExpiredPage />} />,

  // End of Informational pages routes
];
