import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { FormGeneratorNew } from '@/components/form/FormGeneratorNew/FormGeneratorNew';
import { getMergedValues, getValidationSchema } from '@/helpers/formHelpers/formHelpers';
import { Button } from '@/components/Button/Button';
import { ButtonsWrapper } from '@/bundle/_Opco/ui/ModifyOrganizationForm/styles';
import { OrganizationUserType } from '@/bundle/_Opco/types/types';
import { useMutation } from '@tanstack/react-query';
import { editOrganizationUserDetails } from '@/bundle/_Opco/ui/OrganizationUsers/Details/api';
import { EditOrganizationUserDetailsPayloadType } from '@/api/v1/opco/organizations/editOrganizationUserApi';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { Notification } from '@/components/Notification/Notification';
import { EDIT_PERSONAL_DETAILS_FORM_CONFIG } from '@/bundle/pages/ProfilePage/ui/const';

interface EditPersonalDetailsFormType {
  user: OrganizationUserType;
  onClose: () => void;
  onSuccess: () => void;
}

export const EditPersonalDetailsForm = ({ user, onClose, onSuccess }: EditPersonalDetailsFormType) => {
  const { organizationId, userId } = useParams<{ organizationId: string; userId: string }>();

  const { mutate, data, isPending } = useMutation({
    mutationKey: ['edit_organization_user_details', organizationId, userId],
    mutationFn: (values: EditOrganizationUserDetailsPayloadType) => {
      return editOrganizationUserDetails(organizationId, userId, values);
    },

    onSuccess: (editOrganizationUserDetailsResponse) => {
      if (editOrganizationUserDetailsResponse.error) return;

      onSuccess();
    },
  });

  const formik = useFormik({
    initialValues: getMergedValues(EDIT_PERSONAL_DETAILS_FORM_CONFIG, user),
    validationSchema: getValidationSchema(EDIT_PERSONAL_DETAILS_FORM_CONFIG),
    onSubmit: async (values: EditOrganizationUserDetailsPayloadType) => {
      await mutate(values);
    },
  });

  const formError = getResponseError(data?.error);

  return (
    <>
      {formError && (
        <Notification variant='error' mb='24px'>
          {formError}
        </Notification>
      )}
      <FormikForm value={formik}>
        <FormGeneratorNew config={EDIT_PERSONAL_DETAILS_FORM_CONFIG} apiError={data?.error} />
        <ButtonsWrapper>
          <Button type='button' color='secondary' onClick={onClose}>
            Cancel
          </Button>
          <Button type='submit' isLoading={isPending}>
            Save
          </Button>
        </ButtonsWrapper>
      </FormikForm>
    </>
  );
};
