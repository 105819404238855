import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import { RequestModalMessage } from './styles';

export const RequestResetAccountModal = () => {
  return (
    <Box width={'420px'} rowGap={'16px'} flexDirection={'column'} alignItems={'center'}>
      <RequestModalMessage>
        Your request will be sent to WireVault OpCo Admins. When authorized OpCo Admin submits your request, you will
        need to pass the onboarding procedure once again.
      </RequestModalMessage>
      <div>Do you want to continue?</div>
      <Box columnGap={'20px'} flexDirection={'row'} width={'100%'}>
        <Button color={'secondary'}>No</Button>
        <Button color={'primary'}>Yes</Button>
      </Box>
    </Box>
  );
};
