import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useFormik } from 'formik';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { Button } from '@/components/Button/Button';
import { ButtonsWrapper } from '@/bundle/_Opco/ui/ModifyOrganizationForm/styles';
import { OrganizationUserType } from '@/bundle/_Opco/types/types';
import { REQUIRED_PHONE_NUMBER_VALIDATOR } from '@/helpers/formHelpers/validators';
import { FormikPhoneInput } from '@/components/form/fields/FormikPhoneInput/FormikPhoneInput';

import { CheckPinCodeModal } from '@/bundle/shared/components/CheckPinCodeModal/CheckPinCodeModal';
import { PIN_TOKEN_EVENT_SCOPE } from '@/const/shared';
import { editOrganizationUserMobilePhone } from '@/bundle/_Opco/ui/OrganizationUsers/Details/api';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { Notification } from '@/components/Notification/Notification';

interface EditPhoneNumberFormType {
  mobilePhone: OrganizationUserType['mobile_phone'];
  onClose: () => void;
  onSuccess: () => void;
}

const validationSchema = Yup.object().shape({
  mobile_phone: REQUIRED_PHONE_NUMBER_VALIDATOR,
});

export const EditMobilePhoneForm = ({ onClose, mobilePhone, onSuccess }: EditPhoneNumberFormType) => {
  const { organizationId, userId } = useParams<{ organizationId: string; userId: string }>();
  const [isPinCodeOpen, setIsPinCodeOpen] = useState(false);

  const closePinCode = () => {
    setIsPinCodeOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      mobile_phone: mobilePhone,
    },
    validationSchema,
    enableReinitialize: false,
    onSubmit: () => {
      setIsPinCodeOpen(true);
    },
  });

  const { data, mutate, isPending } = useMutation({
    mutationKey: ['edit_mobile_phone', organizationId, userId],
    mutationFn: (pinCode: string) => {
      return editOrganizationUserMobilePhone(organizationId, userId, formik.values?.mobile_phone, pinCode);
    },

    onSuccess: (editMobilePhoneResponse) => {
      if (editMobilePhoneResponse.error) return;

      onSuccess();
    },
  });

  const checkPinCodeSuccess = (pinToken: string) => {
    mutate(pinToken);
    closePinCode();
  };

  const submitFormOnEnter = () => {
    formik.handleSubmit();
  };

  const formError = getResponseError(data?.error);

  return (
    <>
      {formError && (
        <Notification variant='error' mb='24px'>
          {formError}
        </Notification>
      )}
      <FormikForm value={formik}>
        <FormikPhoneInput
          name='mobile_phone'
          label='Mobile Phone Number*'
          placeholder='Enter Mobile Phone'
          apiError={data?.error}
          onEnterKeyPress={submitFormOnEnter}
        />
        <ButtonsWrapper>
          <Button type='button' color='secondary' onClick={onClose}>
            Cancel
          </Button>
          <Button type='submit' isLoading={isPending}>
            Save
          </Button>
        </ButtonsWrapper>
      </FormikForm>
      {isPinCodeOpen && (
        <CheckPinCodeModal
          isOpen={isPinCodeOpen}
          onSuccess={checkPinCodeSuccess}
          onClose={closePinCode}
          eventScope={PIN_TOKEN_EVENT_SCOPE.EDIT_ORG_ADMIN}
        />
      )}
    </>
  );
};
