import { OpcoUserStatus } from '@/bundle/_Opco/_OpcoUsersPage/ui/OpcoUserStatus/OpcoUserStatus';
import { UserRole } from '../../../shared/UserRole/UserRole';
import { ROLE_LABEL_MAP } from '../../../shared/const';
import { dateTimeUTCFormatter } from '@/helpers/formatHelpers';
import { FULL_DATE_TIME_FORMAT } from '@/helpers/dateHelpers/const';

export const ORGANIZATION_USER_STATUS = {
  ACTIVE: 'active',
  BLOCKED: 'blocked',
  INVITED: 'invited',
} as const;

export const ORGANIZATIONS_USERS_TABLE_CONFIG = [
  {
    key: 1,
    name: 'user__email,-user__date_created',
    header: 'EMAIL ADDRESS',
    width: '20%',
    hasSort: true,
    hasTitle: true,
    getValue({ email }) {
      return email;
    },
  },
  {
    key: 2,
    name: 'user__first_name,-user__date_created',
    header: 'FIRST NAME',
    width: '20%',
    hasSort: true,
    hasTitle: true,
    getValue({ first_name }) {
      return first_name || '-';
    },
  },
  {
    key: 3,
    name: 'user__last_name,-user__date_created',
    header: 'LAST NAME',
    width: '20%',
    hasSort: true,
    hasTitle: true,
    getValue({ last_name }) {
      return last_name || '-';
    },
  },
  {
    key: 4,
    name: 'role',
    header: 'ROLE',
    width: '15%',
    getValue({ role }) {
      const formattedRole = ROLE_LABEL_MAP[role] || role;

      return <UserRole role={formattedRole} />;
    },
  },
  {
    key: 5,
    name: 'user_status,-user__date_created',
    header: 'STATUS',
    width: '10%',
    hasSort: true,
    getValue({ status }) {
      return <OpcoUserStatus status={status} />;
    },
  },
  {
    key: 6,
    name: 'user__last_login,-user__date_created',
    header: 'LAST LOGIN',
    width: '15%',
    hasSort: true,
    hasTooltip: true,
    hasTitle: true,
    getValue({ last_login }) {
      return dateTimeUTCFormatter(last_login, FULL_DATE_TIME_FORMAT);
    },
  },
];
