import { ApiParams, prepareListApiParams } from '@/api/helpers/urlApiHelpers';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { getAuthTokenHeaders } from '@/helpers/apiHelpers/queryHelper';
import { OrganizationCreateUserType } from '@/bundle/_Opco/types/types';
import { getUserAddingStatusApi } from '@/api/v1/opco/organizations/getUserAddingStatusApi';
import { getAddedUserDetailsApi } from '@/api/v1/opco/getAddedUserDetailsApi';
import { addOrgAdminRoleApi } from '@/api/v1/opco/organizations/addOrgAdminRoleApi';
import { getOrganizationUsersApi } from '@/api/v1/opco/organizations/getOrganizationUsersApi';
import { createOrganizationUserApi } from '@/api/v1/opco/organizations/createOrganizationUserApi';
import { getOrganizationSearchUserApi } from '@/api/v1/opco/organizations/getOrganizationSearchUserApi';

export const loadOrganizationUsers = async (id: string, params: ApiParams) => {
  const apiParams = prepareListApiParams(params);

  try {
    const response = await getOrganizationUsersApi(id, apiParams);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const createOrgAdminUser = async (
  organizationId: string,
  payload: OrganizationCreateUserType,
  pinToken: string
) => {
  const options = getAuthTokenHeaders(pinToken);

  try {
    const response = await createOrganizationUserApi(organizationId, payload, null, options);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const searchUsers = async (search: string) => {
  const payload = {
    search,
  };

  try {
    const response = await getOrganizationSearchUserApi(payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const addedUserAssigmentStatus = async (userId: string) => {
  try {
    const response = await getUserAddingStatusApi(userId);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const addingOrgAdminRole = async (organizationId: string, userId: string, pinToken: string) => {
  const options = getAuthTokenHeaders(pinToken);
  const payload = {
    user_id: userId,
  };

  try {
    const response = await addOrgAdminRoleApi(organizationId, payload, options);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const loadAddedUserDetails = async (userId: string) => {
  try {
    const response = await getAddedUserDetailsApi(userId);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
