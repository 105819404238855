import { FC, ReactNode } from 'react';
import { useField } from 'formik';
import { CustomSelectInputWrapper, FormikSelectWrapper } from './styles';
import { LabelWrapper, Label } from '../FormikInput/styles';
import { CustomSelect, SelectVariantType } from '../../../Select/CustomSelect';
import { InputError } from '../InputError/InputError';
import { getFieldError } from '@/helpers/formHelpers/formHelpers';

type FormikSelectType = {
  name?: string;
  label?: string;
  placeholder?: string;
  options: any;
  errorsApi?: any;
  variant?: SelectVariantType;
  disabled?: boolean;
  inputVariant?: string;
  sizeVariant?: any;
  postfixLabel?: ReactNode;
};

export const FormikSelect: FC<FormikSelectType> = ({
  name,
  label,
  placeholder,
  options,
  errorsApi,
  variant = 'single',
  disabled,
  inputVariant = 'default',
  sizeVariant = 'default',
  postfixLabel,
}) => {
  const [field, meta, { setValue }] = useField(name);
  const { error, apiError, hasAnyError } = getFieldError(name, meta, errorsApi);

  const onChangeHandler = (value: any) => {
    return setValue(value);
  };

  return (
    <FormikSelectWrapper>
      <LabelWrapper>
        <Label>{label}</Label>
        {postfixLabel && postfixLabel}
      </LabelWrapper>
      <CustomSelectInputWrapper>
        <CustomSelect
          {...field}
          placeholder={placeholder}
          options={options || options?.items}
          variant={variant}
          onChange={onChangeHandler}
          disabled={disabled}
          hasError={hasAnyError}
          inputVariant={inputVariant}
          sizeVariant={sizeVariant}
        />
      </CustomSelectInputWrapper>
      <InputError error={error} apiError={apiError} />
    </FormikSelectWrapper>
  );
};
