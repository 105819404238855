import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AssignedWireType } from '@/types/wireTypes';
import {
  INVITE_RECIPIENT_OPTION,
  PROVIDE_PAYMENT_DETAILS_FOR_RECIPIENT_OPTION,
  ProxyProvidePaymentDetailsOptionType,
} from '../ProxyChoosePaymentDetailsProvider/const';
import { RecipientProvidePaymentDetailsForm } from '../RecipientProvidePaymentDetailsForm/RecipientProvidePaymentDetailsForm';
import { ProxyChoosePaymentDetailsProvider } from '../ProxyChoosePaymentDetailsProvider/ProxyChoosePaymentDetailsProvider';
import { ReactComponent as RecipientIcon } from '@/images/RecipientIcon.svg';
import { ReactComponent as RecipientProxyIcon } from '@/images/ProxyIcon.svg';
import { ProxyAssignRecipientForm } from '../ProxyAssignRecipientForm/ProxyAssignRecipientForm';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { PaymentDetailsHeaderSlot } from '@/bundle/shared/components/PaymentDetailsHeaderSlot/PaymentDetailsHeaderSlot';
import { TrustStampResponseStatusType } from '@/api/v1/recipient/getRecipientTrustStampStatusApi';
import {
  PROVIDE_PAYMENT_DETAILS_STATE,
  ProvidePaymentDetailsStateType,
  getInitialPaymentDetailsState,
  isProxyAssignRecipientState,
  isProxyProvidePaymentDetailsState,
  isProxyProvideReplacementPaymentDetailsState,
  isProxySelectProviderState,
  isRecipientProvidePaymentDetailsState,
  isRecipientSourceWirePaymentDetailsConfirmState,
} from './helpers';
import { ChangeButton } from './styles';
import { ProvidePaymentDetailsTimer } from '../ProvidePaymentDetailsTimer/ProvidePaymentDetailsTimer';
import { DocumentVerificationPaymentDetailsGuard } from '../RecipientDocumentVerification/DocumentVerificationPaymentDetailsGuard/DocumentVerificationPaymentDetailsGuard';
import { useUser } from '@/context/userContext';
import { RecipientSourceWirePaymentDetails } from '../RecipientSourceWirePaymentDetails/RecipientSourceWirePaymentDetails';

type RecipientProvidePaymentDetailsType = {
  wire: AssignedWireType;
  trustStampDetails: TrustStampResponseStatusType;
  isLoadingTrustStampDetails?: boolean;
  onRefetchWire: () => void;
  onStartTrustStamp: () => void;
  onResetTrustStampNextAttemptTimer: () => void;
  onResetProvidePaymentDetailsTimer: () => void;
};

export const RecipientProvidePaymentDetails = ({
  wire,
  trustStampDetails,
  isLoadingTrustStampDetails,
  onRefetchWire,
  onStartTrustStamp,
  onResetTrustStampNextAttemptTimer,
  onResetProvidePaymentDetailsTimer,
}: RecipientProvidePaymentDetailsType) => {
  const { user } = useUser();
  const { id } = useParams<{ id: string }>();
  const [providePaymentDetailsState, setProvidePaymentDetailsState] = useState<ProvidePaymentDetailsStateType>(null);
  const [paymentDetailsProviderOption, setPaymentDetailsProviderOption] =
    useState<ProxyProvidePaymentDetailsOptionType>(null);
  const userId = user?.id;
  const isDocumentVerificationRequired = wire?.is_pd_provider_kyc_required;
  const expiredPaymentDetailsTime = trustStampDetails?.provide_pd_disable_after_sec;
  const hasSourceWirePaymentDetails = !!wire?.payment_details_source_wire?.id;

  // Reset provide payment details state when navigating between child and parent wires(replacement wires)
  useEffect(() => {
    setProvidePaymentDetailsState(null);
  }, [id]);

  // Set initial provide payment details state
  useEffect(() => {
    const newState = getInitialPaymentDetailsState(wire, userId, trustStampDetails, providePaymentDetailsState);

    if (newState !== providePaymentDetailsState) {
      setProvidePaymentDetailsState(newState);
    }
  }, [wire, userId, trustStampDetails, providePaymentDetailsState]);

  const selectPaymentDetailsProviderOption = (option: ProxyProvidePaymentDetailsOptionType) => {
    setPaymentDetailsProviderOption(option);
  };

  const confirmPaymentDetailsProviderOption = () => {
    if (paymentDetailsProviderOption === INVITE_RECIPIENT_OPTION) {
      setProvidePaymentDetailsState(PROVIDE_PAYMENT_DETAILS_STATE.RECIPIENT_PROXY_ASSIGN_RECIPIENT);

      amplitudeService.logEvent(AMPLITUDE_EVENTS.OutboundWirePaymentProviderInviteRecipient);

      return;
    }

    if (paymentDetailsProviderOption === PROVIDE_PAYMENT_DETAILS_FOR_RECIPIENT_OPTION) {
      setProvidePaymentDetailsState(PROVIDE_PAYMENT_DETAILS_STATE.RECIPIENT_PROXY_PROVIDE_PAYMENT_DETAILS);

      amplitudeService.logEvent(AMPLITUDE_EVENTS.OutboundWirePaymentProviderProvideDetails);

      return;
    }
  };

  const providePaymentDetails = () => {
    setProvidePaymentDetailsState(PROVIDE_PAYMENT_DETAILS_STATE.RECIPIENT_PAYMENT_DETAILS_PROVIDED);
  };

  const changeProvidePaymentDetailsMode = () => {
    setPaymentDetailsProviderOption(null);
    setProvidePaymentDetailsState(PROVIDE_PAYMENT_DETAILS_STATE.RECIPIENT_PROXY_SELECT_PROVIDER);
  };

  const closeAssignRecipientForm = () => {
    onRefetchWire();
  };

  const openProvidePaymentDetailsForm = () => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.OutboundWireSourceWireProvidePaymentDetailsRedirect);
    setProvidePaymentDetailsState(PROVIDE_PAYMENT_DETAILS_STATE.RECIPIENT_PROVIDE_PAYMENT_DETAILS);
  };

  const isRecipientProvidePaymentDetails = isRecipientProvidePaymentDetailsState(providePaymentDetailsState);
  const isRecipientSourceWirePaymentDetailsConfirm =
    isRecipientSourceWirePaymentDetailsConfirmState(providePaymentDetailsState);
  const isProxySelectProvider = isProxySelectProviderState(providePaymentDetailsState);
  const isProxyProvidePaymentDetails = isProxyProvidePaymentDetailsState(providePaymentDetailsState);
  const isProxyAssignRecipient = isProxyAssignRecipientState(providePaymentDetailsState);
  const isProxyProvideReplacementPaymentDetails =
    isProxyProvideReplacementPaymentDetailsState(providePaymentDetailsState);
  const showProxyProvidePaymentDetails = isProxyProvidePaymentDetails || isProxyProvideReplacementPaymentDetails;
  const showProvidePaymentDetailsForm =
    isRecipientProvidePaymentDetails || showProxyProvidePaymentDetails || isRecipientSourceWirePaymentDetailsConfirm;

  return (
    <>
      {isProxySelectProvider && (
        <ProxyChoosePaymentDetailsProvider
          value={paymentDetailsProviderOption}
          onSelect={selectPaymentDetailsProviderOption}
          onConfirm={confirmPaymentDetailsProviderOption}
        />
      )}
      {isProxyAssignRecipient && (
        <ProxyAssignRecipientForm
          onAssignRecipient={closeAssignRecipientForm}
          onCreateRecipient={closeAssignRecipientForm}
          onChangeProvidePaymentDetailsMode={changeProvidePaymentDetailsMode}
          isDocumentVerificationRequired={isDocumentVerificationRequired}
        />
      )}

      {showProvidePaymentDetailsForm && (
        <DocumentVerificationPaymentDetailsGuard
          wire={wire}
          providePaymentDetailsState={providePaymentDetailsState}
          trustStampDetails={trustStampDetails}
          isLoading={isLoadingTrustStampDetails}
          onStartTrustStamp={onStartTrustStamp}
          onResetTrustStampNextAttemptTimer={onResetTrustStampNextAttemptTimer}
          onChangeProvidePaymentDetailsMode={changeProvidePaymentDetailsMode}
        >
          <>
            {isRecipientSourceWirePaymentDetailsConfirm && (
              <RecipientSourceWirePaymentDetails
                wire={wire}
                onProvidePaymentDetails={openProvidePaymentDetailsForm}
                trustStampDetails={trustStampDetails}
                onResetTrustStampNextAttemptTimer={onResetTrustStampNextAttemptTimer}
              />
            )}

            {isRecipientProvidePaymentDetails && (
              <RecipientProvidePaymentDetailsForm
                wire={wire}
                autofillNames
                headlineSlot={
                  <PaymentDetailsHeaderSlot
                    icon={<RecipientIcon />}
                    header='I’m the Recipient'
                    meta={
                      hasSourceWirePaymentDetails && (
                        <ChangeButton
                          onClick={() => {
                            setProvidePaymentDetailsState(
                              PROVIDE_PAYMENT_DETAILS_STATE.RECIPIENT_SOURCE_WIRE_PAYMENT_DETAILS_CONFIRM
                            );
                          }}
                        >
                          Change
                        </ChangeButton>
                      )
                    }
                  />
                }
                onProvidePaymentDetails={providePaymentDetails}
                actionSlot={
                  isDocumentVerificationRequired && (
                    <ProvidePaymentDetailsTimer
                      expiredPaymentDetailsTime={expiredPaymentDetailsTime}
                      onResetProvidePaymentDetailsTimer={onResetProvidePaymentDetailsTimer}
                    />
                  )
                }
              />
            )}

            {showProxyProvidePaymentDetails && (
              <RecipientProvidePaymentDetailsForm
                wire={wire}
                headlineSlot={
                  <PaymentDetailsHeaderSlot
                    icon={<RecipientProxyIcon />}
                    header='Provide wire info for the Recipient'
                    meta={
                      isProxyProvidePaymentDetails &&
                      !isDocumentVerificationRequired && (
                        <ChangeButton onClick={changeProvidePaymentDetailsMode}>Change</ChangeButton>
                      )
                    }
                  />
                }
                onProvidePaymentDetails={providePaymentDetails}
                actionSlot={
                  isDocumentVerificationRequired && (
                    <ProvidePaymentDetailsTimer
                      expiredPaymentDetailsTime={expiredPaymentDetailsTime}
                      onResetProvidePaymentDetailsTimer={onResetProvidePaymentDetailsTimer}
                    />
                  )
                }
              />
            )}
          </>
        </DocumentVerificationPaymentDetailsGuard>
      )}
    </>
  );
};
