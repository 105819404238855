import styled from 'styled-components';
import { bodySmall, poppinsRegularFont } from '../../styles/typography';
import { COLORS } from '../../styles/colors';
import { VARIANT_HIGHLIGHT } from './const';

const BACKGROUND_VARIANT_MAP = {
  [VARIANT_HIGHLIGHT.BLUE]: `
    background: ${COLORS.blue[200]};
    color: ${COLORS.blue[500]};
  `,
  [VARIANT_HIGHLIGHT.LIGHT_PURPLE]: `
    background: ${COLORS.purple[100]};
    color: ${COLORS.purple[500]};
  `,
  [VARIANT_HIGHLIGHT.PURPLE]: `
    background: ${COLORS.violet[200]};
    color: ${COLORS.violet[500]};
  `,
  [VARIANT_HIGHLIGHT.YELLOW]: `
    background: #fff3dd;
    color: #ff7a20;
  `,
  [VARIANT_HIGHLIGHT.GREEN]: `
    background: ${COLORS.green[300]};
    color: #00692a;
  `,
  [VARIANT_HIGHLIGHT.RED]: `
    background: ${COLORS.red[100]};
    color: ${COLORS.red[500]};
  `,
  [VARIANT_HIGHLIGHT.ROSE]: `
    background: #fff0ff;
    color: #8e0057;
  `,
  [VARIANT_HIGHLIGHT.DARK_BLUE]: `
    background: ${COLORS.blue[250]};
    color: ${COLORS.blue[700]};
  `,
  [VARIANT_HIGHLIGHT.LIGHT_BLUE]: `
    background: ${COLORS.light_ocean};
    color: ${COLORS.deep_blue[500]};
  `,
  [VARIANT_HIGHLIGHT.DARK_ROSE]: `
    background: ${COLORS.purple[100]};
    color: ${COLORS.purple[500]};
  `,
  [VARIANT_HIGHLIGHT.GREY]: `
    background: ${COLORS.grey[200]};
    color: ${COLORS.grey[700]};
  `,
};

export const StatusWrapper = styled.div<{ variant: string }>`
  padding: 4px 8px;
  border-radius: 10px;
  width: max-content;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
  ${bodySmall};
  ${poppinsRegularFont};
  ${({ variant }) => BACKGROUND_VARIANT_MAP[variant] || VARIANT_HIGHLIGHT.GREY};
`;
