import { ContentLayout } from '../../Layouts/ContentLayout/ContentLayout';
import { Paginator } from '../../../components/Paginator/Paginator';
import { PageBody } from '../../../components/Page/PageBody/PageBody';
import { FilterList } from './ui/FilterList/FilterList';
import { PageFooter } from '../../../components/Page/PageFooter/PageFooter';
import { BilledEventsTable } from './BilledEventsTable';
import { getBilledEventsList, getOrganizationOptions } from './helpers';
import { BilledEventsType } from './types';
import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';
import { loadBilledEvents, downloadBillingFile } from './api';
import { loadOrganizations } from '../api';
import { filterListConfig } from './hooks/filterListConfig';
import { format, lastDayOfMonth, parseISO, subMonths } from 'date-fns';
import { REVERSED_DEFAULT_DATE_FORMAT } from '@/helpers/dateHelpers/const';
import { usePrepareQueryParams } from '@/helpers/apiHelpers/queryHelper';
import { useEffect } from 'react';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { showSnackbar } from '@/components/Snackbar/Snackbar';
import { Button } from '@/components/Button/Button';
import { FilterParamsType } from '@/api/helpers/queryHelpers';
import { saveAs } from 'file-saver';

const ORGANIZATION_LIMIT = 1000;

const getRangeDate = () => {
  const currentDate = new Date();
  const previousMonth = subMonths(currentDate, 1);

  const startDateFormatted = format(previousMonth, 'yyyy-MM-01');
  const endDateFormatted = format(lastDayOfMonth(previousMonth), REVERSED_DEFAULT_DATE_FORMAT);

  return {
    startDateFormatted,
    endDateFormatted,
  };
};

const getFilters = (filters: FilterParamsType) => {
  if (filters?.date_created_after || filters?.date_created_before) return filters;

  const dateRange = getRangeDate();

  const dateFilters = {
    date_created_after: dateRange.startDateFormatted,
    date_created_before: dateRange.endDateFormatted,
  };

  return { ...filters, ...dateFilters };
};

const getFormattedFilters = (filters: FilterParamsType) => {
  return {
    ...filters,
    date_created_after: parseISO(filters.date_created_after),
    date_created_before: parseISO(filters.date_created_before),
  };
};

export const BilledEventsPage = () => {
  const queryParams = usePrepareQueryParams();
  const filters = getFilters(queryParams?.filters);

  const { data: billedEventsData } = useQuery({
    queryKey: ['load_billed_events', queryParams],
    queryFn: () =>
      loadBilledEvents({
        ...queryParams,
        filters,
      }),
    placeholderData: keepPreviousData,
  });

  const { data: organizationsData } = useQuery({
    queryKey: ['load_organizations'],
    queryFn: () => loadOrganizations({ limit: ORGANIZATION_LIMIT }),
    placeholderData: keepPreviousData,
  });

  const { mutate, isPending } = useMutation({
    mutationKey: ['download_billing_file', filters],
    mutationFn: () => {
      const payload = {
        ...filters,
        ordering: '-date_created',
      };

      return downloadBillingFile(payload);
    },
    onSuccess: (response) => {
      if (response?.error) {
        const error = getResponseError(response?.error);

        showSnackbar(error, { variant: 'error' });

        return;
      }

      const headers = response.headers.contentDisposition;
      const filename = headers.replace('attachment; filename=', '') || 'Billing.xlsx';

      saveAs(response?.body, filename);
    },
  });

  useEffect(() => {
    const billedEventsError = billedEventsData?.error;

    if (!billedEventsError) return;

    const error = getResponseError(billedEventsError) || getResponseError(billedEventsError, 'date_created');

    showSnackbar(error, { variant: 'error', autoClose: false });
  }, [billedEventsData?.error]);

  const billedEvents = billedEventsData?.body?.results;
  const billedEventsCount = billedEventsData?.body?.count;
  const organizations = organizationsData?.body?.results;

  const organizationsOptions = getOrganizationOptions(organizations);
  const filterConfig = filterListConfig(organizationsOptions);

  const billedEventsList: BilledEventsType[] = getBilledEventsList(billedEvents);
  const hasPaginator = billedEventsCount > queryParams?.limit;
  const formattedFilters = getFormattedFilters(filters);

  return (
    <ContentLayout
      title='Billing'
      subtitle={
        <>
          Please choose the wires that were viewed by an administrator during the selected time period.
          <br />
          By default wires that were viewed in the previous billing period are displayed. This wires will be included in
          the report.
        </>
      }
      headerAction={
        <Button width={140} onClick={mutate} mobileStretch disabled={isPending || !billedEventsCount}>
          {isPending ? '...' : 'Export'}
        </Button>
      }
    >
      <PageBody>
        <FilterList filterConfig={filterConfig} initialValues={formattedFilters} />
        <BilledEventsTable items={billedEventsList} />
      </PageBody>
      {hasPaginator && (
        <PageFooter>
          <Paginator page={queryParams.page} limit={queryParams.limit} total={billedEventsCount} />
        </PageFooter>
      )}
    </ContentLayout>
  );
};
