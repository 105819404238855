import { ContentLayout } from '@/bundle/Layouts/ContentLayout/ContentLayout';
import { Paginator } from '@/components/Paginator/Paginator';
import { PageBody } from '@/components/Page/PageBody/PageBody';
import { useNavigate, useParams } from 'react-router-dom';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { PageFooter } from '@/components/Page/PageFooter/PageFooter';
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { loadOrganizationUsers } from './api';
import { OrganizationUsersTable } from './ui/OrganizationUsersTable';
import { loadOrganization } from '@/bundle/_Opco/api';
import { useState } from 'react';
import { Drawer } from '@/components/Drawer/Drawer';
import { CreateOrgAdminForm } from './ui/CreateOrgAdminForm/CreateOrgAdminForm';
import { getOpcoOrganizationsUrl } from '../../urls/getOpcoOrganizationsUrl';
import { usePrepareQueryParams } from '@/helpers/apiHelpers/queryHelper';
import { Button } from '@/components/Button/Button';
import { ReactComponent as PlusIcon } from '../../../../images/PlusIcon.svg';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';

export const OrganizationUsersPage = () => {
  const navigate = useNavigate();
  const searchParams = usePrepareQueryParams();
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();

  const [isOpen, setIsOpen] = useState(false);

  const { data: organizationData } = useQuery({
    queryKey: ['load_organization'],
    queryFn: () => loadOrganization(id),
  });

  const { data: organizationUsersData, isPending: isPendingOrganizationUsers } = useQuery({
    queryKey: ['load_organization_users', id, searchParams],
    queryFn: () => loadOrganizationUsers(id, searchParams),
    placeholderData: keepPreviousData,
  });

  const organization = organizationData?.body;
  const organizationUsers = organizationUsersData?.body?.results;
  const totalCount = organizationUsersData?.body?.count;
  const hasPaginator = totalCount > searchParams?.limit;

  const closeModal = () => {
    setIsOpen(false);
  };

  const createUserSuccess = () => {
    setIsOpen(false);
    queryClient.invalidateQueries({ queryKey: ['load_organization_users', id, searchParams] });
  };

  const openCreateUserForm = () => {
    setIsOpen(true);
    amplitudeService.logEvent(AMPLITUDE_EVENTS.CreateOrganizationUserRedirect);
  };

  return (
    <ContentLayout
      backButton='Back to Org Management'
      onBack={() => navigate(getOpcoOrganizationsUrl())}
      title={`${organization?.name || ''} | Organization Users`}
      subtitle={
        <>
          View the list of users of the organization.
          <br />
          Click on a particular row to view the data for the particular User.
        </>
      }
      headerAction={
        <Button width={150} icon={<PlusIcon />} iconPosition='left' onClick={openCreateUserForm} mobileStretch>
          Create User
        </Button>
      }
    >
      <PageBody>
        <OrganizationUsersTable items={organizationUsers} isLoading={isPendingOrganizationUsers} />
      </PageBody>
      {hasPaginator && (
        <PageFooter>
          <Paginator page={searchParams.page} limit={searchParams.limit} total={totalCount} />
        </PageFooter>
      )}
      <Drawer
        isOpen={isOpen}
        onClose={closeModal}
        header='Create Org Admin'
        subHeader='Please provide information for the new Org Admin.'
      >
        <CreateOrgAdminForm onClose={closeModal} onSuccess={createUserSuccess} />
      </Drawer>
    </ContentLayout>
  );
};
