import { Box } from '@/components/Box/Box';
import { ReactComponent as InfoIcon } from './images/InfoIcon.svg';
import { Title, UserRoleItemWrapper } from '@/bundle/shared/components/UserDetails/UserRoleList/styles';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { getSortedActiveRoles } from '@/bundle/pages/SwitchRolePage/ui/SwitchRole';
import { ActiveUserRolesType, UserRoleType } from '@/const/user';
import { USER_ROLE_LABEL_MAP } from '@/bundle/Sidebar/ui/SidebarUserDetails/SidebarUserDetails';

interface UserRoleListType {
  activeRole: UserRoleType;
  activeRoles: ActiveUserRolesType;
}

export const UserRoleList = ({ activeRole, activeRoles }: UserRoleListType) => {
  const sortedUserRoles = getSortedActiveRoles(activeRoles);

  return (
    <Box flexDirection='column' rowGap={'8px'}>
      <Box columnGap='4px' alignItems='center'>
        <Tooltip id='user_role-tooltip' offset={2}>
          Roles your account associated with.
        </Tooltip>
        <Title>Role</Title>
        <InfoIcon data-tooltip-id='user_role-tooltip' style={{ cursor: 'pointer' }} />
      </Box>
      <Box alignItems='center' columnGap='12px' rowGap='12px' flexWrap='wrap'>
        {sortedUserRoles?.map((item) => {
          const isActive = activeRole === item?.value;

          return (
            <UserRoleItemWrapper key={item?.title} isActive={isActive}>
              {USER_ROLE_LABEL_MAP[item?.value]}
            </UserRoleItemWrapper>
          );
        })}
      </Box>
    </Box>
  );
};
