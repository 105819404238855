import { useState } from 'react';
import { useFormik } from 'formik';
import { useMutation } from '@tanstack/react-query';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { CREATE_OPCO_USER_FORM_CONFIG } from './const';
import { ButtonsWrapper, FormWrapper } from './styles';
import { getInitialValues, getValidationSchema } from '@/helpers/formHelpers/formHelpers';
import { FormGeneratorNew } from '@/components/form/FormGeneratorNew/FormGeneratorNew';
import { Button } from '@/components/Button/Button';
import { CheckPinCodeModal } from '@/bundle/shared/components/CheckPinCodeModal/CheckPinCodeModal';
import { createOpcoUser } from '../../api';
import { Notification } from '@/components/Notification/Notification';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { CreateOpcoUserType } from '@/api/v1/opco/internal/createOpcoInternalUserApi';
import { PIN_TOKEN_EVENT_SCOPE } from '@/const/shared';

type CreateOpcoUserFormType = {
  onSuccess: () => void;
  onCancel: () => void;
};

export const CreateOpcoUserForm = ({ onSuccess, onCancel }: CreateOpcoUserFormType) => {
  const [isPinCodeOpen, setIsPinCodeOpen] = useState(false);

  const formik = useFormik({
    initialValues: getInitialValues(CREATE_OPCO_USER_FORM_CONFIG),
    validationSchema: getValidationSchema(CREATE_OPCO_USER_FORM_CONFIG),
    onSubmit: () => {
      setIsPinCodeOpen(true);
    },
  });

  const { mutate, data, isPending } = useMutation({
    mutationKey: ['create_opco_user'],
    mutationFn: (pinToken: string) => {
      return createOpcoUser(formik.values as CreateOpcoUserType, pinToken);
    },
    onSuccess(createOpcoUserResponse) {
      if (createOpcoUserResponse?.error) return;

      onSuccess();
    },
  });

  const closePinCode = () => {
    setIsPinCodeOpen(false);
  };

  const checkPinCodeSuccess = (pinToken: string) => {
    mutate(pinToken);
    closePinCode();
  };

  const formError = getResponseError(data?.error);

  return (
    <>
      {formError && (
        <Notification variant='error' mb='24px'>
          {formError}
        </Notification>
      )}
      <FormWrapper>
        <FormikForm value={formik}>
          <FormGeneratorNew config={CREATE_OPCO_USER_FORM_CONFIG} apiError={data?.error}></FormGeneratorNew>
          <ButtonsWrapper>
            <Button type='button' color='secondary' onClick={onCancel}>
              Cancel
            </Button>
            <Button type='submit' isLoading={isPending}>
              Save
            </Button>
          </ButtonsWrapper>
        </FormikForm>
      </FormWrapper>
      {isPinCodeOpen && (
        <CheckPinCodeModal
          isOpen={isPinCodeOpen}
          eventScope={PIN_TOKEN_EVENT_SCOPE.CREATE_OPCO_USER}
          onSuccess={checkPinCodeSuccess}
          onClose={closePinCode}
        />
      )}
    </>
  );
};
