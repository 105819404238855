import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthLayout } from '@/bundle/Auth/ui/AuthLayout/AuthLayout';
import { LayoutSpinner } from '@/bundle/Layouts/styles';
import { getLoginUrl } from '@/bundle/Auth/LoginFlow/LoginPage/urls/getLoginUrl';

// TODO: Remove after release 10.0.0
export const LegacySetPasswordPage = () => {
  const navigate = useNavigate();
  const { token, userUnverifiedId } = useParams();

  useEffect(() => {
    if (!token || !userUnverifiedId) {
      return navigate(getLoginUrl());
    }

    navigate(`/set-initial-password/totp?token=${token}&user_id=${userUnverifiedId}&token_type=set-initial-password`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthLayout>
      <LayoutSpinner />
    </AuthLayout>
  );
};
