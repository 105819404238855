import { DEPOSIT_ACCOUNT_STATUS_DB } from '@/const/wire';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { getDepositAccountPaymentDetailsApi } from '@/api/v1/organization/deposit-accounts/getDepositAccountPaymentDetailsApi';
import { editDepositAccountApi } from '@/api/v1/organization/deposit-accounts/editDepositAccountApi';
import { getDepositAccountApi } from '@/api/v1/organization/deposit-accounts/getDepositAccountApi';
import {
  UpdateDepositAccountStatusDataType,
  updateDepositAccountStatusApi,
} from '@/api/v1/organization/deposit-accounts/updateDepositAccountStatusApi';

export const loadDepositAccount = async (id: string) => {
  try {
    const response = await getDepositAccountApi(id);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const loadDepositAcountPaymentDetails = async (id: string) => {
  try {
    const response = await getDepositAccountPaymentDetailsApi(id);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const deactivateDepositAccount = async (id: string) => {
  try {
    const payload: UpdateDepositAccountStatusDataType = {
      status: DEPOSIT_ACCOUNT_STATUS_DB.INACTIVE,
    };

    const response = await updateDepositAccountStatusApi(id, payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const updateDepositAccount = async (id: string, payload) => {
  try {
    const response = await editDepositAccountApi(id, payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
