import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { isNullOrUndefined } from '@/helpers/objectHelpers';
import { useFormik } from 'formik';
import { AssignExecutorFormWrapper } from '../../styles';
import { FormikForm } from '../../../../../../components/form/FormikForm/FormikForm';
import { Button } from '../../../../../../components/Button/Button';
import { ConfirmModal, ConfirmModalHint } from '@/components/ConfirmModal/ConfirmModal';
import { FormValues, getMergedValues, getValidationSchema, isValidForm } from '@/helpers/formHelpers/formHelpers';
import { FormButtonBlock, FormMessage, FormTitle } from '../../../../shared/ui/styles';
import { assignDepositor, createDepositor, depositorAssignmentStatus } from '../../api';
import { Maybe } from '@/types/sharedTypes';
import { AutocompleteOptionType } from '@/components/Autocomplete/types';
import { FormGeneratorNew } from '@/components/form/FormGeneratorNew/FormGeneratorNew';
import { AssignUserAutocomplete } from '@/bundle/shared/components/AssignUserAutocomplete';
import { USER_ROLE_MAP } from '@/const/user';
import { loadUserInfo } from '@/bundle/shared/api';
import { useAssignUser } from '@/bundle/shared/hooks/useAssignUser';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import {
  ASSIGN_AMPLITUDE_EVENTS_REDIRECT_MAP,
  ASSIGN_AMPLITUDE_EVENTS_SUCCESS_MAP,
  ERROR_ASSIGN_AMPLITUDE_EVENTS_REDIRECT_MAP,
} from '@/bundle/_OrgAdmin/pages/InboundWire/const/amplitudeEventsMap';
import { ASSIGN_USER_FORM_CONFIG } from '@/bundle/_OrgAdmin/shared/const/const';

type AssignDepositorFormType = {
  wireId: string;
  isOpenConfirmModal: boolean;
  onToggleConfirmModal: (isOpen: boolean) => void;
  onSuccess: () => void;
};

export const AssignDepositorForm = ({
  wireId,
  isOpenConfirmModal,
  onToggleConfirmModal,
  onSuccess,
}: AssignDepositorFormType) => {
  const [selectedDepositorOption, setSelectedDepositorOption] = useState<Maybe<AutocompleteOptionType>>(null);
  const depositorId = selectedDepositorOption?.value;

  const { data: assignmentStatusData } = useQuery({
    queryKey: ['load_assignment_status', depositorId],
    queryFn: () => depositorAssignmentStatus(depositorId),
    enabled: !!selectedDepositorOption?.isSelectedOption,
  });

  const { data: loadUserData } = useQuery({
    queryKey: ['load_user', depositorId],
    queryFn: () => loadUserInfo(depositorId),
    enabled: !!selectedDepositorOption?.isSelectedOption && !!assignmentStatusData?.body?.is_available_to_assign,
  });

  const {
    data: createDepositorData,
    mutate: createDepositorMutate,
    isPending: isPendingCreateDepositor,
    reset: resetCreateDepositor,
  } = useMutation({
    mutationKey: ['create_depositor'],
    mutationFn: (newDepositorData: FormValues) => {
      return createDepositor(wireId, newDepositorData);
    },
    onSuccess(createDepositorResponse) {
      if (createDepositorResponse?.error) return;

      amplitudeService.logEvent(AMPLITUDE_EVENTS.CreateNewDepositorSuccess);

      onSuccess();
    },
    onSettled() {
      onToggleConfirmModal(false);
    },
  });

  const {
    data: assignDepositorData,
    mutate: assignDepositorMutate,
    isPending: isPendingAssignDepositor,
    reset: resetAssignDepositor,
  } = useMutation({
    mutationKey: ['assign_depositor'],
    mutationFn: () => {
      const userData = loadUserData?.body;

      return assignDepositor(wireId, userData?.id);
    },
    onSuccess(assignDepositorResponse) {
      if (assignDepositorResponse?.error) return;

      const currentStatus = assignmentStatusData?.body?.code;

      amplitudeService.logEvent(ASSIGN_AMPLITUDE_EVENTS_SUCCESS_MAP[currentStatus]);

      onSuccess();
    },
    onSettled() {
      onToggleConfirmModal(false);
    },
  });

  const getUser = () => {
    return loadUserData?.body;
  };

  const isAvailableToAssign = useMemo(() => {
    const isAvailableToAssignDepositor = assignmentStatusData?.body?.is_available_to_assign;

    if (isNullOrUndefined(isAvailableToAssignDepositor)) return true;

    return isAvailableToAssignDepositor === true;
  }, [assignmentStatusData]);

  const user = getUser();
  const initialValues = getMergedValues(ASSIGN_USER_FORM_CONFIG, user) as FormValues;
  const isDisabledForm = !!user || !isAvailableToAssign;
  const validationSchema = isDisabledForm ? null : getValidationSchema(ASSIGN_USER_FORM_CONFIG);

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (selectedDepositorOption?.isSelectedOption) {
        assignDepositorMutate();

        return;
      }

      createDepositorMutate(values);
    },
  });

  const closeConfirmModal = () => onToggleConfirmModal(false);

  const onReset = () => {
    resetCreateDepositor();
    resetAssignDepositor();
  };

  useAssignUser(
    loadUserData?.body,
    formik,
    onReset,
    selectedDepositorOption,
    assignmentStatusData?.body?.is_available_to_assign
  );
  const assignmentStatus = assignmentStatusData?.body?.code;
  const createDepositorError = createDepositorData?.error;
  const assignRecipientError = assignDepositorData?.error;
  const apiError = createDepositorError || assignRecipientError;
  const isLoading = isPendingAssignDepositor || isPendingCreateDepositor;

  const openConfirmModal = async () => {
    if (assignmentStatusData?.body?.is_available_to_assign) {
      amplitudeService.logEvent(ASSIGN_AMPLITUDE_EVENTS_REDIRECT_MAP[assignmentStatus]);
    } else {
      amplitudeService.logEvent(AMPLITUDE_EVENTS.CreateDepositorRedirect);
    }

    const isValid = await isValidForm(formik);

    onToggleConfirmModal(isValid);
  };

  useEffect(() => {
    const assigmentStatusBody = assignmentStatusData?.body;

    if (assigmentStatusBody && !assigmentStatusBody?.is_available_to_assign) {
      amplitudeService.logEvent(ERROR_ASSIGN_AMPLITUDE_EVENTS_REDIRECT_MAP[assigmentStatusBody?.code]);
    }
  }, [assignmentStatusData?.body]);

  return (
    <AssignExecutorFormWrapper>
      <FormTitle>Assign Depositor</FormTitle>
      <FormMessage>Please indicate who will be making this deposit.</FormMessage>
      <FormikForm value={formik}>
        <AssignUserAutocomplete
          role={USER_ROLE_MAP.DEPOSITOR}
          assignmentStatus={assignmentStatus}
          selectedOption={selectedDepositorOption}
          onSelect={setSelectedDepositorOption}
          apiError={apiError}
          error={formik?.errors.email}
        />
        <FormGeneratorNew config={ASSIGN_USER_FORM_CONFIG} disableForm={isDisabledForm} apiError={apiError} />
      </FormikForm>
      <FormButtonBlock>
        <Button width={172} size='medium' onClick={openConfirmModal} disabled={!isAvailableToAssign}>
          Assign
        </Button>
      </FormButtonBlock>

      <ConfirmModal
        isOpen={isOpenConfirmModal}
        header={'Confirm Wire Information'}
        isLoading={isLoading}
        body={
          <>
            <ConfirmModalHint>
              You are certifying the validity of the wire information you have provided.
            </ConfirmModalHint>
            <ConfirmModalHint>
              This information will be committed to the WireVault blockchain and cannot be changed.
            </ConfirmModalHint>
            <ConfirmModalHint>You can cancel this wire until the deposit is sent.</ConfirmModalHint>
          </>
        }
        onClose={closeConfirmModal}
        onConfirm={formik.handleSubmit}
      />
    </AssignExecutorFormWrapper>
  );
};
