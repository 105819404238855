import { FormikDatePicker } from '@/components/form/FormikDatePicker/FormikDatePicker';
import { Box } from '@/components/Box/Box';
import { FormValues } from '@/helpers/formHelpers/formHelpers';

type RangeDatePickerType = {
  config: {
    nameFrom: string;
    nameTo: string;
  };
  values: FormValues;
};

export const RangeDatePicker = ({ config, values }: RangeDatePickerType) => {
  const { nameFrom, nameTo } = config;
  const startDate = values.date_created_after as Date;
  const endDate = values.date_created_before as Date;

  return (
    <Box flexDirection='row' columnGap='10px'>
      <FormikDatePicker
        name={nameFrom}
        variant={'small'}
        isClearable={false}
        selectsStart
        startDate={startDate}
        endDate={endDate}
      />
      <FormikDatePicker
        name={nameTo}
        variant={'small'}
        isClearable={false}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
      />
    </Box>
  );
};
