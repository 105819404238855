import { useNavigate, useParams } from 'react-router-dom';
import { WIRE_TYPE_INBOUND } from '../../const/const';
import { getInboundWireDetailsUrl } from '../../../../InboundWire/urls/getInboundWireDetailsUrl';
import { getOutboundWireDetailsUrl } from '../../../../OutboundWire/_Details/urls/getOutboundWireDetailsUrl';
import { Card, CardContent } from '@/components/Card/Card';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { dateFormatter, formatAmount } from '@/helpers/formatHelpers';
import { WireInboundTypeIconComponent, WireOutboundTypeIconComponent } from '../WireTypeIconComponent';
import { WireHeading } from './styled';
import { AssignedWireType } from '@/types/wireTypes';
import { StatusList } from '../StatusList/StatusList';
import { Box } from '@/components/Box/Box';
import { RecipientType } from '@/bundle/_OrgAdmin/pages/Transactions/_Details/ui/RecipientType/RecipientType';
import { isCreatedWireStatus, isRecipientProxy } from '@/helpers/wireHelpers';

type WiresListType = {
  list: AssignedWireType[];
};
const EMAIL_ADDRESS_LABEL = 'Email Address';

const getEmailColumnName = (isInboundWire: boolean, isProxy: boolean, hasRecipient: boolean) => {
  if (isInboundWire) return `Depositor ${EMAIL_ADDRESS_LABEL}`;

  if (isProxy && hasRecipient) return `Recipient ${EMAIL_ADDRESS_LABEL}`;

  if (isProxy) return `Proxy ${EMAIL_ADDRESS_LABEL}`;

  return `Recipient ${EMAIL_ADDRESS_LABEL}`;
};

const getRecipientEmail = (wire: AssignedWireType) => {
  const { assigned_depositor, assigned_user, assigned_proxy_user } = wire;

  if (!!assigned_depositor?.user?.email) return assigned_depositor?.user?.email;

  if (!!assigned_proxy_user?.email && !!assigned_user?.email) return assigned_user?.email;

  if (!!assigned_proxy_user?.email) return assigned_proxy_user?.email;

  if (!!assigned_user?.email) return assigned_user?.email;

  return '-';
};

export const WiresList = ({ list }: WiresListType) => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const getWireDetailsUrl = (wire) => {
    const { type, id: wireId } = wire;

    return type === WIRE_TYPE_INBOUND ? getInboundWireDetailsUrl(id, wireId) : getOutboundWireDetailsUrl(id, wireId);
  };

  const navigateToDetails = (wire) => {
    const currentUrl = getWireDetailsUrl(wire);
    const eventData = { status: wire.status, type: wire.type };

    amplitudeService.logEvent(AMPLITUDE_EVENTS.OutboundWireViewDetails, eventData);

    navigate(currentUrl);
  };

  return (
    <>
      {list.map((wire) => {
        const {
          name,
          date_created,
          expected_payment_date,
          status,
          amount,
          payment_details,
          type,
          assigned_user,
          is_pd_provider_kyc_required,
        } = wire;
        const isInboundWire = type === WIRE_TYPE_INBOUND;
        const wireTypeIcon = isInboundWire ? <WireInboundTypeIconComponent /> : <WireOutboundTypeIconComponent />;
        const depositAccountStatus = payment_details?.deposit_account?.status;

        const isProxy = isRecipientProxy(wire);
        const isCreatedStatus = isCreatedWireStatus(status);
        const emailColumnName = getEmailColumnName(isInboundWire, isProxy, !!assigned_user);
        const email = getRecipientEmail(wire);
        const verificationMarker = is_pd_provider_kyc_required && 'ID VERIFICATION';

        return (
          <Card
            key={wire.id}
            title={name}
            marker={verificationMarker}
            header={
              <WireHeading>
                {wireTypeIcon}
                {name}
              </WireHeading>
            }
            meta={
              <Box display='flex' alignItems='center' columnGap='8px'>
                {!isInboundWire && !isCreatedStatus && <RecipientType isProxy={isProxy} />}
                <StatusList wireStatus={status} depositAccountStatus={depositAccountStatus} />
              </Box>
            }
            onClick={() => navigateToDetails(wire)}
          >
            <CardContent name='Creation Date'>{dateFormatter(date_created)}</CardContent>
            <CardContent name='Expected Payment Date'>{dateFormatter(expected_payment_date)}</CardContent>
            <CardContent name='Amount'>{formatAmount(amount)}</CardContent>
            <CardContent name={emailColumnName} ratio={2}>
              {email}
            </CardContent>
          </Card>
        );
      })}
    </>
  );
};
