import { forwardRef } from 'react';
import { TSelectOptionsItem, SelectOptionsWrapper } from './styles';
import { ReactComponent as CheckedIcon } from '../../../images/checedIcon.svg';
import { useOutsideClick } from '../../../hooks/useOutsideClick';

type MultiSelectOptionsProps = {
  values: string[];
  width?: number;
  options: any;
  onChange: any;
  optionsHeight?: number;
  onClose: () => void;
};

export const MultiSelectOptions = forwardRef<HTMLElement, MultiSelectOptionsProps>(
  ({ values, options, onChange, optionsHeight, onClose }, forwardedRef) => {
    useOutsideClick(forwardedRef, () => {
      onClose();
    });

    return (
      <SelectOptionsWrapper height={optionsHeight}>
        {options.map((option: any) => {
          const isChecked = Array.isArray(values) ? values.includes(option.value) : false;

          return (
            <TSelectOptionsItem key={option.value} onClick={() => onChange(option.value)}>
              <div>{option.label}</div>
              <div>{isChecked && <CheckedIcon />}</div>
            </TSelectOptionsItem>
          );
        })}
      </SelectOptionsWrapper>
    );
  }
);
