import { useSearchParams } from 'react-router-dom';
import { QueryParamsType, defaultQueryParams, prepareFilters } from '../../api/helpers/queryHelpers';

export const usePrepareQueryParams = (): QueryParamsType => {
  const [searchParams] = useSearchParams();
  const filters = prepareFilters(searchParams);

  const page = searchParams.get('page') === null ? defaultQueryParams.page : Number(searchParams.get('page'));
  const limit = searchParams.get('limit') === null ? defaultQueryParams.limit : Number(searchParams.get('limit'));
  const ordering = searchParams.get('ordering') === null ? defaultQueryParams.ordering : searchParams.get('ordering');
  const search = searchParams.get('search') === null ? defaultQueryParams.search : searchParams.get('search');
  const email = searchParams.get('email') === null ? defaultQueryParams.email : searchParams.get('email');

  return {
    page,
    limit,
    ordering,
    filters,
    search,
    email,
  };
};

export const getAuthTokenHeaders = (pinToken: string) => {
  return {
    requestHeaders: {
      'X-AUTH-CONFIRMATION-TOKEN': pinToken,
    },
  };
};
