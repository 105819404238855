import styled from 'styled-components';
import { COLORS } from '../../../../styles/colors';
import { bodySmall, headline5, poppinsRegularFont, poppinsSemiBoldFont } from '../../../../styles/typography';
import { flex } from '@/styles/layout';
import { ReactComponent as BackButtonIcon } from '../images/BackIcon.svg';
import { media } from '@/styles/responsive';

export const ContentHeaderWrapper = styled.div`
  width: 100%;
  border-radius: 8px 8px 0 0;
  margin-bottom: 36px;

  ${media.tablet`
    margin-bottom: 24px;
  `}

  ${media.mobile`
    margin-bottom: 20px;
  `}
`;

export const ContentHeaderContainer = styled.div`
  ${flex({ alignItems: 'flex-start', justifyContent: 'space-between' })};
  width: 100%;

  ${media.mobile`
    ${flex({ alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' })};
  `}
`;

export const ContentHeaderTextBlock = styled.div`
  padding-right: 16px;
`;

export const ContentHeaderTitle = styled.div`
  ${flex({ alignItems: 'center' })};
  width: 100%;
  text-transform: uppercase;
  column-gap: 12px;
  margin-top: 8px;
  ${poppinsSemiBoldFont}
  color: ${COLORS.grey[900]};
  ${headline5};

  ${media.mobile`
    margin-top: 0;
  `}
`;

export const ContentHeaderSubtitle = styled.div`
  ${poppinsRegularFont};
  ${bodySmall};
  color: ${COLORS.grey[700]};
  margin-top: 8px;
`;

export const ContentHeaderRightBlock = styled.div`
  ${flex({ justifyContent: 'right' })};
  gap: 20px;

  ${media.mobile`
    display: block;
    width: 100%;
  `}
`;

export const ContentHeaderButtonRow = styled.div`
  ${flex({ alignItems: 'center' })};
  gap: 20px;

  ${media.mobile`
    margin-top: 20px;
  `}
`;

export const ContentHeaderActionMessage = styled.div`
  ${flex({ alignItems: 'center' })};
  width: max-content;
  color: ${COLORS.grey[600]};
  ${poppinsRegularFont};
  ${bodySmall};
`;

export const BackButton = styled.button`
  cursor: pointer;
  padding: 0;
  border: 0;
  border-radius: 8px;
  overflow: hidden;
  background: transparent;
  flex-shrink: 0;
`;

export const BackIcon = styled(BackButtonIcon)`
  transition: all 0.2s ease;

  &:hover {
    background: ${COLORS.grey[300]};

    path {
      stroke: ${COLORS.black};
    }
  }
`;
