import { Widget } from '@/components/Widget/Widget';
import { EditWireDetails } from '@/bundle/_OrgAdmin/pages/OutboundWire/_Edit/ui/EditWireDetails/EditWireDetails';
import { OutboundWireEditForm } from '@/bundle/_OrgAdmin/pages/OutboundWire/_Edit/OutboundWireEditForm';
import { Box } from '@/components/Box/Box';
import { DetailsBlockGenerator } from '@/components/DetailsBlockGenerator/DetailsBlockGenerator';
import { WaterMark } from '@/bundle/shared/components/WaterMark/WaterMark';
import { AssignedWireType } from '@/types/wireTypes';
import { isCancelledWireStatus, isExecutedWireStatus } from '@/helpers/wireHelpers';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateOutboundWire } from '@/bundle/_OrgAdmin/pages/OutboundWire/_Edit/api';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { useParams } from 'react-router-dom';
import { ResponseErrorType } from '@/types/sharedTypes';
import { useWireGeneralDetailsConfig } from '@/bundle/shared/helpers/configHelpers';

interface OutboundWireGeneralDetailsType {
  wire: AssignedWireType;
  wireApiError: ResponseErrorType;
  isEdit: boolean;
  currencyOptions: Array<{ value: string; label: string }>;
  onUpdateWireDetails: () => void;
  onEditWireDetails: () => void;
}

export const OutboundWireGeneralDetails = ({
  wire,
  isEdit,
  currencyOptions,
  onUpdateWireDetails,
  onEditWireDetails,
  wireApiError,
}: OutboundWireGeneralDetailsType) => {
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();

  const wireStatus = wire?.status;
  const isExecutedWire = isExecutedWireStatus(wireStatus);
  const isCanceledWire = isCancelledWireStatus(wireStatus);
  const showWaterMark = isExecutedWire || isCanceledWire;
  const detailsConfig = useWireGeneralDetailsConfig(wire);

  const {
    mutate: updateWireDetails,
    isPending,
    reset: resetUpdateWireDetails,
  } = useMutation({
    mutationKey: ['update_wire_details'],
    mutationFn: (payload) => updateOutboundWire(id, payload),
    onSuccess: (response) => {
      if (response?.error) return;

      amplitudeService.logEvent(AMPLITUDE_EVENTS.EditGeneralInfoOutboundWireSuccess);

      queryClient.setQueryData(['load_outbound_wire', id], response);
    },
    onSettled: () => {
      onUpdateWireDetails();
    },
  });

  return (
    <>
      {isEdit ? (
        <Widget wrapperVariant='dark' title='Edit Wire'>
          <EditWireDetails status={wireStatus} dateUpdate={wire?.date_modified} />
          <OutboundWireEditForm
            onUpdateWireDetails={onUpdateWireDetails}
            currencyOptions={currencyOptions}
            outboundWire={wire}
            apiError={wireApiError}
            isLoading={isPending}
            onReset={resetUpdateWireDetails}
            onEdit={updateWireDetails}
          />
        </Widget>
      ) : (
        <Widget>
          <Box>
            <DetailsBlockGenerator configuration={detailsConfig} onClick={onEditWireDetails} />
            {showWaterMark && <WaterMark wireStatus={wireStatus} />}
          </Box>
        </Widget>
      )}
    </>
  );
};
