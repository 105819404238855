import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { FormGeneratorNew } from '@/components/form/FormGeneratorNew/FormGeneratorNew';
import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import { FormValues, getMergedValues, getValidationSchema } from '@/helpers/formHelpers/formHelpers';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Notification } from '@/components/Notification/Notification';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { CREATE_ORGANIZATION_USER_CONFIG } from './const';
import { addingOrgAdminRole, createOrgAdminUser, loadAddedUserDetails, addedUserAssigmentStatus } from '../../api';
import { PIN_TOKEN_EVENT_SCOPE } from '@/const/shared';
import { Maybe } from '@/types/sharedTypes';
import { AutocompleteOptionType } from '@/components/Autocomplete/types';
import { CreateOrgAdminAutocomplete } from '@/bundle/_Opco/ui/OrganizationUsers/component/CreateOrgAdminAutocomplete';
import { phoneFormatter } from '@/helpers/formatHelpers';
import { OrganizationCreateUserType } from '@/bundle/_Opco/types/types';
import { ADDING_ORG_ADMIN_ROLE_STATUS_MAP } from '@/api/v1/opco/organizations/getUserAddingStatusApi';
import { CheckPinCodeModal } from '@/bundle/shared/components/CheckPinCodeModal/CheckPinCodeModal';

interface CreateOrganizationUserFormType {
  onClose: () => void;
  onSuccess: () => void;
}

export const CreateOrgAdminForm = ({ onClose, onSuccess }: CreateOrganizationUserFormType) => {
  const { id } = useParams<{ id: string }>();
  const [isPinCodeOpen, setIsPinCodeOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<Maybe<AutocompleteOptionType>>(null);

  const closePinCode = () => {
    setIsPinCodeOpen(false);
  };

  const isSelectedOption = selectedUser?.isSelectedOption;
  const selectedOptionValue = selectedUser?.value;

  const { data: addingUserStatusData } = useQuery({
    queryKey: ['adding_user_status', selectedOptionValue],
    queryFn: () => {
      return addedUserAssigmentStatus(selectedOptionValue);
    },
    enabled: !!isSelectedOption,
  });

  const { data: loadUserData } = useQuery({
    queryKey: ['load_user_details', selectedOptionValue],
    queryFn: () => loadAddedUserDetails(selectedOptionValue),
    enabled: !!isSelectedOption && !!addingUserStatusData?.body?.is_available_to_add,
  });

  const validationSchema = isSelectedOption ? null : getValidationSchema(CREATE_ORGANIZATION_USER_CONFIG);

  const formik = useFormik<OrganizationCreateUserType>({
    initialValues: getMergedValues(CREATE_ORGANIZATION_USER_CONFIG, loadUserData?.body) as OrganizationCreateUserType,
    validationSchema,
    enableReinitialize: true,
    onSubmit: () => {
      if (addingUserStatusData?.body?.code === ADDING_ORG_ADMIN_ROLE_STATUS_MAP.CAN_BE_ADDED) {
        amplitudeService.logEvent(AMPLITUDE_EVENTS.CreateOrgAdminExistingRedirect);
      } else {
        amplitudeService.logEvent(AMPLITUDE_EVENTS.CreateOrgAdminNewRedirect);
      }

      setIsPinCodeOpen(true);
    },
  });
  const {
    mutate: createOrgAdminMutate,
    data: createOrgAdminData,
    isPending: isPendingCreateOrgAdmin,
    reset: resetCreateOrgUser,
  } = useMutation({
    mutationKey: ['create_org_admin', id],
    mutationFn: (pinCode: string) => {
      return createOrgAdminUser(id, formik.values, pinCode);
    },
    onSuccess(response) {
      if (response.error) return;

      amplitudeService.logEvent(AMPLITUDE_EVENTS.CreateOrgAdminNewSuccess);
      onSuccess();
    },
  });

  const {
    mutate: addingOrgAdminRoleMutate,
    data: addingOrgAdminRoleData,
    isPending: isPendingAddingOrgAdminRole,
    reset: resetAddingOrgAdminRole,
  } = useMutation({
    mutationKey: ['adding_org_admin_role', id, selectedOptionValue],
    mutationFn: (pinCode: string) => {
      return addingOrgAdminRole(id, selectedOptionValue, pinCode);
    },
    onSuccess(response) {
      if (response.error) return;

      amplitudeService.logEvent(AMPLITUDE_EVENTS.CreateOrgAdminExistingSuccess);

      onSuccess();
    },
  });

  const checkPinCodeSuccess = (pinToken: string) => {
    if (isSelectedOption) {
      addingOrgAdminRoleMutate(pinToken);
      closePinCode();

      return;
    }

    createOrgAdminMutate(pinToken);
    closePinCode();
  };

  const setFormValues = (formValues: Partial<FormValues>) => {
    formik.setValues({
      ...formik.values,
      ...formValues,
    });
  };

  const clearForm = () => {
    setFormValues({
      mobile_phone: '',
      first_name: '',
      middle_name: '',
      last_name: '',
    });
  };

  // Reset form when user clears last char in search
  useEffect(() => {
    if (!selectedUser) return;

    if (!formik?.values.email) {
      setFormValues({
        mobile_phone: '',
        first_name: '',
        middle_name: '',
        last_name: '',
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values?.email, selectedUser]);

  useEffect(() => {
    if (isSelectedOption) return;

    resetCreateOrgUser();
    resetAddingOrgAdminRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectedOption]);

  useEffect(() => {
    if (!isSelectedOption || !loadUserData?.body) return;

    const { first_name, middle_name, last_name, mobile_phone } = loadUserData?.body;

    setFormValues({
      mobile_phone: phoneFormatter(mobile_phone),
      first_name,
      middle_name,
      last_name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectedOption, loadUserData?.body]);

  useEffect(() => {
    if (!addingUserStatusData?.body?.is_available_to_add) {
      formik.setErrors({});
      clearForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addingUserStatusData?.body?.is_available_to_add]);

  const addingUserStatus = addingUserStatusData?.body?.code;
  const apiError = createOrgAdminData?.error || addingOrgAdminRoleData?.error;
  const isLoading = isPendingAddingOrgAdminRole || isPendingCreateOrgAdmin;
  const currentEventScope = isSelectedOption
    ? PIN_TOKEN_EVENT_SCOPE.ADD_ORG_USER_ROLE
    : PIN_TOKEN_EVENT_SCOPE.CREATE_ORG_ADMIN;

  const isNotAvailableToAssignUser = addingUserStatusData?.body && !addingUserStatusData?.body?.is_available_to_add;

  const isDisableForm = (isSelectedOption && !!loadUserData?.body) || isNotAvailableToAssignUser;
  const formError = getResponseError(apiError);

  useEffect(() => {
    if (isNotAvailableToAssignUser) {
      amplitudeService.logEvent(AMPLITUDE_EVENTS.CreateOrgAdminFailed);
    }
  }, [isNotAvailableToAssignUser]);

  return (
    <>
      {formError && (
        <Notification variant='error' mb='24px'>
          {formError}
        </Notification>
      )}
      <FormikForm value={formik}>
        <CreateOrgAdminAutocomplete
          selectedOption={selectedUser}
          selectedUserStatus={addingUserStatus}
          onSelect={setSelectedUser}
          apiError={apiError}
          error={formik.errors.email}
        />

        <FormGeneratorNew config={CREATE_ORGANIZATION_USER_CONFIG} apiError={apiError} disableForm={isDisableForm} />
        <Box display='flex' alignItems='center' columnGap='20px' mt='14px'>
          <Button color='secondary' onClick={onClose}>
            Cancel
          </Button>
          <Button type='submit' isLoading={isLoading} disabled={isNotAvailableToAssignUser}>
            Save
          </Button>
        </Box>
      </FormikForm>
      {isPinCodeOpen && (
        <CheckPinCodeModal
          isOpen={isPinCodeOpen}
          onSuccess={checkPinCodeSuccess}
          onClose={closePinCode}
          eventScope={currentEventScope}
        />
      )}
    </>
  );
};
