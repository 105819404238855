import { parseISO } from 'date-fns';
import { useFormik } from 'formik';
import { FormikForm } from '../../../../../../../components/form/FormikForm/FormikForm';
import { Button } from '../../../../../../../components/Button/Button';
import { AssignedWireType } from '@/types/wireTypes';
import { formatDate } from '@/helpers/dateHelpers/dateHelpers';
import { formatNotes } from '@/helpers/stringHelpers';
import { Box } from '@/components/Box/Box';
import { FormGeneratorNew } from '@/components/form/FormGeneratorNew/FormGeneratorNew';
import { getCreateReplacementWireFormConfig } from './const/const';
import { getMergedValues, getValidationSchema } from '@/helpers/formHelpers/formHelpers';
import { OptionType } from '@/components/SelectNew/SelectNew';
import { isRecipientChainProxy, isRecipientProxy } from '@/helpers/wireHelpers';

type CreateReplacementWireFormType = {
  wire: AssignedWireType;
  currencyOptions: OptionType[];
  isLoading: boolean;
  onCreate: (data) => void;
  onClose: () => void;
};

type FormValues = {
  name: string;
  amount?: number;
  expected_payment_date?: Date;
  email: string;
  notes?: string;
  currency: OptionType;
};

const getFormattedWireData = (wire: AssignedWireType, currencyOptions: OptionType[]) => {
  if (!wire) return;

  const isProxy = isRecipientProxy(wire);
  const isChainProxy = isRecipientChainProxy(wire);
  const { name, amount, expected_payment_date, assigned_user, notes, assigned_proxy_user } = wire;
  const commonWireData = {
    name,
    amount,
    currency: currencyOptions[0]?.value,
    expected_payment_date: expected_payment_date ? parseISO(expected_payment_date) : '',
    notes,
  };

  if (isChainProxy) {
    return {
      ...commonWireData,
      email_proxy: assigned_proxy_user?.email,
      email: assigned_user?.email,
    };
  }

  return {
    ...commonWireData,
    email: isProxy ? assigned_proxy_user?.email : assigned_user?.email,
  };
};

export const CreateReplacementWireForm = ({
  wire,
  currencyOptions = [],
  isLoading,
  onCreate,
  onClose,
}: CreateReplacementWireFormType) => {
  const isChainProxy = isRecipientChainProxy(wire);
  const isDocumentVerificationRequired = !!wire?.is_pd_provider_kyc_required;

  const CREATE_REPLACEMENT_WIRE_FORM_CONFIG = getCreateReplacementWireFormConfig(
    currencyOptions,
    isChainProxy,
    isDocumentVerificationRequired
  );
  const wireData = getFormattedWireData(wire, currencyOptions);

  const formik = useFormik<FormValues>({
    initialValues: getMergedValues<FormValues>(CREATE_REPLACEMENT_WIRE_FORM_CONFIG, wireData),
    enableReinitialize: true,
    validationSchema: getValidationSchema(CREATE_REPLACEMENT_WIRE_FORM_CONFIG),
    onSubmit: (values) => {
      const { name, amount, currency, expected_payment_date, notes } = values;

      const payload = {
        name,
        amount,
        currency: currency.value,
        expected_payment_date: formatDate(expected_payment_date),
        notes: formatNotes(notes),
      };

      onCreate(payload);
    },
  });

  const closeModal = () => {
    onClose();
  };

  return (
    <FormikForm value={formik}>
      <FormGeneratorNew config={CREATE_REPLACEMENT_WIRE_FORM_CONFIG} />

      <Box alignItems='center' justifyContent='space-between' columnGap='20px'>
        <Button color='secondary' onClick={closeModal}>
          Cancel
        </Button>
        <Button type='submit' isLoading={isLoading}>
          Create
        </Button>
      </Box>
    </FormikForm>
  );
};
