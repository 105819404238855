import styled from 'styled-components';

export const FilterListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

export const FilterListFiltersBlock = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

export const FilterListFiltersBlockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;

export const FilterListCmpBlock = styled.div<{ width: number }>`
  display: flex;
  width: ${({ width }) => (width ? `${width}px` : '240px')};
`;

export const FilterListFiltersBlockButtonBlock = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1350px) {
    margin-top: 10px;
  }
`;

export const FilterListCustomButtonClearAll = styled.button`
  margin-right: 10px;
  gap: 10px;
  max-width: max-content;
  width: 120px;
  height: 36px;
  border: none;
  font-family: 'Poppins-Medium', sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  color: #00866f;
  cursor: pointer;
  background: transparent;
`;

export const BlockOfChips = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 12px 0 8px 0;
`;
