import { useState } from 'react';
import { ContentLayout } from '../Layouts/ContentLayout/ContentLayout';
import { PageBody } from '../../components/Page/PageBody/PageBody';
import { Paginator } from '../../components/Paginator/Paginator';
import { NoResults } from '../../components/NoResults/NoResults';
import { ReactComponent as NoTransactionsIcon } from '../../images/NoTransactionsIcon.svg';
import { CreateTransactionForm } from './pages/Transactions/ui/CreateTransactionForm/CreateTransactionForm';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { PageFooter } from '../../components/Page/PageFooter/PageFooter';
import { TransactionsList } from './pages/Transactions/ui/TransactionsList';
import { ModalWindow } from '../../components/ModalWindow/ModalWindow';
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createTransaction, loadTransactions } from './pages/Transactions/api';
import { amplitudeService } from '../../services/amplitudeService/amplitudeService';
import { usePrepareQueryParams } from '@/helpers/apiHelpers/queryHelper';
import { formatDate } from '@/helpers/dateHelpers/dateHelpers';
import { ReactComponent as PlusIcon } from '@/images/PlusIcon.svg';
import { Button } from '@/components/Button/Button';

type ValuesType = {
  expected_close_date: string;
  name: string;
};

export const TransactionsPage = () => {
  const searchParams = usePrepareQueryParams();
  const [isOpen, setIsOpen] = useState(false);
  const queryClient = useQueryClient();

  const {
    mutate,
    data: createdTransaction,
    isPending,
    reset,
  } = useMutation({
    mutationKey: ['create_transaction'],
    mutationFn: ({ name, expected_close_date }: ValuesType) => {
      const formattedDate = formatDate(expected_close_date);
      const payload = { name, expected_close_date: formattedDate };

      return createTransaction(payload);
    },
    onSuccess(data) {
      if (data.error) return;

      setIsOpen(false);

      amplitudeService.logEvent(AMPLITUDE_EVENTS.CreateTransactionSuccess);
      queryClient.removeQueries({ queryKey: ['load_transaction', searchParams] });
    },
  });

  const { data: transactions, isPending: isPendingTransactions } = useQuery({
    queryKey: ['load_transaction', searchParams],
    queryFn: () => loadTransactions(searchParams),
    placeholderData: keepPreviousData,
  });

  const onClose = () => {
    setIsOpen(false);
    reset();
  };

  const openCreateTransactionForm = () => {
    setIsOpen(true);

    amplitudeService.logEvent(AMPLITUDE_EVENTS.CreateTransactionRedirect);
  };

  const transactionBody = transactions?.body;
  const hasTransactions = !!transactionBody?.results?.length;
  const hasPaginator = transactionBody?.count > searchParams?.limit;
  const transactionsList = transactionBody?.results;

  return (
    <ContentLayout
      title='Transactions'
      headerAction={
        <Button width={220} icon={<PlusIcon />} iconPosition='left' onClick={openCreateTransactionForm}>
          Create Transaction
        </Button>
      }
    >
      <PageBody>
        {hasTransactions && <TransactionsList list={transactionsList} />}
        {!hasTransactions && !isPendingTransactions && (
          <NoResults marginTop={128} icon={<NoTransactionsIcon />} message='Please create your first transaction.' />
        )}
      </PageBody>
      {hasPaginator && (
        <PageFooter>
          <Paginator page={searchParams.page} limit={searchParams.limit} total={transactionBody?.count} />
        </PageFooter>
      )}
      <ModalWindow isOpen={isOpen} headerText={'Create Transaction'} isStartPositionHeader>
        <CreateTransactionForm
          onCreate={mutate}
          apiError={createdTransaction?.error}
          onClose={onClose}
          isLoading={isPending}
        />
      </ModalWindow>
    </ContentLayout>
  );
};
