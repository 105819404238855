import { useEffect, useState } from 'react';
import { ContentLayout } from '../../Layouts/ContentLayout/ContentLayout';
import { PageBody } from '@/components/Page/PageBody/PageBody';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useInterval } from '@/hooks/useInterval';
import { PageColumn } from '@/components/Page/PageColumn/PageColumn';
import { PageContent } from '@/components/Page/PageContent/PageContent';
import { WIRE_STATUS_DB } from '@/const/wire';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { loadRecipientWire } from './api';
import { isPaymentDetailsProvidedWireStatus, isRetryWireStatus } from '@/helpers/wireHelpers';
import { getRecipientWiresUrl } from '../urls/getRecipientWiresUrl';
import { UpsellBanner } from '@/bundle/shared/components/UpsellBanner/UpsellBanner';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { useHasOrgAdminRole } from '@/bundle/shared/hooks/useHasOrgAdminRole';
import { useScrollToTop } from '@/hooks/useScrollToTop';
import { RecipientPaymentDetails } from './ui/RecipientPaymentDetails/RecipientPaymentDetails';
import { RecipientProvidePaymentDetails } from './ui/RecipientProvidePaymentDetails/RecipientProvidePaymentDetails';
import { ProxyEditRecipientForm } from './ui/ProxyEditRecipientForm/ProxyEditRecipientForm';
import { TrustStampResponseStatusType } from '@/api/v1/recipient/getRecipientTrustStampStatusApi';
import { RecipientGeneralDetails } from './ui/RecipientGeneralDetails/RecipientGeneralDetails';
import { useLoadTrustStampStatus } from './queries/useLoadTrustStampStatus';
import { getIsEnabledTrustStampDetailsRequest } from './ui/RecipientDocumentVerification/helpers';
import { getRecipientWireDetailsUrl } from './urls/getRecipientWireDetailsUrl';
import { RedirectButton } from '@/components/RedirectButton/RedirectButton';

export const RecipientWireDetailsPage = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { scrollToTop } = useScrollToTop();
  const { hasOrgAdminRole } = useHasOrgAdminRole();
  const location = useLocation();

  const [isEditRecipient, setIsEditRecipient] = useState(false);
  const [trustStampDetails, setTrustStampDetails] = useState<null | TrustStampResponseStatusType>(null);

  const { data: recipientWireData } = useQuery({
    queryKey: ['load_recipient_wire', id],
    queryFn: () => loadRecipientWire(id),
  });

  const recipientWire = recipientWireData?.body;

  const {
    data: trustStampStatusData,
    isPending: isPendingTrustStampData,
    refetch,
  } = useLoadTrustStampStatus(id, recipientWire);

  const wireStatus = recipientWire?.status;
  const isPaymentDetailsProvided = isPaymentDetailsProvidedWireStatus(wireStatus);

  const { cancelInterval, isIntervalRunning } = useInterval(() => {
    if (wireStatus === WIRE_STATUS_DB.PENDING) {
      queryClient.invalidateQueries({ queryKey: ['load_recipient_wire', id] });
    }
  });

  // TODO v12.0.0: this would clear trust stamp details when recipient switching between pages and stale data exists for wrong wire
  useEffect(() => {
    setTrustStampDetails(null);
  }, [id]);

  // Cancel polling logic
  useEffect(() => {
    const shouldCancelInterval = wireStatus && wireStatus !== WIRE_STATUS_DB.PENDING && isIntervalRunning;

    if (shouldCancelInterval) {
      cancelInterval();
    }
  }, [cancelInterval, isIntervalRunning, wireStatus]);

  useEffect(() => {
    if (isPaymentDetailsProvided) {
      scrollToTop();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaymentDetailsProvided]);

  useEffect(() => {
    if (trustStampStatusData?.body) {
      setTrustStampDetails(trustStampStatusData?.body);
    }
  }, [trustStampStatusData?.body]);

  const nagivateToWires = () => {
    navigate(getRecipientWiresUrl());
  };

  const sendUpsellRequest = () => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.UpsellRecipientWireDetails);
  };

  const refetchRecipientWire = () => {
    queryClient.invalidateQueries({ queryKey: ['load_recipient_wire', id] });
  };

  const closeEditRecipientForm = () => {
    setIsEditRecipient(false);
  };

  const proxyEditRecipientSuccess = () => {
    refetchRecipientWire();
    closeEditRecipientForm();
  };

  const proxyEditRecipientOpenEdit = () => {
    setIsEditRecipient(true);
    amplitudeService.logEvent(AMPLITUDE_EVENTS.OutboundWireProxyEditRecipientRedirect);
  };

  const resetTrustStampNextAttemptTimer = () => {
    setTrustStampDetails({
      ...trustStampDetails,
      start_new_session_enable_after_sec: 0,
    });

    queryClient.invalidateQueries({ queryKey: ['load_trust_stamp_status', id] });
  };

  const resetProvidePaymentDeatilsTimer = () => {
    queryClient.invalidateQueries({ queryKey: ['load_trust_stamp_status', id] });
  };

  const startTrustStampVerification = () => {
    refetch();
  };

  const showRecipientPaymentDetails = !!recipientWire?.is_payment_details_exist || isRetryWireStatus(wireStatus);
  const redirectToWire = () => {
    navigate(getRecipientWireDetailsUrl(location.state?.id));
  };

  const hasRedirectButton = location?.state?.id;

  return (
    <ContentLayout
      title='Wire Details'
      backButton='Back to Wires'
      onBack={nagivateToWires}
      banner={isPaymentDetailsProvided && !hasOrgAdminRole && <UpsellBanner onSent={sendUpsellRequest} />}
    >
      <PageBody>
        <PageContent>
          <PageColumn>
            <RecipientGeneralDetails
              wire={recipientWire}
              trustStampDetails={trustStampDetails}
              isEditRecipient={isEditRecipient}
              onProxyEditRecipientOpenEdit={proxyEditRecipientOpenEdit}
            />
          </PageColumn>
          <PageColumn>
            {showRecipientPaymentDetails && <RecipientPaymentDetails wire={recipientWire} />}
            <RecipientProvidePaymentDetails
              wire={recipientWire}
              isLoadingTrustStampDetails={
                getIsEnabledTrustStampDetailsRequest(recipientWire) && isPendingTrustStampData
              }
              trustStampDetails={trustStampDetails}
              onRefetchWire={refetchRecipientWire}
              onStartTrustStamp={startTrustStampVerification}
              onResetTrustStampNextAttemptTimer={resetTrustStampNextAttemptTimer}
              onResetProvidePaymentDetailsTimer={resetProvidePaymentDeatilsTimer}
            />

            {isEditRecipient && (
              <ProxyEditRecipientForm
                wire={recipientWire}
                onClose={closeEditRecipientForm}
                onSave={proxyEditRecipientSuccess}
              />
            )}
          </PageColumn>
        </PageContent>
      </PageBody>
      {hasRedirectButton && <RedirectButton onNavigate={redirectToWire} />}
    </ContentLayout>
  );
};
