import { FormSubmitLoader } from '../../../../../../../components/form/FormSubmitLoader/FormSubmitLoader';
import { LoadPaymentDetailsRow } from './styles';

export const LoadPaymentDetailsLoading = () => {
  return (
    <LoadPaymentDetailsRow>
      <FormSubmitLoader />
      <span>Checking with the WireVault blockchain.</span>
    </LoadPaymentDetailsRow>
  );
};
