import {
  NOT_ALLOWED_WIRE_STATUSES_CANCEL_OUTBOND,
  NOT_ALLOWED_WIRE_STATUSES_EXECUTE,
} from '@/bundle/_OrgAdmin/shared/const/const';
import { Button } from '@/components/Button/Button';
import { RoundedButton } from '@/components/RoundedButton/RoundedButton';
import { isCancelledWireStatus, isPaymentDetailsProvidedWireStatus } from '@/helpers/wireHelpers';
import { AssignedWireType } from '@/types/wireTypes';

type OutboundWireHeaderActionsType = {
  wire: AssignedWireType;
  isLoadingWire: boolean;
  viewPaymentDetails: boolean;
  isShowExecutorForm: boolean;
  onOpenCancelWireDialog: () => void;
  onOpenExecuteWireDialog: () => void;
  onAssignExecutorRedirect: () => void;
  onOpenCreateReplacementWireForm: () => void;
};

export const OutboundWireHeaderActions = ({
  wire,
  isLoadingWire,
  viewPaymentDetails,
  isShowExecutorForm,
  onOpenCancelWireDialog,
  onOpenExecuteWireDialog,
  onAssignExecutorRedirect,
  onOpenCreateReplacementWireForm,
}: OutboundWireHeaderActionsType) => {
  const wireStatus = wire?.status;
  const isCancelledStatus = isCancelledWireStatus(wireStatus);
  const isPaymentDetailsProvided = isPaymentDetailsProvidedWireStatus(wireStatus);
  const isShowedPaymentDetails = wire?.view_payment_details_event_register && wire?.is_payment_details_exist;

  const canCreateWire =
    isCancelledStatus && isShowedPaymentDetails && !wire?.child_wire && !wire?.parent_wire && !isLoadingWire;
  const canCreateReplacementWire = isCancelledStatus && isShowedPaymentDetails && wire?.parent_wire && !isLoadingWire;
  const cannotCreateReplacementWire = isCancelledStatus && isShowedPaymentDetails && wire?.child_wire && !isLoadingWire;
  const canExecuteWire =
    wire?.view_payment_details_event_register && isPaymentDetailsProvided && !isShowExecutorForm && !isLoadingWire;
  const canAssignExecutor =
    !NOT_ALLOWED_WIRE_STATUSES_EXECUTE.includes(wireStatus) &&
    viewPaymentDetails &&
    !isShowExecutorForm &&
    !isLoadingWire;
  const canCancelWire = !NOT_ALLOWED_WIRE_STATUSES_CANCEL_OUTBOND.includes(wireStatus) && !isLoadingWire;

  return (
    <>
      {canCancelWire && (
        <Button width={160} color='secondary' mobileStretch onClick={onOpenCancelWireDialog}>
          Cancel Wire
        </Button>
      )}
      {canAssignExecutor && (
        <Button width={160} onClick={onOpenExecuteWireDialog} mobileStretch>
          Wire Executed
        </Button>
      )}
      {canCreateWire && (
        <Button width={160} color='secondary' onClick={onOpenCreateReplacementWireForm} mobileStretch>
          Create Wire
        </Button>
      )}

      {(canCreateReplacementWire || cannotCreateReplacementWire) && (
        <Button width={160} color='secondary' disabled mobileStretch>
          Create Wire
        </Button>
      )}

      {canExecuteWire && <RoundedButton onClick={onAssignExecutorRedirect}>Assign Executor</RoundedButton>}
    </>
  );
};
