import styled from 'styled-components';
import { COLORS } from '@/styles/colors';
import { poppinsMediumFont, subTitleSmall } from '@/styles/typography';

export const IntermediaryBankBlock = styled.div`
  width: 100%;
  padding: 24px 0 0 0;
  margin: 10px 0 16px 0;
  border-top: 1px solid #bec2cc;
  color: ${COLORS.grey[900]};
  ${poppinsMediumFont};
  ${subTitleSmall};
`;

export const AssignRecipientWireFormWrapper = styled.div`
  width: 100%;
  padding: 28px 28px 36px;
  background: #ffffff;
  border: 1px solid #333539;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
`;

export const AssignExecutorFormWrapper = styled(AssignRecipientWireFormWrapper)``;

export const Separator = styled.div`
  width: 100%;
  margin: 12px 0 20px 0;
  border-top: 1px solid ${COLORS.grey[400]};
`;
