import styled, { createGlobalStyle } from 'styled-components';
import { flex, flexCenter } from '@/styles/layout';
import { COLORS } from '@/styles/colors';
import { bodySmall, poppinsMediumFont, poppinsRegularFont } from '@/styles/typography';

export const DatePickerWrapperStyles = createGlobalStyle`
  .wv-datepicker.react-datepicker {
    border-radius: 24px;
    border: 1px solid ${COLORS.grey[850]};
    background: ${COLORS.white};
    box-shadow: 0 4px 8px 0 rgba(0, 6, 38, 0.2);
    overflow: hidden;
  }

  .react-datepicker-popper {
    z-index: 99;
    padding-top: 10px;
  }

  /* Styles to date picker wrapper */
  .wv-datepicker {
    .react-datepicker__month-container {
      width: 375px;
      padding: 20px;
    }

    .react-datepicker__header {
      padding-top: 0;
      background: ${COLORS.white};
      border: none;
    }

    .react-datepicker__day-names {
      ${flex({ alignItems: 'center', justifyContent: 'center' })};
      height: 40px;
      padding: 5px 0;
      flex: 1 0 0;
      color: ${COLORS.black};
      text-align: center;
      ${poppinsRegularFont};
      ${bodySmall};
      text-transform: uppercase;
      border-top: 1px solid ${COLORS.grey[300]};
      margin-top: -15px;
    }

    .react-datepicker__day-name {
      flex-grow: 1;
    }

    .react-datepicker__month {
      color: ${COLORS.black};
      ${poppinsMediumFont};
      ${bodySmall};
      text-align: center;
      text-transform: uppercase;
    }

    .react-datepicker__week {
      ${flex()};
    }

    .react-datepicker__day {
      ${flexCenter};
      margin: 0;
      height: 40px;
      flex-grow: 1;

      &:hover:not(.react-datepicker__day--selected):not(.react-datepicker__day--disabled) {
        background-color: transparent;
        border: 1px solid ${COLORS.grey[950]};
        border-radius: 12px;
      }
    }

    .react-datepicker__day--outside-month {
      color: ${COLORS.grey[400]};
      text-align: center;
      ${poppinsMediumFont};
      ${bodySmall};
      text-transform: uppercase;
    }

    .react-datepicker__day--selected {
      border-radius: 12px;
      background-color: ${COLORS.grey[950]};
      color: ${COLORS.white};
    }

    .react-datepicker__day--in-selecting-range {
      background-color: transparent;
      color: ${COLORS.grey[950]};
    }

    /* TODO: fix styles w/o using !important in next bugfixes of datepicker */
    .react-datepicker__day--in-range {
      background-color: ${COLORS.grey[200]} !important;
      color: ${COLORS.black} !important;
      border-radius: 0;
    }

    .react-datepicker__day--range-start,
    .react-datepicker__day--range-end {
      background-color: ${COLORS.grey[950]} !important;
      color: ${COLORS.white} !important;
      border-radius: 12px;
    }

    .react-datepicker__day--keyboard-selected {
      background-color: ${COLORS.white};
    }

    .react-datepicker__day--disabled {
      cursor: not-allowed;
    }
  }
`;

export const Wrapper = styled.div`
  ${flex({ flexDirection: 'column' })};
`;

export const ButtonWrapper = styled.div`
  cursor: pointer;
  height: 100%;
  border-radius: 6px;

  &:hover {
    background-color: ${COLORS.grey[200]};
  }
`;
