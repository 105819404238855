import { Box } from '@/components/Box/Box';
import { ReactComponent as UserRoleIcon } from './images/UserRoleIcon.svg';
import { RoleTitle } from './styles';

type UserRoleType = {
  role: string;
};

export const UserRole = ({ role }: UserRoleType) => {
  return (
    <Box display='flex' columnGap='8px'>
      <UserRoleIcon />
      <RoleTitle>{role}</RoleTitle>
    </Box>
  );
};
