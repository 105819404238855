import styled from 'styled-components';
import { COLORS } from '../../../../../styles/colors';

export const DetailsBlockEdit = styled.div`
  cursor: pointer;
  &:hover {
    path {
      transition: fill 0.2s ease;
      fill: ${COLORS.green[500]};
    }
  }
`;
