import {
  CustomSelectInput,
  CustomSelectArrow,
  SelectInnerSpan,
  OptionsWrapper,
  SelectDefaultWrapper,
  SmallSelectInnerSpan,
  SmallCustomSelectArrow,
  SelectSmallWrapper,
  CustomSelectInputBig,
  CustomSelectInputSmall,
  BigSelectInnerSpan,
  BigCustomSelectArrow,
  SelectBigWrapper,
} from './styles';
import { useRef, useState } from 'react';
import { ReactComponent as ArrowDownIcon } from '../../images/arrowDownIcon.svg';
import { ReactComponent as ArrowUpIcon } from '../../images/arrowUpIcon.svg';
import { MultiSelectOptions } from './MultiSelectOptions/MultiSelectOptions';
import { SelectOptions } from './SelectOptions/SelectOptions';

export type SelectVariantType = 'datepicker' | 'multi' | 'single' | 'singlePortal';
export type SelectSizeType = 'default' | 'big' | 'small' | 'date_picker';

export const CUSTOM_SELECT_MAP = {
  default: CustomSelectInput,
  big: CustomSelectInputBig,
  small: CustomSelectInputSmall,
};

const SIZE_SELECT_MAP = {
  default: SelectDefaultWrapper,
  big: SelectBigWrapper,
  small: SelectSmallWrapper,
};

type SelectType = {
  placeholder?: string;
  options: any;
  value: any;
  variant?: SelectVariantType;
  onChange: any;
  onBlur?: any;
  meta?: any;
  config?: any;
  disabled?: boolean;
  hasError?: boolean;
  field?: any;
  isShowValueInInput?: boolean;
  hasValue?: boolean;
  inputVariant?: string;
  sizeVariant?: SelectSizeType;
  optionsHeight?: number;
  name?: string;
};

export const CustomSelect = ({
  placeholder,
  options,
  value,
  variant = 'single',
  onChange,
  onBlur,
  disabled,
  hasError,
  isShowValueInInput,
  hasValue = !!value?.length,
  inputVariant = 'default',
  sizeVariant = 'default',
  optionsHeight,
  name,
}: SelectType) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();

  const isOpenHandler = () => {
    setIsOpen(!isOpen);
  };

  const closeSelect = () => {
    setIsOpen(false);
  };

  const onChangeSingleHandler = (currentValue: any) => {
    setIsOpen(false);
    onChange(currentValue);
  };

  const selectedOption = options?.find((option) => {
    return option.value === value;
  });

  const valueText = selectedOption ? selectedOption?.label : placeholder;

  const valueSelect = isShowValueInInput ? selectedOption : valueText;

  const ref2: any = useRef();

  const CustomSelectInputCmp = CUSTOM_SELECT_MAP[inputVariant];
  const CustomSelectWrapperCmp = SIZE_SELECT_MAP[sizeVariant];

  return (
    <CustomSelectWrapperCmp ref={ref}>
      <CustomSelectInputCmp
        id={name}
        value={valueSelect}
        onBlur={onBlur}
        placeholder={placeholder}
        readOnly
        onClick={isOpenHandler}
        isDisabled={disabled}
        hasError={hasError}
        hasValue={hasValue}
      />
      {sizeVariant === 'default' && (
        <SelectInnerSpan>
          <CustomSelectArrow onClick={isOpenHandler}>
            {isOpen && !disabled ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </CustomSelectArrow>
        </SelectInnerSpan>
      )}
      {sizeVariant === 'big' && (
        <BigSelectInnerSpan>
          <BigCustomSelectArrow onClick={isOpenHandler}>
            {isOpen && !disabled ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </BigCustomSelectArrow>
        </BigSelectInnerSpan>
      )}
      {sizeVariant === 'small' && (
        <SmallSelectInnerSpan>
          <SmallCustomSelectArrow onClick={isOpenHandler}>
            {isOpen && !disabled ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </SmallCustomSelectArrow>
        </SmallSelectInnerSpan>
      )}
      {isOpen && !disabled && (
        <OptionsWrapper ref={ref2} height={optionsHeight}>
          {variant === 'multi' && (
            <MultiSelectOptions
              ref={ref}
              onClose={closeSelect}
              options={options}
              onChange={onChange}
              values={value}
              optionsHeight={optionsHeight}
            />
          )}
          {variant === 'single' && (
            <SelectOptions
              ref={ref}
              onClose={closeSelect}
              options={options}
              onChange={onChangeSingleHandler}
              value={value}
            />
          )}
        </OptionsWrapper>
      )}
    </CustomSelectWrapperCmp>
  );
};
