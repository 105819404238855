import { IntermediaryBankBlock } from '@/bundle/_OrgAdmin/pages/InboundWire/styles';
import { LoadPaymentDetailsBlockhainUnavailable } from '@/bundle/_OrgAdmin/pages/OutboundWire/_Details/ui/LoadPaymentDetailsWidget/LoadPaymentDetailsBlockhainUnavailable';
import { LoadPaymentDetailsLoading } from '@/bundle/_OrgAdmin/pages/OutboundWire/_Details/ui/LoadPaymentDetailsWidget/LoadPaymentDetailsLoading';
import { PaymentDetailsWrapper } from './styles';
import { ResponseErrorType } from '@/types/sharedTypes';
import {
  getHasIntermediaryBank,
  getPaymentDetailsConfig,
  getPaymentDetailsIntermediaryBankConfig,
} from '@/bundle/shared/helpers/configHelpers';
import { DetailsBlockGenerator } from '@/components/DetailsBlockGenerator/DetailsBlockGenerator';
import { DetailsWidget } from '@/components/DetailsWidget/DetailsWidget';
import { PaymentDetailsGeneralType } from '@/types/paymentDetailsTypes';
import { Notification } from '@/components/Notification/Notification';

type WirePaymentDetailsType = {
  paymentDetails?: PaymentDetailsGeneralType;
  isLoading: boolean;
  error?: ResponseErrorType;
};

export const WirePaymentDetails = ({ paymentDetails, isLoading, error }: WirePaymentDetailsType) => {
  const hasDetails = !isLoading && !error;
  const hasBlockchainDetails = hasDetails && paymentDetails?.is_blockchain_payment_details_data_from_bc;
  const hasDatabaseDetails = hasDetails && paymentDetails?.is_blockchain_payment_details_data_from_bc === false;

  const paymentDetailsConfig = getPaymentDetailsConfig(paymentDetails);
  const hasIntermediaryBank = getHasIntermediaryBank(paymentDetails);
  const paymentDetailsIntermediaryBankConfig = getPaymentDetailsIntermediaryBankConfig(paymentDetails);

  return (
    <PaymentDetailsWrapper>
      <DetailsWidget header='Wire Info' headerOffset={12}>
        {isLoading && <LoadPaymentDetailsLoading />}

        {hasBlockchainDetails && (
          <Notification variant='success' fontVariant='medium' verticalAlign='center' mb='20px'>
            Stored in the WireVault blockchain.
          </Notification>
        )}
        {hasDatabaseDetails && <LoadPaymentDetailsBlockhainUnavailable />}
        <div>
          <DetailsBlockGenerator configuration={paymentDetailsConfig} />
          {hasIntermediaryBank && (
            <>
              <IntermediaryBankBlock>Intermediary Bank</IntermediaryBankBlock>
              <DetailsBlockGenerator configuration={paymentDetailsIntermediaryBankConfig} />
            </>
          )}
        </div>
      </DetailsWidget>
    </PaymentDetailsWrapper>
  );
};
