import { BlockWrapper, Wrapper } from '../styles';
import {
  DetailsBlockRowName,
  DetailsBlockRowValue,
  DetailsBlockRow,
  DetailsBlockColumn,
  DetailsBlockRowValueBig,
  DetailsBlockRowValueBold,
  DetailsBlockRowNameWrapper,
} from './styles';
import { ReactComponent as StatusHintIcon } from '../../images/StatusHintIcon.svg';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { DetailsRowNotes } from '../DetailsWidget/DetailsRowNotes/DetailsRowNotes';

type DetailsType = {
  columns?: number;
  columnsWidth?: string;
  configuration: {};
  onClick?: () => void;
};

export const CUSTOM_ROW_VALUE_MAP = {
  default: DetailsBlockRowValue,
  big: DetailsBlockRowValueBig,
  bold: DetailsBlockRowValueBold,
};

export const DetailsBlockGenerator = ({ columns, columnsWidth, configuration, onClick }: DetailsType) => {
  return (
    <BlockWrapper columns={columns} columnsWidth={columnsWidth}>
      {Object.keys(configuration).map((key, index) => {
        const customKey = `${key}_${index}`;
        const config = configuration[key];

        if (config?.isRowHidden) return null;

        const DetailsBlockComponent = config?.component;

        const DetailsBlockRowValueCmp = config?.valueVariant
          ? CUSTOM_ROW_VALUE_MAP[config?.valueVariant]
          : CUSTOM_ROW_VALUE_MAP.default;

        switch (config.type) {
          case 'component':
            return (
              <Wrapper key={customKey} gridColumn={config.gridColumn}>
                <DetailsBlockRow hasBorder={config.hasBorder}>
                  <DetailsBlockColumn>
                    <DetailsBlockRowNameWrapper>
                      <DetailsBlockRowName>{config.label}</DetailsBlockRowName>
                      {config.hintText && (
                        <>
                          <StatusHintIcon data-tooltip-id='status-tooltip' />
                          <Tooltip id='status-tooltip' offset={5} place='top-start'>
                            {config.hintText}
                          </Tooltip>
                        </>
                      )}
                    </DetailsBlockRowNameWrapper>
                    {config.value && (
                      <DetailsBlockRowValueCmp title={config.value}>{config.value}</DetailsBlockRowValueCmp>
                    )}
                  </DetailsBlockColumn>
                  {config?.component && <DetailsBlockComponent onClick={onClick} />}
                </DetailsBlockRow>
              </Wrapper>
            );

          case 'hint':
            const HintComponent = config.component || null;

            if (!HintComponent) return null;

            return (
              <Wrapper key={customKey} gridColumn={config.gridColumn}>
                <DetailsBlockRow>
                  <HintComponent />
                </DetailsBlockRow>
              </Wrapper>
            );

          case 'notes':
            return (
              <Wrapper key={customKey} gridColumn={config.gridColumn}>
                <DetailsRowNotes name='Notes'>{config?.value}</DetailsRowNotes>
              </Wrapper>
            );

          default:
            return (
              <Wrapper key={customKey} gridColumn={config.gridColumn}>
                <DetailsBlockRow hasBorder={config.hasBorder} gradient={config.gradient} title={config.title}>
                  <DetailsBlockRowName>{config.label}</DetailsBlockRowName>
                  <DetailsBlockRowValueCmp title={config.title || config.value}>{config.value}</DetailsBlockRowValueCmp>
                </DetailsBlockRow>
              </Wrapper>
            );
        }
      })}
    </BlockWrapper>
  );
};
