import styled from 'styled-components';
import { COLORS } from '@/styles/colors';
import { bodySmall, poppinsRegularFont } from '@/styles/typography';
import { flex } from '@/styles/layout';

export const NoOptionsComponentWrapper = styled.div`
  ${flex({ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' })};
  row-gap: 20px;
  padding: 11px 0 11px 0;
`;

export const Message = styled.span`
  ${poppinsRegularFont};
  ${bodySmall};
  color: ${COLORS.black};
`;

export const LabelWrapper = styled.div`
  ${flex({ justifyContent: 'space-between' })}
`;

export const Label = styled.div`
  ${poppinsRegularFont};
  ${bodySmall};
  color: ${COLORS.black};
  margin-bottom: 4px;
`;
