import { FilterTypes } from '../ui/FilterList/_filters/FilterTypes';

export interface IBilledEventsParams {
  wire__contract__name?: string;
  organization__id?: string;
  date_created_after?: string;
  date_created_before?: string;
}

export const filterListConfig = (organizationOptions) => {
  return [
    {
      type: FilterTypes.MULTI_SELECT_BY_ID,
      name: 'organization__id',
      placeholder: 'Organization Name',
      options: organizationOptions,
      width: 260,
      optionsHeight: 204,
    },
    {
      type: FilterTypes.DATE_PICKER,
      name: 'date_of_view',
      nameFrom: 'date_created_after',
      nameTo: 'date_created_before',
      placeholder: 'Date of View',
      width: 320,
    },
    {
      type: FilterTypes.SEARCH,
      name: 'wire__contract__name',
      placeholder: 'Transaction Name',
      width: 260,
    },
  ];
};
