import { useNavigate } from 'react-router-dom';
import { ContentLayout } from '../../../Layouts/ContentLayout/ContentLayout';
import { NoResults } from '@/components/NoResults/NoResults';
import { ReactComponent as NoDepositAccountsIcon } from '../../../../images/NoItemsIcon.svg';
import { PageBody } from '@/components/Page/PageBody/PageBody';
import { Paginator } from '@/components/Paginator/Paginator';
import { PageFooter } from '@/components/Page/PageFooter/PageFooter';
import { DepositAccountsList } from './ui/DepositAccountsList';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { usePrepareQueryParams } from '@/helpers/apiHelpers/queryHelper';
import { loadDepositAccounts } from './api';
import { getCreateDepositAccountUrl } from './_Create/urls/getCreateDepositAccountUrl';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { Button } from '@/components/Button/Button';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { ReactComponent as PlusIcon } from '@/images/PlusIcon.svg';

export const DepositAccountsPage = () => {
  const navigate = useNavigate();
  const queryParams = usePrepareQueryParams();

  const { data, isPending } = useQuery({
    queryKey: ['load_deposit_accounts', queryParams],
    queryFn: () => loadDepositAccounts(queryParams),
    placeholderData: keepPreviousData,
  });

  const navigateToCreateAccount = () => {
    navigate(getCreateDepositAccountUrl());

    amplitudeService.logEvent(AMPLITUDE_EVENTS.CreateDepositAccountStartRedirect);
  };

  const depositAccounts = data?.body?.results;
  const hasDepositAccounts = !!depositAccounts?.length;
  const totalCount = data?.body?.count;
  const hasPaginator = totalCount > queryParams?.limit;

  return (
    <ContentLayout
      title='Deposit Accounts'
      headerAction={
        <Button width={191} mobileStretch icon={<PlusIcon />} iconPosition='left' onClick={navigateToCreateAccount}>
          Create Account
        </Button>
      }
      subtitle={'Manage the deposit accounts available for inbound wires for your organization.'}
    >
      <PageBody>
        {hasDepositAccounts && <DepositAccountsList list={depositAccounts} />}

        {!hasDepositAccounts && !isPending && (
          <NoResults
            marginTop={128}
            icon={<NoDepositAccountsIcon />}
            message='Please create your first deposit account.'
          />
        )}
      </PageBody>

      {hasPaginator && (
        <PageFooter>
          <Paginator page={queryParams.page} limit={queryParams.limit} total={totalCount} />
        </PageFooter>
      )}
    </ContentLayout>
  );
};
