import { LoadPaymentDetailsWidget } from '@/bundle/_OrgAdmin/pages/OutboundWire/_Details/ui/LoadPaymentDetailsWidget/LoadPaymentDetailsWidget';
import { DetailsWidget } from '@/components/DetailsWidget/DetailsWidget';
import { DetailsBlockGenerator } from '@/components/DetailsBlockGenerator/DetailsBlockGenerator';
import { IntermediaryBankBlock } from '@/bundle/_OrgAdmin/pages/OutboundWire/_Details/styles';
import { AssignedWireType } from '@/types/wireTypes';
import { isRecipientChainProxy, isRecipientProxy } from '@/helpers/wireHelpers';
import { PaymentDetailsGeneralType } from '@/types/paymentDetailsTypes';
import {
  getHasIntermediaryBank,
  getPaymentDetailsConfig,
  getPaymentDetailsIntermediaryBankConfig,
} from '@/bundle/shared/helpers/configHelpers';
import { PaymentDetailsHeaderSlot } from '@/bundle/shared/components/PaymentDetailsHeaderSlot/PaymentDetailsHeaderSlot';
import { getPaymentDetailsHeaderSlot } from './const';
import capitalize from 'lodash/capitalize';
import { PaymentDetailsCollectionType } from './styles';

interface OutboundWirePaymentDetailsType {
  wire: AssignedWireType;
  paymentDetails: PaymentDetailsGeneralType;
  isLoadingPaymentDetails: boolean;
  isShowPaymentDetails: boolean;
}

export const OutboundWirePaymentDetails = ({
  wire,
  isShowPaymentDetails,
  isLoadingPaymentDetails,
  paymentDetails,
}: OutboundWirePaymentDetailsType) => {
  const hasIntermediaryBank = getHasIntermediaryBank(paymentDetails);
  const paymentDetailsConfig = getPaymentDetailsConfig(paymentDetails);
  const paymentDetailsIntermediaryBankConfig = getPaymentDetailsIntermediaryBankConfig(paymentDetails);
  const isProxy = isRecipientProxy(wire);
  const isChainProxy = isRecipientChainProxy(wire);
  const headerSlot = getPaymentDetailsHeaderSlot(isChainProxy, isProxy);
  const paymentDetailsCollectionType = capitalize(wire?.payment_details_collection_type);

  return (
    <>
      {isShowPaymentDetails && (
        <div>
          {isLoadingPaymentDetails && (
            <DetailsWidget header='Wire Info' headerOffset={12}>
              <LoadPaymentDetailsWidget isLoading={isLoadingPaymentDetails} />
            </DetailsWidget>
          )}
          {paymentDetails && !isLoadingPaymentDetails && (
            <DetailsWidget
              header='Wire Info'
              headlineSlot={headerSlot && <PaymentDetailsHeaderSlot {...headerSlot} />}
              action={<PaymentDetailsCollectionType>{paymentDetailsCollectionType}</PaymentDetailsCollectionType>}
            >
              <LoadPaymentDetailsWidget paymentDetails={paymentDetails} />
              <DetailsBlockGenerator configuration={paymentDetailsConfig} />
              {hasIntermediaryBank && (
                <>
                  <IntermediaryBankBlock>Intermediary Bank</IntermediaryBankBlock>
                  <DetailsBlockGenerator configuration={paymentDetailsIntermediaryBankConfig} />
                </>
              )}
            </DetailsWidget>
          )}
        </div>
      )}
    </>
  );
};
