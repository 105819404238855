import { JSONError } from '@/bundle/Error/classes/JSONError';
import { RequiredAuthError } from '@/bundle/Error/classes/RequiredAuthError';
import { ResponseError } from '@/bundle/Error/classes/ResponseError';
import { ApiResponseType, RequestOptions } from '@/types/sharedTypes';
import { urlApiHelper } from './helpers/urlApiHelpers';
import { AuthTokensType, authTokenService } from '@/bundle/Auth/LoginFlow/LoginPage/service/authTokenService';
import {
  ACCESS_DENIED_RESPONSE,
  BLOCKED_USER_RESPONSE,
  DEFAULT_LANGUAGE,
  EXPIRED_TOKE_CODE,
  HTTP_FORBIDDEN_STATUS,
  HTTP_METHOD,
  HTTP_SUCCESS_STATUS,
  HTTP_UNAUTHORIZED_STATUS,
  INVALID_TOKEN_RESPONSE,
} from './const';
import { BlockedUserError } from '@/bundle/Error/classes/BlockedUserError';
import { AccessDeniedError } from '@/bundle/Error/classes/AccessDeniedError';
import { TOKEN_REFRESHED_EVENT } from '@/const/shared';

let refreshingTokenProcess = null;
const tokenRefreshedEvent = new CustomEvent(TOKEN_REFRESHED_EVENT);

const getContentType = (headers: Headers) => {
  return headers.get('content-type');
};

const buildOptions = async (headers: HeadersInit = {}) => {
  const tokens = authTokenService.getTokens();

  const options: RequestInit = {
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': DEFAULT_LANGUAGE,
      authorization: `Bearer ${tokens?.access}`,
      ...headers,
    },
    credentials: 'include',
  };

  return options;
};

const parseResponse = async <T>(response: Response): Promise<T> => {
  const contentType = getContentType(response.headers);
  let content;

  try {
    if (contentType === 'application/json') {
      content = await response.json();
    } else {
      content = response;
    }

    return content;
  } catch (error) {
    throw new ResponseError();
  }
};

export const authApiService = {
  async GET<T>(uri: string, params?: unknown): Promise<ApiResponseType<T>> {
    const response = await authApiService.makeRequest<T>(HTTP_METHOD.GET, uri, null, params);

    return response;
  },

  async POST<T>(
    uri: string,
    data: unknown,
    params?: unknown,
    requestOptions?: RequestOptions
  ): Promise<ApiResponseType<T>> {
    const response = await authApiService.makeRequest<T>(HTTP_METHOD.POST, uri, data, params, requestOptions);

    return response;
  },

  async PATCH<T>(
    uri: string,
    data: unknown,
    params?: unknown,
    requestOptions?: RequestOptions
  ): Promise<ApiResponseType<T>> {
    const response = await authApiService.makeRequest<T>(HTTP_METHOD.PATCH, uri, data, params, requestOptions);

    return response;
  },

  async PUT<T>(uri: string, data: unknown, params?: unknown): Promise<ApiResponseType<T>> {
    const response = await authApiService.makeRequest<T>(HTTP_METHOD.PUT, uri, data, params);

    return response;
  },

  async DELETE<T>(uri: string) {
    const response = await authApiService.makeRequest<T>(HTTP_METHOD.DELETE, uri, null);

    return response;
  },

  async GET_XLS<T>(uri: string, params?: unknown): Promise<ApiResponseType<T>> {
    const url = urlApiHelper(uri, params);
    const options = await buildOptions({
      'Content-Type': 'application/vnd.ms-excel',
    });
    const response = await fetch(url, options);
    const data = await authApiService.handleResponse(response, null, {
      method: HTTP_METHOD.GET,
      uri,
      params,
      payload: null,
    });

    if (data.response?.status !== HTTP_SUCCESS_STATUS) {
      throw new Error('Download XLS error. Try again later');
    }

    const blobData = await response.blob();

    return {
      response,
      json: blobData as T,
    };
  },

  async UPLOAD_FILE(uri: string, data: any, params?: any) {
    const tokens = authTokenService.getTokens();
    const url = urlApiHelper(uri, params);

    const options = {
      authorization: `Bearer ${tokens?.access}`,
    };

    const response = await fetch(url, {
      method: HTTP_METHOD.POST,
      headers: options,
      body: data,
      credentials: 'include',
    });
    const contentType = getContentType(response.headers);

    if (contentType !== 'application/json') {
      throw new JSONError();
    }

    const res = await response.json();

    return res;
  },

  async makeRequest<T>(
    method: string,
    uri: string,
    payload: unknown,
    params: unknown = null,
    requestOptions = {} as RequestOptions
  ): Promise<ApiResponseType<T>> {
    const url = urlApiHelper(uri, params);
    const { headers, ...restOptions } = await buildOptions();
    const body = method === HTTP_METHOD.GET ? null : JSON.stringify(payload);

    const response = await fetch(url, {
      method,
      headers: { ...headers, ...requestOptions.requestHeaders },
      ...(body && { body }),
      ...restOptions,
    });

    const parsedData = await parseResponse<T>(response);

    const data = await authApiService.handleResponse(response, parsedData, { method, uri, payload, params });

    return data;
  },

  async handleResponse<T>(response: Response, json: T, { method, uri, payload, params }): Promise<ApiResponseType<T>> {
    const isTokenExpired = (json as any)?.code === EXPIRED_TOKE_CODE;
    const { status } = response;

    if (status === HTTP_UNAUTHORIZED_STATUS && isTokenExpired) {
      const newResponse = await authApiService.refreshToken(method, uri, payload, params);

      return newResponse;
    }

    if (status === HTTP_UNAUTHORIZED_STATUS) {
      throw new RequiredAuthError();
    }

    if (status === HTTP_FORBIDDEN_STATUS && json === ACCESS_DENIED_RESPONSE) {
      throw new AccessDeniedError();
    }

    if (status === HTTP_FORBIDDEN_STATUS && json === BLOCKED_USER_RESPONSE) {
      throw new BlockedUserError();
    }

    return { response, json };
  },

  async renewToken(tokens: AuthTokensType) {
    const url = urlApiHelper('/users/refresh-tokens/');
    const body = { refresh: tokens?.refresh };
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(body),
    });
    const contentType = getContentType(response.headers);

    if (contentType !== 'application/json') {
      refreshingTokenProcess = null;

      throw new JSONError();
    }

    const json = await response.json();

    if (json === INVALID_TOKEN_RESPONSE) {
      refreshingTokenProcess = null;

      throw new RequiredAuthError(json);
    }

    if (response?.ok) {
      const newTokens = { ...tokens, ...json };

      authTokenService.setTokens(newTokens);
      refreshingTokenProcess = null;

      window.dispatchEvent(tokenRefreshedEvent);

      return;
    }

    refreshingTokenProcess = null;

    throw new RequiredAuthError(json);
  },

  async refreshToken<T>(originalMethod, uri, payload, params) {
    const tokens = authTokenService.getTokens();

    if (!tokens?.user_id || !tokens?.refresh) {
      throw new RequiredAuthError();
    }

    if (!refreshingTokenProcess) {
      refreshingTokenProcess = authApiService.renewToken(tokens);
    }

    await refreshingTokenProcess;

    const originalRequestResponse = await authApiService.makeRequest(originalMethod, uri, payload, params);

    return originalRequestResponse as T;
  },
};
