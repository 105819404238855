import styled, { css } from 'styled-components';
import { body, poppinsRegularFont } from '../../../styles/typography';
import { COLORS } from '../../../styles/colors';
import { flex } from '../../../styles/layout';

export const SelectOptionsStyles = css`
  position: absolute;
  width: 100%;
  max-width: 100%;
  max-height: 499px;
  background: #ffffff;
  border: 1px solid ${COLORS.grey[850]};
  border-radius: 10px;
  padding: 5px 0;
  z-index: 2;
  box-shadow: 0 2px 20px rgba(63, 77, 90, 0.25);
  overflow-wrap: anywhere;
`;

export const SelectOptionsWrapper = styled.div`
  ${SelectOptionsStyles};
  overflow: auto;
  padding: 8px 10px;
`;

export const SelectOptionsItem = styled.div<{
  isSelected: boolean;
}>`
  ${flex({ alignItems: 'center', justifyContent: 'space-between' })}
  cursor: pointer;
  color: ${COLORS.black};
  grid-gap: 10px;
  padding: 9px 6px;
  ${poppinsRegularFont};
  ${body};
  white-space: pre-wrap;

  &:hover {
    background: ${COLORS.grey[200]};
    border-radius: 6px;
  }
`;
