import styled from 'styled-components';
import { flex } from '../../styles/layout';
import { COLORS } from '../../styles/colors';
import {
  body,
  bodySmall,
  caption,
  poppinsMediumFont,
  poppinsRegularFont,
  poppinsSemiBoldFont,
  subTitle,
} from '../../styles/typography';
import { ellipsis } from '../../styles/common';
import { media } from '@/styles/responsive';
import { THEME } from '@/styles/theme';

export const ContentWrapper = styled.div<{
  ratio: number;
  width: string;
}>`
  ${flex({ flexDirection: 'column' })};
  flex: 1;
  min-width: 0;

  ${({ ratio }) =>
    ratio &&
    `
    flex: ${ratio};
  `};

  ${({ width }) =>
    width &&
    `
    width: ${width};
  `};

  ${media.mobile`
    ${flex({ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' })};
  `}
`;

export const ContentKey = styled.div`
  ${poppinsRegularFont};
  ${bodySmall};
  color: ${COLORS.grey[600]};
  flex-shrink: 0;
`;

export const ContentValue = styled.div`
  ${poppinsMediumFont};
  ${body};
  color: ${COLORS.black};
  ${ellipsis};

  ${media.mobile`
    margin-left: 8px;
  `}
`;

export const CardWrapper = styled.div`
  position: relative;
  ${flex({ flexDirection: 'column' })};
  padding: 20px;
  border-radius: 16px;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.grey[400]};
  margin-bottom: 16px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: ${COLORS.grey[200]};
  }

  ${media.mobile`
    padding: ${THEME.offset.mobile};
  `}
`;

export const CardMarker = styled.div`
  position: absolute;
  color: ${COLORS.white};
  top: 0;
  left: 0;
  padding: 0px 12px;
  ${caption};
  ${poppinsSemiBoldFont};
  border-radius: 16px 0px 16px 0px;
  background-color: ${COLORS.grey[700]};
`;

export const CardHeading = styled.div`
  ${flex({ justifyContent: 'space-between', alignItems: 'center' })};

  ${media.mobile`
    ${flex({ flexDirection: 'column', alignItems: 'flex-start' })};
  `}
`;

export const CardHeader = styled.div`
  ${poppinsMediumFont};
  ${subTitle};
  ${ellipsis};
  color: ${COLORS.black};
  white-space: pre-wrap;
`;

export const CardMeta = styled.div`
  margin-left: 20px;

  ${media.mobile`
    margin-top: 8px;
    margin-left: 0;
  `}
`;

export const CardColumns = styled.div`
  margin-top: 12px;
  ${flex()};
  gap: 20px;

  ${media.mobile`
    flex-direction: column;
    gap: 4px;
  `}
`;
