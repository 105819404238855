import PhoneInput from 'react-phone-input-2';
import styled from 'styled-components';
import { body, bodySmall, poppinsRegularFont } from '@/styles/typography';
import { COLORS } from '@/styles/colors';
import { customVerticalScrollBase } from '@/styles/common';
import pathToIcon from '../../../SelectNew/images/SelectedIcon.svg';
import arrowIconPath from './images/ArrowIcon.svg';
import { flex } from '@/styles/layout';

export const Label = styled.div`
  ${bodySmall};
  color: ${COLORS.grey[950]};
  ${poppinsRegularFont};
`;

export const StyledPhoneInput = styled(PhoneInput)<{ error: boolean }>`
  &:hover {
    .form-control {
      border-color: ${COLORS.grey[850]};
    }

    .flag-dropdown {
      border-color: ${COLORS.grey[850]};
    }
  }

  :focus-within {
    box-shadow: 0 0 2px 0 rgba(0, 134, 111, 0.6);
    border-radius: 10px;

    .form-control {
      border: 1px solid ${COLORS.green[500]};
      background-color: ${COLORS.white};
    }

    .flag-dropdown {
      border: 1px solid ${COLORS.green[500]};
      background-color: ${COLORS.white};
    }
  }

  .form-control {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    padding: 13px 16px 13px 91px;
    color: ${COLORS.black};
    ${poppinsRegularFont};
    ${body};
    background-color: ${COLORS.grey[250]};
    border: 1px solid ${COLORS.grey[400]};
    border: ${({ error }) => error && `1px solid ${COLORS.red[600]}`};

    ::placeholder {
      color: ${COLORS.grey[600]};
    }
  }

  .flag-dropdown {
    width: 80px;
    border-right: 1px solid ${COLORS.grey[400]};
    border-radius: 10px 0 0 10px !important;
    background-color: ${COLORS.grey[250]};
    border: ${({ error }) => error && `1px solid ${COLORS.red[600]}`};

    .selected-flag {
      width: 100%;
      padding-left: 16px;
      border-radius: 0 !important;
      background-color: transparent !important;

      .arrow {
        border: none;
        margin-top: -8px;
        width: 20px;
        height: 20px;
        background-image: url(${arrowIconPath});

        &.up {
          transform: rotate(180deg);
        }
      }
    }
  }

  .country-list {
    ${poppinsRegularFont};
    ${body};
    color: ${COLORS.black};
    border: 1px solid ${COLORS.grey[850]};
    border-radius: 10px;
    ${customVerticalScrollBase};

    .country {
      ${flex()};
      padding: 9px 10px 9px 50px;

      &.highlight {
        background-color: transparent;

        &::after {
          content: '';
          flex-shrink: 0;
          display: block;
          height: 20px;
          width: 20px;
          background-image: url(${pathToIcon});
          margin-left: auto;
        }
      }

      &:hover {
        background-color: ${COLORS.grey[200]};
      }

      .dial-code {
        color: ${COLORS.grey[600]};
      }
    }

    .flag {
      left: 16px;
      top: 8px;
    }

    .country-name {
      margin-right: 12px;
    }
  }
`;
