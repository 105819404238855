import styled from 'styled-components';

export const EyeSvgWrapper = styled.div`
  position: absolute;
  bottom: 16px;
  right: 16px;
  cursor: pointer;
`;

export const TicksWrapper = styled.div`
  position: absolute;
  bottom: 16px;
  right: 46px;
`;
