import {
  UserDetailsCard,
  UserDetailsRow,
  UserDetailsRowTitle,
  UserDetailsRowValue,
} from '@/bundle/_Opco/shared/styles';
import { ReactComponent as UserIcon } from '@/bundle/_Opco/_OpcoUsersPage/Details/images/UserIcon.svg';
import { Box } from '@/components/Box/Box';
import {
  EditIconBlock,
  UserDetailsEditableRow,
} from '@/bundle/_Opco/ui/OrganizationUsers/Details/ui/OrganizationUserAccountDetails/styles';
import { ReactComponent as EditIcon } from '@/images/EditIcon.svg';
import { UserDetailsHeader } from './UserDetailsHeader/UserDetailsHeader';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';

export interface PersonalDetailsType {
  first_name?: string;
  middle_name?: string;
  last_name?: string;
}

interface UserPersonalInfoType {
  onOpen: (arg: boolean) => void;
  personalDetails: PersonalDetailsType;
  isEdit: boolean;
}

export const UserPersonalInfo = ({ onOpen, personalDetails, isEdit }: UserPersonalInfoType) => {
  const openEdit = () => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.ProfileEditPersonalInfoRedirect);

    onOpen(true);
  };

  return (
    <Box flexDirection='column' width='100%'>
      <UserDetailsHeader title='Personal Info' icon={<UserIcon />}></UserDetailsHeader>
      <UserDetailsCard>
        <UserDetailsRow>
          <Box flexDirection='row' justifyContent='space-between'>
            <UserDetailsEditableRow>
              <UserDetailsRowTitle>First Name</UserDetailsRowTitle>
              <UserDetailsRowValue>{personalDetails?.first_name || '-'}</UserDetailsRowValue>
            </UserDetailsEditableRow>
            {isEdit && (
              <EditIconBlock onClick={openEdit}>
                <EditIcon />
              </EditIconBlock>
            )}
          </Box>
        </UserDetailsRow>
        <UserDetailsRow>
          <UserDetailsRowTitle>Middle Name</UserDetailsRowTitle>
          <UserDetailsRowValue>{personalDetails?.middle_name || '-'}</UserDetailsRowValue>
        </UserDetailsRow>
        <UserDetailsRow>
          <UserDetailsRowTitle>Last Name</UserDetailsRowTitle>
          <UserDetailsRowValue>{personalDetails?.last_name || '-'}</UserDetailsRowValue>
        </UserDetailsRow>
      </UserDetailsCard>
    </Box>
  );
};
