import { ReactNode, useState } from 'react';
import { useField } from 'formik';
import { EyeSvgWrapper, TicksWrapper } from './styles';
import { InputError } from '../InputError/InputError';
import { ReactComponent as EyeInputActive } from '../../../../images/EyeInputActive.svg';
import { ReactComponent as EyeInput } from '../../../../images/EyeInput.svg';
import { CustomInputWrapper, Label, FormikInputWrapper, IconWrapper, LabelWrapper } from '../FormikInput/styles';
import { INPUT_MAP } from '../FormikInput/FormikInput';
import { ReactComponent as ConfirmInputTicks } from '../../../../images/ConfirmInputTicks.svg';
import { getFieldError } from '@/helpers/formHelpers/formHelpers';

type FormikPasswordInputType = {
  label?: string;
  name?: string;
  errorsApi?: any;
  disabled?: boolean;
  width?: number;
  placeholder?: string;
  maxLength?: number;
  minWidth?: number;
  iconRight?: any;
  isCopyPasteDisabled?: boolean;
  subLabel?: ReactNode;
  variant?: string;
  autoComplete?: string;
  isConfirmTicks?: boolean;
};

export const FormikPasswordInput = ({
  label,
  name,
  errorsApi,
  disabled,
  width,
  placeholder,
  maxLength,
  minWidth,
  iconRight,
  isCopyPasteDisabled,
  subLabel,
  variant = 'default',
  autoComplete = 'off',
  isConfirmTicks,
}: FormikPasswordInputType) => {
  const [field, meta, { setValue }] = useField(name);
  const [isVisibleSymbols, setIsVisibleSymbols] = useState(false);

  const { hasAnyError, apiError, error } = getFieldError(name, meta, errorsApi);
  const hasConfirmTicks = isConfirmTicks && field.value?.trim() && !meta.error;

  const onChangeHandler = (event: any) => {
    const value = event.target.value;

    return setValue(value);
  };

  const changeVisibleOfSymbols = () => {
    return setIsVisibleSymbols(!isVisibleSymbols);
  };

  const onCopyPasteHandler = (event: any) => {
    if (isCopyPasteDisabled) {
      event.preventDefault();
    }
  };

  const InputComponent = INPUT_MAP[variant];

  return (
    <FormikInputWrapper width={width} minWidth={minWidth}>
      <LabelWrapper>
        <Label>{label}</Label>
        {subLabel}
      </LabelWrapper>
      <CustomInputWrapper>
        <InputComponent
          {...field}
          onChange={onChangeHandler}
          disabled={disabled}
          placeholder={placeholder}
          maxLength={maxLength}
          hasError={hasAnyError}
          variant={variant}
          autoComplete={autoComplete}
          type={isVisibleSymbols ? 'text' : 'password'}
          onPaste={onCopyPasteHandler}
          onCopy={onCopyPasteHandler}
          hasConfirmTicks={hasConfirmTicks}
          hasToggleEye={true}
        />
        {!!iconRight && <IconWrapper>{iconRight}</IconWrapper>}

        {hasConfirmTicks && (
          <TicksWrapper>
            <ConfirmInputTicks />
          </TicksWrapper>
        )}
        <EyeSvgWrapper onClick={changeVisibleOfSymbols}>
          {isVisibleSymbols ? <EyeInputActive /> : <EyeInput />}
        </EyeSvgWrapper>
      </CustomInputWrapper>
      <InputError error={error} apiError={apiError} />
    </FormikInputWrapper>
  );
};
