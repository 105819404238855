import { useNavigate } from 'react-router-dom';
import { prepareUrl } from '../api/helpers/queryHelpers';

export const useReplaceUrl = () => {
  const navigate = useNavigate();

  const replaceUrl = (newQueryParams: any) => {
    const newUrl = prepareUrl(window.location, newQueryParams);

    navigate(newUrl);
  };

  return {
    replaceUrl,
  };
};
