import React, { MouseEvent, forwardRef } from 'react';
import { LabelWrapper } from '@/components/form/fields/FormikInput/styles';
import { ReactComponent as DatePickerIcon } from '../../../../images/CalendarIcon.svg';
import { ReactComponent as CrossIcon } from '../images/CloseIcon.svg';
import {
  CrossIconWrapper,
  DatePickerIconWrapper,
  DatePickerInputWrapper,
} from '@/components/form/FormikDatePicker/ui/styles';
import { INPUT_MAP, InputVariantType } from '../../fields/FormikInput/FormikInput';
import { InputError } from '@/components/form/fields/InputError/InputError';

interface DatePickerInputType {
  value: string;
  onOpen: (event: MouseEvent<HTMLDivElement>) => void;
  onClear: () => void;
  label?: string;
  placeholder?: string;
  isClearable?: boolean;
  variant?: InputVariantType;
  hasErrorComponent?: boolean;
  error: string;
  apiError: string;
}

export const DatePickerInput = forwardRef((props: DatePickerInputType, ref: React.Ref<HTMLInputElement>) => {
  const {
    label,
    placeholder,
    value,
    onOpen,
    onClear,
    variant = 'default',
    isClearable = true,
    hasErrorComponent,
    error,
    apiError,
  } = props;

  const clearDate = (event) => {
    event.stopPropagation();

    onClear();
  };

  const hasClearIcon = value && isClearable;
  const InputComponent = INPUT_MAP[variant];

  return (
    <>
      {label && <LabelWrapper>{label}</LabelWrapper>}
      <DatePickerInputWrapper onClick={onOpen}>
        <InputComponent ref={ref} placeholder={placeholder} value={value} readOnly />
        {hasClearIcon && (
          <CrossIconWrapper onClick={clearDate} variant={variant}>
            <CrossIcon />
          </CrossIconWrapper>
        )}
        <DatePickerIconWrapper variant={variant}>
          <DatePickerIcon />
        </DatePickerIconWrapper>
      </DatePickerInputWrapper>
      {hasErrorComponent && <InputError error={error} apiError={apiError} />}
    </>
  );
});
