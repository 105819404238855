import { useField } from 'formik';
import { SelectNew, SelectNewType } from '../../../SelectNew/SelectNew';
import { ResponseErrorType } from '@/types/sharedTypes';
import { getFieldError } from '@/helpers/formHelpers/formHelpers';

interface FormikSelectNewType extends Omit<SelectNewType, 'apiError'> {
  name: string;
  apiError?: ResponseErrorType;
}

export const FormikSelectNew = ({
  placeholder,
  options,
  label,
  name,
  apiError,
  isSearchable,
  postfixLabel,
  isLoading,
  hasPortal,
}: FormikSelectNewType) => {
  const [field, meta, { setValue }] = useField(name);

  const { error, apiError: apiErrorMessage } = getFieldError(name, meta, apiError);

  const onChange = (newValue) => {
    setValue(newValue);
  };

  const onBlur = (event) => {
    field.onBlur(event);
  };

  return (
    <SelectNew
      {...field}
      placeholder={placeholder}
      options={options}
      isSearchable={isSearchable}
      label={label}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
      apiError={apiErrorMessage}
      isLoading={isLoading}
      postfixLabel={postfixLabel}
      hasPortal={hasPortal}
    />
  );
};
