import { DetailsBlockEdit } from './styles';
import { ReactComponent as EditIcon } from '@/images/EditIcon.svg';

type DetailsEditButtonType = {
  onEdit: (boolean) => void;
};

export const DetailsEditButton = ({ onEdit }: DetailsEditButtonType) => {
  return (
    <DetailsBlockEdit onClick={onEdit}>
      <EditIcon />
    </DetailsBlockEdit>
  );
};
