import { ValueOf } from '@/types/sharedTypes';

export const USER_ROLE_MAP = {
  OPCO: 'opco_user',
  ORGANIZATION: 'organization_user',
  RECIPIENT: 'recipient_user',
  EXECUTOR: 'executor',
  DEPOSITOR: 'depositor',
} as const;

export type UserRoleType = ValueOf<typeof USER_ROLE_MAP>;

export type ActiveUserRolesType = UserRoleType[];
