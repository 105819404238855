import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { authTokenService } from '@/bundle/Auth/LoginFlow/LoginPage/service/authTokenService';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { getLoginUrl } from '@/bundle/Auth/LoginFlow/LoginPage/urls/getLoginUrl';
import { loadSelfUserDetails } from '@/bundle/Auth/LoginFlow/LoginPage/api';
import { sessionStorageService } from '@/helpers/storageHelpers';
import { REDIRECT_URL_KEY, TOKEN_REFRESHED_EVENT } from '@/const/shared';
import { USER_ROLE_MAP, UserRoleType } from '@/const/user';
import { getStorageCurrentRole, setStorageCurrentRole } from '@/helpers/userHelpers';
import { SelfUserDetailsType } from '@/api/v1/users/getSelfUserDetailsApi';

interface UserContextType {
  user: SelfUserDetailsType;
  loading: boolean;
  loadUser: () => void;
  currentRole: UserRoleType;
  setRole: (role: UserRoleType) => void;
  isOrgAdminRole: boolean;
  isOpcoRole: boolean;
  isExecutorRole: boolean;
}

const UserContextProvider = createContext<UserContextType>({} as UserContextType);

export const UserProvider = ({ children }: { children?: ReactNode }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useState<SelfUserDetailsType>(null);
  const [currentUserRole, setCurrentUserRole] = useState(null);
  const [loading, setLoading] = useState(false);

  const loadUser = async (disableLoading: boolean = false) => {
    if (loading) return;

    const tokens = authTokenService.getTokens();

    if (!tokens?.user_id) {
      sessionStorageService.set(REDIRECT_URL_KEY, location);

      return navigate(getLoginUrl());
    }

    if (!user && !disableLoading) {
      setLoading(true);
    }

    try {
      const response = await queryClient.fetchQuery({
        queryKey: ['load_self_user'],
        queryFn: () => loadSelfUserDetails(),
      });

      if (response.error) return;

      amplitudeService.setUser(response.body, currentUserRole);

      setUser(response.body);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      navigate(getLoginUrl());
    }
  };

  const setRole = (role: UserRoleType) => {
    setCurrentUserRole(role);
    setStorageCurrentRole(role);
  };

  const getRole = () => {
    if (currentUserRole) {
      return currentUserRole;
    }

    const role = getStorageCurrentRole();

    if (role) {
      setRole(role);
    }

    return role;
  };

  const isOrgAdminRole = currentUserRole === USER_ROLE_MAP.ORGANIZATION;
  const isOpcoRole = currentUserRole === USER_ROLE_MAP.OPCO;
  const isExecutorRole = currentUserRole === USER_ROLE_MAP.EXECUTOR;

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Load user details whenever access token updated to keep user roles up to date.
    window.addEventListener(TOKEN_REFRESHED_EVENT, () => {
      loadUser(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserContextProvider.Provider
      value={{ user, loading, loadUser, currentRole: getRole(), setRole, isOrgAdminRole, isOpcoRole, isExecutorRole }}
    >
      {children ? children : <Outlet />}
    </UserContextProvider.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContextProvider);
};
