import { FormikInput } from '@/components/form/fields/FormikInput/FormikInput';

type SearchInputType = {
  config: {
    name: string;
  };
  placeholder: string;
};

export const SearchInput = ({ config: { name }, placeholder }: SearchInputType) => {
  return <FormikInput name={name} placeholder={placeholder} variant='small' />;
};
