import { useFormik } from 'formik';
import { createTransactionValidationSchema } from '../../const/createTransactionValidationSchema';
import { Box } from '@/components/Box/Box';
import { ResponseErrorContentType } from '@/types/sharedTypes';
import { FormikDatePicker } from '@/components/form/FormikDatePicker/FormikDatePicker';
import { DatePickerWrapper } from './styles';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { FormikInput } from '@/components/form/fields/FormikInput/FormikInput';
import { Button } from '@/components/Button/Button';

type CreateTransactionFormType = {
  onCreate: (values) => void;
  apiError: ResponseErrorContentType;
  onClose: (e: any) => void;
  isLoading: boolean;
};

export const CreateTransactionForm = ({ onCreate, apiError, onClose, isLoading }: CreateTransactionFormType) => {
  const formik = useFormik({
    initialValues: {
      name: '',
      expected_close_date: '',
    },
    validationSchema: createTransactionValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      onCreate(values);
    },
  });

  const saveHandler = (e: any) => {
    formik.handleSubmit(e);
  };

  return (
    <FormikForm value={formik}>
      <FormikInput
        label='Transaction Name*'
        placeholder='Enter Transaction Name'
        name='name'
        autoFocus
        errorsApi={apiError}
      />
      <DatePickerWrapper>
        <FormikDatePicker name='expected_close_date' label='Expected Closing Date' placeholder='Select Date' />
      </DatePickerWrapper>
      <Box columnGap='20px' display='flex' mt='36px'>
        <Button color='secondary' onClick={onClose} width={200} type='button'>
          Cancel
        </Button>
        <Button onClick={saveHandler} type='submit' width={200} isLoading={isLoading}>
          Save
        </Button>
      </Box>
    </FormikForm>
  );
};
