import { Status } from '../../../../../../../components/Status/Status';
import { DEPOSIT_ACCOUNT_STATUS_DB } from '../../../../../../../const/wire';
import { DepositAccountStatusType, WireStatusType } from '../../../../../../../types/wireTypes';
import { InactiveDepositAccountStatus, StatusListWrapper } from './styles';

type StatusListType = {
  wireStatus: WireStatusType;
  depositAccountStatus: DepositAccountStatusType;
};

export const StatusList = ({ wireStatus, depositAccountStatus }: StatusListType) => {
  const isInactiveDepositAccount = depositAccountStatus === DEPOSIT_ACCOUNT_STATUS_DB.INACTIVE;

  return (
    <StatusListWrapper>
      {isInactiveDepositAccount && (
        <InactiveDepositAccountStatus>Inactive deposit account</InactiveDepositAccountStatus>
      )}
      <Status status={wireStatus} />
    </StatusListWrapper>
  );
};
