import styled from 'styled-components';

export const PaymentDetailsWrapper = styled.div`
  width: 100%;
  flex: 1;
  position: relative;
`;

export const StatusIconWrapper = styled.div<{ hasIntermediaryBank: boolean }>`
  position: absolute;
  top: ${({ hasIntermediaryBank }) => (hasIntermediaryBank ? '590px' : '350px')};
  right: 220px;
  z-index: 2;
`;
