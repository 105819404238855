import {
  ArrowButton,
  PagesWrapper,
  PaginatorActiveButton,
  PaginatorDots,
  PaginatorNextButton,
  PaginatorWrapper,
} from './styles';
import { useNavigate } from 'react-router-dom';
import range from 'lodash/range';
import { ReactComponent as LeftArrowIcon } from '../images/LeftArrowIcon.svg';
import { ReactComponent as RightArrowIcon } from '../images/RightArrowIcon.svg';
import { prepareUrl } from '../../../api/helpers/queryHelpers';
import { useRef } from 'react';

const PAGE_SEPARATOR_COUNT = 1;
const SCROLL_DELAY = 100;

type PaginatorContainerType = {
  page: number;
  limit: number;
  total: number;
};

export const PaginatorContainer = ({ page, limit, total }: PaginatorContainerType) => {
  const navigate = useNavigate();
  const paginatorRef = useRef(null);
  const totalPagesCount = limit && total ? Math.ceil(total / limit) : 0;
  const leftDelimiter = page > PAGE_SEPARATOR_COUNT + 1;
  const rightDelimiter = totalPagesCount - 1 - page > PAGE_SEPARATOR_COUNT + 1;
  const leftPage = leftDelimiter ? page - PAGE_SEPARATOR_COUNT : 0;
  const rightPage = rightDelimiter ? page + PAGE_SEPARATOR_COUNT + 1 : totalPagesCount;

  const scrollToBottom = () => {
    if (!paginatorRef?.current) return;

    setTimeout(() => {
      paginatorRef.current?.scrollIntoView();
    }, SCROLL_DELAY);
  };

  const replaceUrl = (newQueryParams: any) => {
    const newUrl = prepareUrl(window.location, newQueryParams);

    navigate(newUrl);
    scrollToBottom();
  };

  const navigateToPreviousPage = (prevPage: number) => {
    if (prevPage <= 0) return;

    const newPage = prevPage - 1 > 0 ? prevPage - 1 : null;
    const newQueryParams = { page: newPage };

    replaceUrl(newQueryParams);
  };

  const naviteToPage = (currentPage: number) => {
    const newPage = currentPage || null;
    const newQueryParams = {
      page: newPage,
    };

    replaceUrl(newQueryParams);
  };

  const navigateToNextPage = (nextPage: number) => {
    if (nextPage >= totalPagesCount - 1) return;

    const newPage = nextPage + 1 > totalPagesCount - 1 ? totalPagesCount - 1 : nextPage + 1;
    const newQueryParams = { page: newPage };

    replaceUrl(newQueryParams);
  };

  const getPagesRange = () => {
    return range(leftPage, rightPage);
  };

  const pagesRange = getPagesRange();

  return (
    <PaginatorWrapper ref={paginatorRef}>
      <ArrowButton onClick={() => navigateToPreviousPage(page)} disabled={page <= 0} type='button'>
        <LeftArrowIcon />
      </ArrowButton>
      <PagesWrapper>
        {leftDelimiter && (
          <PaginatorNextButton key={0} onClick={() => naviteToPage(0)} type='button'>
            1
          </PaginatorNextButton>
        )}
        {leftDelimiter && <PaginatorDots>...</PaginatorDots>}
        {pagesRange.map((pageNumber: number) => {
          const ButtonElement = pageNumber === page ? PaginatorActiveButton : PaginatorNextButton;

          return (
            <ButtonElement key={pageNumber} onClick={() => naviteToPage(pageNumber)} type='button'>
              {pageNumber + 1}
            </ButtonElement>
          );
        })}
        {rightDelimiter && <PaginatorDots>...</PaginatorDots>}

        {rightDelimiter && (
          <PaginatorNextButton
            key={totalPagesCount - 1}
            onClick={() => naviteToPage(totalPagesCount - 1)}
            type='button'
          >
            {totalPagesCount}
          </PaginatorNextButton>
        )}
      </PagesWrapper>
      <ArrowButton onClick={() => navigateToNextPage(page)} disabled={page >= totalPagesCount - 1} type='button'>
        <RightArrowIcon />
      </ArrowButton>
    </PaginatorWrapper>
  );
};
