import { COLORS } from '@/styles/colors';
import { caption, poppinsSemiBoldFont } from '@/styles/typography';
import styled from 'styled-components';

export const TableHeaderCellContainer = styled.th<{ width: string; hasSort: boolean }>`
  padding: 15px 16px;
  width: ${({ width }) => (width ? width : '100%')};
  ${poppinsSemiBoldFont};
  ${caption};
  color: ${COLORS.white};
  letter-spacing: 0.24px;

  &:first-child {
    border-top-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
  }

  ${({ hasSort }) =>
    hasSort && {
      cursor: 'pointer',
    }};
`;
