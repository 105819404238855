import { useFormik } from 'formik';
import { FormikForm } from '../../../../../components/form/FormikForm/FormikForm';
import { Button } from '../../../../../components/Button/Button';
import { FormButtonBlock } from '../../../shared/ui/styles';
import { AssignedWireType } from '@/types/wireTypes';
import { ResponseErrorType } from '@/types/sharedTypes';
import { FormGeneratorNew } from '@/components/form/FormGeneratorNew/FormGeneratorNew';
import { getChangedFormValues, getMergedValues, getValidationSchema } from '@/helpers/formHelpers/formHelpers';
import { getOutboundWireEditConfig } from './const/const';
import { formatOutboundWire } from './helpers/helpers';
import { getEditWirePayload } from '@/bundle/_OrgAdmin/shared/helpers/helpers';

type OutboundWireEditFormType = {
  onUpdateWireDetails: () => void;
  currencyOptions: Array<{ value: string; label: string }>;
  outboundWire: AssignedWireType;
  onEdit: (data) => void;
  isLoading: boolean;
  onReset: () => void;
  apiError: ResponseErrorType;
};

export const OutboundWireEditForm = ({
  onUpdateWireDetails,
  currencyOptions,
  outboundWire,
  onEdit,
  isLoading,
  onReset,
  apiError,
}: OutboundWireEditFormType) => {
  const outboundWireEditConfig = getOutboundWireEditConfig(currencyOptions);
  const outboundWireFormatted = formatOutboundWire(outboundWire);

  const formik = useFormik({
    initialValues: getMergedValues(outboundWireEditConfig, outboundWireFormatted),
    validationSchema: getValidationSchema(outboundWireEditConfig),
    enableReinitialize: true,
    onSubmit: (values) => {
      const payload = getChangedFormValues(values, formik.initialValues);

      if (!payload) {
        onUpdateWireDetails();

        return;
      }

      const outboundWirePayload = getEditWirePayload(payload);

      onEdit(outboundWirePayload);
    },
  });

  const cancelHandler = () => {
    onReset();
    onUpdateWireDetails();
  };

  return (
    <>
      <FormikForm value={formik}>
        <FormGeneratorNew config={outboundWireEditConfig} apiError={apiError} />
      </FormikForm>
      <FormButtonBlock>
        <Button color='secondary' onClick={cancelHandler} width={200} size='medium'>
          Cancel
        </Button>
        <Button isLoading={isLoading} onClick={formik.handleSubmit} width={200} size='medium'>
          Save
        </Button>
      </FormButtonBlock>
    </>
  );
};
