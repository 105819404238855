import { FakeCheckbox, Input } from './styles';

type CheckboxProps = {
  checked?: boolean;
  onChange?: () => void;
  disabled?: boolean;
  id?: string;
};

export const Checkbox = ({ id, checked, onChange, disabled }: CheckboxProps) => {
  return (
    <label>
      <Input id={id} type='checkbox' checked={checked} onChange={onChange} disabled={disabled} />
      <FakeCheckbox disabled={disabled} />
    </label>
  );
};
