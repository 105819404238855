import styled from 'styled-components';
import { COLORS } from '@/styles/colors';
import { media } from '@/styles/responsive';

export const AuthStepWrapper = styled.div<{ width?: string; center?: boolean }>`
  max-width: 504px;
  padding: 42px;
  background: ${COLORS.white};
  box-shadow: 0 2px 10px rgba(19, 62, 112, 0.2);
  border-radius: 20px;
  margin: 0 auto;
  width: ${({ width }) => (width ? `${width}` : '504px')};

  ${media.mobile`
    margin-top: 42px;
    margin-bottom: 12px;
    max-width: none;
    width: 100%;
    border-radius: 0;
    padding: 16px;
    height: 100%;
    box-shadow: none;

    ${({ center }) =>
      center &&
      `
      height: auto;
      margin-top: 0;
    `};
  `};
`;
