import styled from 'styled-components';
import { COLORS } from '../../../../../../../styles/colors';
import { bodySmall, poppinsRegularFont } from '../../../../../../../styles/typography';
import { flex } from '@/styles/layout';

export const StatusListWrapper = styled.div`
  ${flex()};
`;

export const InactiveDepositAccountStatus = styled.div`
  border-radius: 10px;
  padding: 4px 8px;
  background-color: ${COLORS.grey[200]};
  color: ${COLORS.grey[600]};
  ${bodySmall};
  ${poppinsRegularFont};
  margin-right: 12px;
`;
